// функция получения гет параметров

function getParams(url = window.location) {
    let params = {};

    new URL(url).searchParams.forEach(function (val, key) {
        if (params[key] !== undefined) { // Проверяем параметр на undefined
            /* Проверяем, имеется ли в объекте аналогичный params[key]
            *  Если его нет, то добавляем его в объект
            */
            if (!Array.isArray(params[key])) {
                params[key] = [params[key]];
            }
            params[key].push(val);
        } else {
            params[key] = val;
        }
    });
    // console.log(params);
    return params;
}

// получить стили элемента
function getStyle(elem) {
    if (window.getComputedStyle) return getComputedStyle(elem, null);
    else return elem.currentStyle;
}

// получить куки
function getCookie(name) {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
            return cookie.substring(name.length + 1);
        }
    }

    return null;
}

// меняем куки
function setCookie(name, value) {
    document.cookie = name + "=" + encodeURIComponent(value) + "; path=/";
}

// склонение слов

function pluralizeWord(number, wordForms) {
    // Проверяем, что передан массив форм слова
    if (!Array.isArray(wordForms) || wordForms.length !== 3) {
        throw new Error('wordForms должен быть массивом из трех форм слова');
    }

    // Проверяем, что передано число
    if (typeof number !== 'number') {
        throw new Error('number должен быть числом');
    }

    // Определяем индекс формы слова в зависимости от числа
    let formIndex;
    if (number % 10 === 1 && number % 100 !== 11) {
        formIndex = 0; // первая форма (например, "яблоко")
    } else if ([2, 3, 4].includes(number % 10) && ![12, 13, 14].includes(number % 100)) {
        formIndex = 1; // вторая форма (например, "яблока")
    } else {
        formIndex = 2; // третья форма (например, "яблок")
    }

    // Возвращаем склоненное слово
    return wordForms[formIndex];
}

// клик вне элемента
function clickOutside(el, btn, cl) {
    let element = typeof el === 'object' ? el : document.querySelector(el);
    let button = typeof btn === 'object' ? btn : document.querySelector(btn);

    document.addEventListener('click', e => {
        let target = e.target;
        let itsEl = target == element || element.contains(target);
        let its_btn = target == button;
        let its_el_is_open = element.classList.contains(cl);

        if (!itsEl && !its_btn && its_el_is_open) {
            element.classList.toggle(cl);
        }
    });
}

function handleClickOutside(element, callback) {

    function handleDocumentClick(event) {
        const isClickInsideElement = element.contains(event.target);
        if (!isClickInsideElement) {
            callback();
        }
    }

    document.addEventListener("click", handleDocumentClick);

    return function cleanup() {
        document.removeEventListener("click", handleDocumentClick);
    };
}

function customSelect(containers, title, content, label, radio, show) {
    const allContainers = document.querySelectorAll(containers);

    function calculateLabelHeight(labels, show) {
        let labelHeight = 0;

        for (let i = 0; i < labels.length; i++) {
            if (i < show) {
                labelHeight += labels[i].offsetHeight;
            } else {
                break;
            }
        }

        return labelHeight;
    }

    allContainers.forEach(function (container) {
        const labels = container.querySelectorAll(label);
        const contentEl = container.querySelector(content);
        const containerTitle = container.querySelector(title);
        const titleEl = containerTitle.querySelector('.select-title__text') || containerTitle;
        const labelHeight = calculateLabelHeight(labels, show);

        if (show) {
            if (labels.length <= show) {
                contentEl.classList.add('no-scroll');
            }

            contentEl.style.maxHeight = labelHeight + 32 + 'px';
        }


        const radios = container.querySelectorAll('input[type="radio"]');

        if (radios) {
            radios[0].checked = true;
        }

        container.addEventListener('click', function(event) {
            const mainParent = event.target.closest(containers);
            const currentTitle = title.replaceAll('.', '');
            const currentRadio = radio.replaceAll('.', '');
            const currentLabel = label.replaceAll('.', '');

            if (event.target.classList.contains(currentRadio) || event.target.classList.contains(currentLabel)) {
                const content = event.target.textContent;

                mainParent.classList.add('active');
                titleEl.textContent = content;
                mainParent.classList.remove('open');

            } else if (event.target.classList.contains(currentTitle) || event.target.classList.contains('select-title__text') || event.target.classList.contains('select-undertitle')) {

                if (!mainParent.classList.contains('open')) {
                    mainParent.classList.add('open');
                } else {
                    mainParent.classList.remove('open');
                }

                allContainers.forEach(container => {
                    if (container !== mainParent) {
                        container.classList.remove('open');
                    }
                });
            }
        }, true);

    })

    document.addEventListener('click', function(event) {
        const target = event.target;

        if (!target.closest(containers)) {
            allContainers.forEach(container => {
                container.classList.remove('open');
            });
        }
    });
}

;

$(document).ready(function () {
// Создаем класс Accordion
class Accordion {
    constructor(container, multiple, item) {
        this.container = container;
        this.multiple = multiple;
        this.items = this.container.querySelectorAll(item);

        // Добавляем обработчик события клика для каждого заголовка
        this.items.forEach(item => {
            const header = item.querySelector('.heading');
            header.addEventListener('click', () => {
                this.toggleItem(item);
            });
        });
    }

    // Метод для открытия/закрытия элемента аккордеона
    toggleItem(item) {
        const content = item.querySelector('.submenu');
        const isOpen = item.classList.contains('open');

        if (!this.multiple) {
            // Если multiple = false, закрываем все элементы перед открытием нового
            this.items.forEach(element => {
                if (element !== item) {
                    element.classList.remove('open');
                    element.querySelector('.submenu').style.maxHeight = 0;
                }
            });
        }

        if (isOpen) {
            // Если элемент открыт, закрываем его
            item.classList.remove('open');
            content.style.maxHeight = 0;
        } else {
            // Если элемент закрыт, открываем его
            item.classList.add('open');
            content.style.maxHeight = content.scrollHeight + "px";
        }
    }
}
;

    // Получаем все аккордеоны
    const accordions = document.querySelectorAll('.accordion');

    if (accordions) {
        // Перебираем каждый аккордеон
        accordions.forEach(accordion => {
            const multiple = accordion.dataset.multiple === 'true';
            new Accordion(accordion, multiple, '.accordion__item');
            new Accordion(accordion, multiple, '.vision__accordion-item');
        });
    }

    // кнопка назад
    const backLinks = document.querySelectorAll('.vision__back-link');
    let observer = null; // создаем переменную под экземпляр MutationObserver

    if (backLinks) {
        backLinks.forEach(function (link) {
            link.addEventListener('click', function (e) {
                // запускаем функцию показа нужного блока
                showPathnameBlock(this.dataset.href);
            });
        });
    }

    /**
     * Создаем объект, в котором есть нужные данные для изменения контента на странице
     * @param { Object[] } pathnames // массив с pathname из функции getPathname
     * @param { Object[] } keys // массив с ключами для поиска нужного описания
     * @param { Object} container // элемент, в который будем вставлять описание
     * @returns {{keys, pathnames, element}}
     */
    const createDescriptionArgs = (pathnames, keys, container) => {
        return {
            pathnames: pathnames,
            keys: keys,
            element: container
        }
    };

    /**
     * Добавляем к оригинальной ссылке pathname, которые получаем в качестве аргумента
     * @param { String } pathname
     * @returns { string }
     */
    const newAddressLink = (pathname) => {
        return window.location.origin + '/' + pathname;
    };

    /**
     * Получаем pathname, разбиваем в массив и удаляемс из массива первый элемент, так как он пустой
     * @returns {string[]} // массив с pathname
     */
    const getPathname = () => {
        let pathname = window.location.pathname;
        let pathnames = pathname.split('/');

        pathnames.splice(0, 1);

        return pathnames;
    };

    /**
     * Переключение описания, если селект зависит от селекта
     * currentSection передаем, когда открываем блок, когда мы уже внутри блока и производим выбор в селекте не передаем
     * @param { Object } currentSection // section внутри, которого будет все выполняться или объект this
     */
    function selectWithTabs(currentSection) {
        let currentVision = currentSection.type ? this.closest('.vision') : currentSection; // получаем текущую секцию

        let activeTab = currentVision.querySelector('.vision__tabs li.active'); // получаем активный таб
        let tabGetValue = activeTab.dataset.tab; // получаем значение гет параметра активного таба
        let visionContainer = currentVision.querySelector(`.vision__tab-container[data-tab="${tabGetValue}"]`); // находим активый контейнер
        let activeSelect = visionContainer.querySelector('.vision__select'); // получаем активный селект для выбранного таба
        let activeOption = activeSelect.querySelector('input:checked'); // находим активный option внутри активного слелекта

        let tabGetName = activeTab.dataset.get; // получаем название гет параметра активного таба
        let tabContainerGetName = visionContainer.dataset.get; // получаем название гет параметра активного контейнера
        let tabContainerGetValue = activeOption.value; // получаем значение гет параметра активного контейнера

        // подставляем описание
        changeDescription(
            // создаем объект с аргументами для правильной подстановки описания
            createDescriptionArgs(
                getPathname(),
                [tabGetValue, tabContainerGetValue],
                visionContainer
            )
        );

        // меняем урл
        let getArgs = {
            [tabGetName]: tabGetValue,
            [tabContainerGetName]: tabContainerGetValue
        };

        changeUrlGet(getArgs);
    }

    // если селект зависит от другого селекта
    /**
     * Переключение описания, если селект зависит от табов
     * currentSection передаем, когда открываем блок, когда мы уже внутри блока и производим выбор в селекте или табе не передаем
     * @param { Object } currentSection // section внутри, которого будет все выполняться или объект this
     */
    function onlySelects(currentSection) {
        let currentVision = currentSection.type ? this.closest('.vision') : currentSection; // получаем текущую секцию
        let currentVisionId = currentVision.getAttribute('id'); // получаем id текущей секции
        let visionContainer = currentVision.querySelector('.vision__select-container'); // получаем контейнер селектов на странице
        let currentSelects = visionContainer.querySelectorAll('.vision__select'); // получаем все селекты внутри страницы
        let selectsGet = {}; // пишем сюда гет параметры
        let keys = []; // пишем сюда ключи для массива с описаниями
        let ids = ['personal']; // айдишники, которые нужны для проверки

        // меняем label в селекте, если нужно
        if (ids.includes(currentVisionId)) {
            let parentLabel = currentVision.querySelector('.vision__select_parent-undertitle input:checked')
            let partLabel = parentLabel.closest('.select-content__wrapper').querySelector('.select-content__radio').textContent; // текст для label
            let childLabelContainer = currentVision.querySelector('.vision__select_child-undertitle .select-undertitle'); // получаем дочерний контейнер для label
            let childLabelText = childLabelContainer.textContent; // получаем старый текст из дочернего контейнера

            partLabel = partLabel.trim(); // убираем лишние пробелы
            childLabelText = childLabelText.trim(); // убираем лишние пробелы
            childLabelText = childLabelText.split(' ');
            // подставляем label в нужный блок
            childLabelContainer.textContent = `${partLabel} ${childLabelText[1]} ${childLabelText[2]}`;
        }

        if (currentSelects) {
            currentSelects.forEach(function (select, i) {
                let nameGet = select.querySelector('input').getAttribute('name'); // получаем название гет параметра
                let valueGet = select.querySelector('input:checked').getAttribute('value'); // получаем значение гет параметра

                // разделяем, так как атрибут name у input такого вида: personal_planet, нам нужно только planet
                nameGet = nameGet.split('_');
                // записываем гет параметр
                selectsGet[nameGet[nameGet.length - 1]] = valueGet;
                // записываем ключ
                keys.push(valueGet);
            });
        }

        // подставляем описание
        changeDescription(
            // создаем объект с аргументами для правильной подстановки описания
            createDescriptionArgs(
                getPathname(),
                keys,
                currentVision.querySelector('.vision__tab-description-wrap')
            )
        );

        // меняем url
        changeUrlGet(selectsGet);
    }

    document.addEventListener('click', function (e) {
        let condition_1 = e.target.classList.contains('submenu__item');
        let condition_2 = (e.target.closest('.list__item-link') && e.target.closest('.item__heading'));
        let condition_3 = e.target.classList.contains('submenu__text');
        let condition_4 = e.target.classList.contains('submenu__ico');
        let condition_5 = e.target.classList.contains('svg-ico');

        let final_condition = condition_1 || condition_2 || condition_3 || condition_4 || condition_5;

        if (final_condition) {
            let target = e.target.closest('.submenu__item, .list__item-link > .item__heading');
            let href = target.dataset.href; // ищем дата атрибут с id блока, который буем показывать
            let parent = target.closest('.accordion__item'); // ищем родительский блок, на котором может быть дата атрибут начального пути
            let newUrl = newAddressLink(href); // формируем новый url

            if (parent && parent.dataset.path) {
                let fullPath = parent.dataset.path + '/' + href; // конкатенируем начальный путь и конечный
                newUrl = newAddressLink(fullPath);  // формируем новый url
            }

            history.replaceState(null, null, newUrl); // записываем новый url
            showPathnameBlock(href); // показываем нужный блок
        }
    });

    /**
     * определение какой блок будем показывать в зависимости от переданного параметра
     * @param { String } href // id блока, который нужно показать
     */
    function showPathnameBlock(href) {
        // Определяем текущий pathname
        let pathname = href || window.location.pathname;
        let index = document.querySelector('.manuals');
        // Создаем массив с всеми возможными путями, который будем проверять
        const pathnames = ['pluto', 'neptun', 'uranus', 'saturn', 'jupiter', 'lilit', 'personal', 'planet-in-house', 'axis', 'cup-in-house', 'solar-phormulas', 'ingressions', 'directions'];

        // убираем из pathname "/"
        pathname = pathname.replace('/', '');

        // Проверяем, если pathnames содержит pathname
        if (pathnames.includes(pathname)) {
            // получаем блок с id из pathname
            const visionBlock = document.getElementById(pathname);
            const visionAccordion = visionBlock.querySelector('.vision__content-accordion'); // находим аккординг внутри нужного блока
            let visionType = visionBlock.dataset.type;
            index.style.display = 'none'; // скрываем главный блок

            if (visionBlock) {
                // показываем нужный блок
                visionBlock.style.display = 'block';

                // подставляем активный контент и добавляем гет параметры в url
                if (visionType) {

                    switch (visionType) {
                        case 'selectWithTabs':
                            selectWithTabs(visionBlock);
                            break;

                        case 'selects':
                            onlySelects(visionBlock);
                            break;

                        case 'accordion':
                            let accordionButton = visionBlock.querySelector('.vision__accordion-button');
                            let accordionItems = visionAccordion.querySelectorAll('.accordion__item');

                            // Создаем экземпляр MutationObserver
                            observer = new MutationObserver(function (mutations) {
                                let currentItem = mutations[0]['target'];

                                updateNearbyItems(currentItem);
                                // При изменениях в аккордеоне вызываем функцию обновления текста кнопки
                                updateButtonState(accordionButton, accordionItems);
                            });

                            // Настраиваем настройки для observer (следим за изменениями в дочерних элементах и их атрибутах)
                            var config = {childList: true, subtree: true, attributes: true};

                            // Начинаем отслеживание изменений в аккордеоне с использованием настроек и функции обратного вызова
                            observer.observe(visionAccordion, config);

                            break;

                    }

                }

                if (visionAccordion) {

                }
            } else {
                // Если блок не найден, показываем страницу 404
                const error404Block = document.getElementById('error-404');
                if (error404Block) {
                    error404Block.style.display = 'block';
                }
            }
        } else if (pathname === 'origin') {
            const visionBlocks = document.querySelectorAll('.vision'); // получаем все блоки с контентом

            if (visionBlocks) {
                visionBlocks.forEach(function (vision) {
                    vision.style.display = 'none';
                });
            } // проверяем есть ли эти блоки, потом преебираем и скрываем каждый

            index.style.display = 'block'; // главный блок показываем
            history.replaceState(null, null, window.location.origin); // меняем url

            if (observer !== null) observer.disconnect(); // отключаем MutationObserver (отвелючаем слежением ха элементом)
        }

    }

    showPathnameBlock();

    // клик по табам

    const tabs = document.querySelectorAll('.vision__tabs li');

    /**
     * получаем массив гет парамтеров и формируем новую ссылкуЮ которую возвращаем
     * @param { Object[] } args // массив уже готовых гет параметров, элемент массива ключ=значение
     * @returns {string} // соединенные вместе ссылка на на сайт и все гет параметры из массива
     */
    const newUrlGetParam = (args) => {
        let getParameters = ''; // строка гет параметров

        for (let i = 0; i < args.length; i++) {
            getParameters += args[i]; // добавляем гет параметр из массива

            if (i + 1 < args.length) {
                getParameters += '&'; // разделяем гет параметры
            }
        }

        return window.location.origin + window.location.pathname + '?' + getParameters;
    }

    /**
     * получаем объект с гет параметрами, преебираем и создаем массива с строками вида ключ=значение
     * получаем новую ссылку через функцию newUrlGetParam
     * меняем url в истории браузера
     * @param { Object } args // гет параметры
     */
    const changeUrlGet = (args) => {
        let getArr = [];

        for (const argsKey in args) {
            let argValue = args[argsKey]
            getArr.push(`${argsKey}=${argValue}`);
        }
        // меняем url
        const newUrl = newUrlGetParam(getArr);

        history.replaceState(null, null, newUrl);
    }

    if (tabs) {
        // Перебираем все табы
        tabs.forEach(function (tab) {
            // вешаем клик событие на каждый таб
            tab.addEventListener('click', function (e) {
                let isActive = this.classList.contains('active');
                let dataTab = this.dataset.tab; // получаем дата атрибут таба
                let dataGetTab = this.dataset.get; // получаем дата атрибут для записи в гет параметр
                let dataContainerGet; // получаем дата атрибут контейнера для записи в гет параметр
                let containerGetValue; // получаем значение выбранного элемента в селекте для записи в гет параметр
                let currentSection = this.closest('.vision'); // получаем тиекущую секцию
                let getArgs; // сюда будут добавляться параметры для гет url в виде ключ:значение
                // получаем все контейнеры для контента табов
                const tabsContainers = currentSection.querySelectorAll('.vision__tab-container');
                // перебирвем все контейнеры
                tabsContainers.forEach(function (container) {
                    // получаем дата атрибут контейнера
                    let containerDataTab = container.dataset.tab;

                    // сравниваем дата атрибут контейнера с дата атрибутом выбранного таба, чтобы показать нужный котейнер и остальные скрыть
                    if (containerDataTab === dataTab) {
                        container.classList.add('active');
                        dataContainerGet = container.dataset.get;
                        containerGetValue = container.querySelector('input:checked').value;

                        // подставляем описание
                        changeDescription(
                            // создаем объект с аргументами для правильной подстановки описания
                            createDescriptionArgs(
                                getPathname(),
                                [dataTab, containerGetValue],
                                container
                            )
                        );
                    } else {
                        container.classList.remove('active')
                    }

                });

                // делаем текущий таб активным и остальные не активными
                if (!isActive) {
                    currentSection.querySelectorAll('.vision__tabs li').forEach(function (item) {
                        item.classList.remove('active');
                    });

                    this.classList.add('active');
                }

                // меняем url
                getArgs = {
                    [dataGetTab]: dataTab,
                    [dataContainerGet]: containerGetValue
                };

                changeUrlGet(getArgs);
            });
        });
    }

    // инит селекта
    customSelect('.select', '.select-title', '.select-content', '.select-content__wrapper', '.select-content__radio');

    // выбор в селекте

    const visionSelects = document.querySelectorAll('.vision__select');

    if (visionSelects) {

        visionSelects.forEach(function (select) {
            const inputs = select.querySelectorAll('input[type="radio"]');
            let type = select.closest('.vision').dataset.type; // узнаем тип логики показа описания

            if (inputs) {
                inputs.forEach(function (input) {
                    switch (type) {
                        case 'selectWithTabs':
                            input.addEventListener('change', selectWithTabs);

                            break;

                        case 'selects':
                            input.addEventListener('change', onlySelects);

                            break;
                    }
                });
            }

        });
    }

    // описания
const descriptions = {
    transit: {
        pluto: {
            planet: {
                sun: {
                    title: '',
                    description: [
                        'Плутон по Солнцу – трансформации и переоценке подвергается сама личность натива, эго, самооценка.',
                        '(+) Прилив энергии и невероятной концентрации, способность к созданию нового и к масштабированию старого, возможность укрепить собственное влияние, способность подчинять других, способность трансформировать обстоятельства исключительно силой собственного намерения.',
                        '(-) Трансформация личности натива в условиях кризиса, потеря каких-либо качеств/навыков или болезненный отказ от них по причине ненадобности, психологическое разрушение, ощущение невероятного давления, стресса. Транзит может принести серьезные заболевания.'
                    ]
                },
                moon: {
                    title: '',
                    description: [
                        'Плутон по Луне – трансформации подвергаются эмоции, чувства, бессознательные поведенческие реакции. Независимо от аспекта такой транзит переживается сложно.',
                        '(+) Выход на новый уровень понимания собственных ощущений, усиление интуиции, проницательности, высокая скорость проживания самых разных эмоций, умелое целенаправленное воздействие на собственное состояние, как следствие – быстрая трансформация пространства. Темы дома, в котором стоит Луна, а также дома, которым она управляет однозначно актуализируются.',
                        '-) Кризисное время для психики. Неврозы, стрессы, эмоциональные срывы, затяжное ощущение перенапряжения, невыносимого давления со стороны. Транзит может принести серьезные психологические расстройства, проблемы по темам домов, к которым в гороскопе относится Луна.'
                    ]
                },
                mercury: {
                    title: '',
                    description: [
                        'Плутон по Меркурию – трансформации подвергаются контакты и информационное поле натива.',
                        '(+) Возрастает количество коммуникаций, в частности, с новыми людьми. Увеличивается уровень вовлеченности в интеллектуальную деятельность, знания и информация идут концентрированным потоком. Ощутимым становится влияние натива на окружение через слова и высказывание идей.',
                        '(-) Возникают серьезные сложности в выстраивании коммуникаций. Споры, силовое словесное противостояние, проблемы по темам домов, которыми управляет Меркурий. Мелкие дела высасывают всю энергию, ощущения бега по кругу. При слабом гороскопе – повышается внушаемость, натив слепо идет за чужим мнением.'
                    ]
                },
                venus: {
                    title: '',
                    description: [
                        'Плутон по Венере - трансформации подвергается тема любовных взаимоотношений, романтических увлечений и творческих проявлений.',
                        '(+) Повышенное внимание со стороны противоположного пола, любовные авантюры и реализация смелых фантазий. Возникает желание преобразовать свои отношения или вывести на новый уровень близость с партнером. Растет ощущение собственной сексуальности и притягательности.',
                        '(-) Уже сформированные любовные отношения проживают серьезнейший кризис. Повышается эмоциональность, появляется неуправляемое желание добиться своего. Возможны роковые пристрастия, одержимость объектом желания. Транзит толкает на нарушение существующих моральных границ в любовной сфере.'
                    ]
                },
                mars: {
                    title: '',
                    description: [
                        'Плутон по Марсу - трансформации подвергается тема физической активности, выносливости, настойчивости.',
                        '(+) Отличный период для интенсивной физической активности и спорта. Натив может выйти на новый уровень и добиться высоких результатов. Растет концентрация, воля, решительность, пробивные способности. Жизненных сил и упорства хватает на решение гигантского объема задач. Если в карте Марс и Плутон сильные, то наступает время действий и реализации задуманного.',
                        '(-) Опасность серьезных травм, взрывчатых и огнеопасных веществ. Подверженность столкновению с агрессивными группами людей. Силовое агрессивное поведение как со стороны натива, так и по отношению к нему. Возникновение навязчивых идей в сексуальной сфере жизни, опасность изнасилования.'
                    ]
                },
                jupiter: {
                    title: '',
                    description: [
                        'Плутон по Юпитеру - трансформации подвергаются темы морали, отношения к обществу и социальное положение.',
                        '(+) Возможность вырасти и продвинуться в социуме, стать более популярным и авторитетным, более значимым для широкого круга людей. Период удачных сделок и масштабных проектов. Результаты и продолжительность таких проектов зависят от качества Юпитера в натальной карте.',
                        '(-) Конфликты и скандалы с участием социума. Ситуации порицания, неодобрения в коллективе. Публичные судебные разбирательства. Часто встречается в картах звезд в периоды сложных бракоразводных процессов. Натив склонен переоценивать устойчивость и значимость своего положения в обществе.'
                    ]
                },
                saturn: {
                    title: '',
                    description: [
                        'Плутон по Сатурну - трансформации подвергается сила воли и выносливость натива.',
                        '(+) Растет выдержка, самоорганизованность, укрепляется дисциплина. Включение здорового скептицизма, позволяющего добровольно избавиться от лишнего, в том числе, от правил и порядков из прошлого. Прекрасное время для новых аскез и ограничений, которые несут благо нативу.',
                        '(-) Крайне угнетенное эмоциональное состояние (особенно при личностном Сатурне), избыточный стресс, ситуации вынужденного отказа от чего-либо. Возникают жесткие ограничения в привычных удовольствиях. Сложный транзит, проверяющий на прочность и выдержку.'
                    ]
                },
                uranus: {
                    title: '',
                    description: [
                        'Плутон по Урану, Нептуну и своему натальному положению проявится в событиях в тех домах, где находятся эти планеты или которыми они управляют. Сила и характер проявления этих обстоятельств зависит от аспектов и личных показателей в натальной карте.'
                    ]
                },
                neptun: {
                    title: '',
                    description: [
                        'Плутон по Урану, Нептуну и своему натальному положению проявится в событиях в тех домах, где находятся эти планеты или которыми они управляют. Сила и характер проявления этих обстоятельств зависит от аспектов и личных показателей в натальной карте.'
                    ]
                },
                pluto: {
                    title: '',
                    description: [
                        'Плутон по Урану, Нептуну и своему натальному положению проявится в событиях в тех домах, где находятся эти планеты или которыми они управляют. Сила и характер проявления этих обстоятельств зависит от аспектов и личных показателей в натальной карте. '
                    ]
                },
                nodes: {
                    title: '',
                    description: [
                        'Плутон по Северному/Южному узлам',
                        '(+) При соединении Плутона и Северного узла побуждает к трансформации личности, получению нового опыта. Натив ощущает острое желание изменить сферу дома, за которую отвечает Плутон (или дома, в котором он стоит).',
                        '(-) При соединении Плутона и Южного узла возникают ситуации отката по теме дома, за которую отвечает Плутон (или дома, в котором он стоит). Квадраты Плутона к узлам резко актуализируют темы предназначения натива, заставляя делать выбор.'
                    ]
                },
                lilit: {
                    title: '',
                    description: [
                        'Плутон по Лилит',
                        '(-) Наиболее сложным считается период соединения Плутона и Лилит. Данный транзит поднимает в человеке все негативные качества знака пребывания Лилит, толкает на проживание низкочастотных эмоций, вовлекает в опасные ситуации. Натив более чем когда-либо склонен идти за мнением большинства без оглядки на адекватность и моральные нормы. Это время психологического насилия (как по отношению к нативу, так и с его стороны), давления и манипуляций.'
                    ]
                },
            },
            house: {
                1: {
                    title: 'Плутон по 1 дому',
                    description: [
                        '(+) Перерождение личности. Концентрация психологической мощи. Происходит серьезная перестройка, последствия которой будут ощущаться спустя время. Человек становится способным оказывать влияние на коллективы, стремиться реализовывать личные замыслы любой ценой, часто выбирая силовые методы воздействия (особенно, если натальная карта указывает на наличие подобных черт характера). Натив может быть вовлечен в масштабные проекты, управление большими группами людей.',
                        '(-) Кризисный период, влекущий трансформации и события по темам дома, за который отвечает Плутон (или того, в котором он стоит). Личные потери, разрушение чего-либо. При наличии натальных указаний – протестные настроения, связи с группами людей из мира плутонианских энергий.'
                    ]
                },
                2: {
                    title: 'Плутон по 2 дому',
                    description: [
                        '(+) Масштабирование в темах финансов, серьезное увеличение прибыли, особенно от плутонианской деятельности (серийное производство, большой бизнес, проценты). Приток чужих средств, займы на удачных условиях, наращивание совместного с кем-либо имущества или возможность использовать коллективное/арендное/предоставленное компанией.',
                        '(-) Серьезные финансовые кризисы, банкротства. Потеря имущества, рост долгов, вовлечение в финансовые проблемы других людей, изматывающие споры, расходы и состояние перманентного эмоционального напряжения из-за денег/ресурсов/имущества.',
                        'Транзит может дать проблемы со здоровьем (в частности из-за оппозиции к VIII дому гороскопа).'
                    ]
                },
                3: {
                    title: 'Плутон по 3 дому',
                    description: [
                        '(+) Кратное увеличение числа поездок, контактов, масштабирование продаж. Мощное погружение в учебу или какую-то отдельную тему, которая представляет для натива интерес (отличное время для исследований). Формирование своего особого круга приближенных, тесный психологический контакт с родственниками.',
                        '(-) Кризисные ситуации в поездках и на учебе. При наличии соответствующих показателей в натальной карте - риск аварий. Ближайшее окружение формирует неприятную, давящую атмосферу. Напряжение при контактах и выстраивании отношений с родственниками'
                    ]
                },
                4: {
                    title: 'Плутон по 4 дому',
                    description: [
                        '(+) Трансформация в темах недвижимости и рода. Возможно строительство или ремонт дома, принятие или осуществление решения о переезде.',
                        '(-) Напряжение в отношениях с семьей. Проблемы с домом: потеря или необходимость что-то срочно менять, перестраивать, переделывать. Риск потери одного из родителей, трансформация отношений, уход из семьи, разрушение семейного уклада.'
                    ]
                },
                5: {
                    title: 'Плутон по 5 дому',
                    description: [
                        '(+) Творческие и созидательные порывы, появление нового эмоционального увлечения. Трансформация по теме детей (при дополнительных указаниях может дать рождение ребенка), глубокая вовлеченность и сосредоточенность на их делах.',
                        '(-) Пагубные романтические связи, роковые любовные отношения, гипертрофированный интерес к сексу, удовольствиям, праздникам и азартным играм. На таком транзите человек может проиграть все до нуля'
                    ]
                },
                6: {
                    title: 'Плутон по 6 дому',
                    description: [
                        '(+) Трансформация в штабе подчиненных и серьезные изменения операционной деятельности, идущие во благо. Часто рабочие процессы приходится полностью выстраивать с нуля. Излечение от затяжной болезни, перерождение натива по теме здоровья, радикальный пересмотр образа жизни.',
                        '(-) Операционная деятельность и команда подчиненных вытягивают все силы, возможна резкая перестановка кадров, недовольства, разрушение привычной системы. Для наемного работника может означать серьёзные проблемы на работе вплоть до увольнения. Напряженный период для здоровья, необходимо постоянное внимание со стороны натива.'
                    ]
                },
                7: {
                    title: 'Плутон по 7 дому',
                    description: [
                        '(+) Перерождение темы отношений, выход партнерства на новый уровень. Если нет текущих партнеров – начало новых отношений (как символизм зарождения чего-то нового). Масштабный рост популярности, увеличение контактов с общественностью, наращивание власти.',
                        '(-) Опасный период для партнерских отношений (как личных, так и деловых). Риск разрывов, разводов, судебных разбирательств. Общественное порицание, травля, необходимость противостоять большому количеству людей.'
                    ]
                },
                8: {
                    title: 'Плутон по 8 дому',
                    description: [
                        '(+) Период интенсивного проживания энергий Плутона. Мощные трансформации, рискованные операции, которые заканчиваются для натива успешно. Масштабирование бизнеса, приток чужих денежных средств (инвестиции, удачные займы и т.п.)',
                        '(-) Крайне неблагоприятный транзит. Пространство фонит темами смерти, криминала, чужими проблемами, в которые мы вовлекаемся (приходится брать ответственность за чужие долги – как самый распространенный вариант из практики). Плохое время для операций с деньгами, финансовые потери. Возможен серьезный удар по здоровью'
                    ]
                },
                9: {
                    title: 'Плутон по 9 дому',
                    description: [
                        '(+) Трансформация мировоззрения, пересмотр духовных ориентиров и идеалов. Погружение в темы преподавания, наставничества, коучинга, масштабирование в них. При наличии показателей в натальной карте данный транзит может дать эмиграцию.',
                        '(-) Потери и травмирующие ситуации за границей. Риск аварий и катастроф с участием других людей. Пагубные, затягивающие религиозные и идеологические учения, секты, обрушение привычной картины мира.'
                    ]
                },
                10: {
                    title: 'Плутон по 10 дому',
                    description: [
                        '(+) Мощное возвышение, получение власти, статуса, высокой должности. Резкая смена профессии, начало кардинально нового витка профессионального развития. Рост популярности, возможность оказывать влияние на массы людей.',
                        '(-) Потеря работы, положения, социального признания, мучительные негативные перемены в деловой среде. Полная профессиональная трансформация. Конфликты с начальством или властью, которые становятся достоянием общественности.'
                    ]
                },
                11: {
                    title: 'Плутон по 11 дому',
                    description: [
                        '(+) Активное участие в общественной, политической или гуманитарной деятельности. Трансформация круга единомышленников, планов и мечтаний. Серьезная вовлеченность в дела друзей, онлайн-проекты и борьбу за равноправие.',
                        '(-) Неблагоприятный период для вложений в коллективные проекты, в том числе сетевые. Конфликты с друзьями, разрывы контактов, проблемы в жизни детей партнера.',
                    ]
                },
                12: {
                    title: 'Плутон по 12 дому',
                    description: [
                        '(+) Период глубинного познания себя, трансформация психики, установок. Погружение в темы магии, благотворительности, духовности. Прекрасное время для ретритов, самостоятельных индивидуальных практик.',
                        '(-) Натив склонен разрушать сам себя. Сложный период, когда подсознание активно влияет на реальность, проявляя все страхи, комплексы, деструктивные установки. Риск оказаться в полной изоляции не по своей воле (госпитализация, лишение свободы).'
                    ]
                },
            },
            ruler: {
                1: {
                    title: 'Плутон по управителю 1 дома',
                    description: [
                        'Трансформации подвергаются личные проявления человека, внешний вид, манеры поведения, трансляция себя в обществе. Человек полностью пересматривает методы и вид контактирования с внешним миром, меняется его представление о самом себе.',
                        '(+) Человек черпает энергию от каждого вдоха, силы берутся "из ниоткуда". Человек может стать более пробивным, настойчивым в проявлении себя. Может появиться тяга к изменению тела в стиле «до/после» или желание сделать тату.',
                        '(-) Натив может стать более эгоистичным, напористым, протестующим, ищущим конфликта. Возможно, поведение человека становится более вульгарным, раскрепощенным, даже «развязанным». Упрямство и бесцеремонность может мешать человеку контактировать с окружающим миром.'
                    ]
                },
                2: {
                    title: 'Плутон по управителю 2 дома',
                    description: [
                        'Высока вероятность кризисных ситуаций, переломных моментов в финансовой стороне жизни.',
                        '(+) Финансовая удача может появиться из другого, ранее не изведанного источника. В меньшей степени проявления Плутона - натив будет ощущать сильные эмоциональные переживания, связанные с финансами. Так же у человека может появиться интуиция на деньги и финансовые процессы, которая поможет взрастить доходы.',
                        '(-) На работе могут появиться сверхнагрузки, требующие огромной траты энергии. Собственные ресурсы натива претерпевают глобальные изменения. При дисгармоничном Плутоне (в натальной карте) обязательно давать рекомендации подготовить финансовую подушку и быть готовым к нелегким временам.'
                    ]
                },
                3: {
                    title: 'Плутон по управителю 3 дома',
                    description: [
                        'Трансформации подвергаются темы коммуникаций, поездок, братьев/сестёр, транспорта, коммерции и документооборота.',
                        '(+) Натив может постоянно посещать различные курсы, изучать материал по интересующей его теме. Возможно расширение, обновление круга общения, командировки. Увеличение продаж, «включение» навыка коммерсанта. У человека открывается талант убеждать, добираться до сути, возможно даже манипулировать.',
                        '(-) Круг общения порождает негативные эмоции; напряженные отношения с братьями/сестрами, соседями, знакомыми. Человек может начать материться больше чем обычно, критиковать окружающих, сплетничать, злословить. Возможна травля в школе или чернение в социальных сетях, поломки гаджетов, автомобиля.',
                        'Следует дать рекомендацию быть аккуратнее за рулем.'
                    ]
                },
                4: {
                    title: 'Плутон по управителю 4 дома',
                    description: [
                        'Изменениям подвергаются темы семьи, родственников, недвижимого имущества.',
                        '(+) Возможна удача при получении недвижимости в наследство. Натива может охватить желание оформить свой дом в плутонианские декорации: больше темных цветов, обустроить камин или добавить символики потустороннего мира в жильё.',
                        '(-) Возможно выражение сложной плутонианской энергии в семье – ссоры, конфликты с родными. Возможны тяжелые ситуации с родственниками, несчастные случаи. Риски при покупке недвижимости, в том числе с заёмными средствами. При отсутствии перспектив приобретения нового жилища – возможны проблемы извне, такие как пожар, ограбление, вынужденный переезд.'
                    ]
                },
                5: {
                    title: 'Плутон по управителю 5 дома',
                    description: [
                        'Трансформациям подвергаются темы детей, отдыха, азартных игр, любовников. Могут глобально меняться хобби, увлечения.',
                        '(+) Натив может очень страстно влюбиться, до беспамятства. Такие отношения могут захлестнуть с головой, вызывать бурю эмоций (любых, от дикой ревности до трепетания бабочек) и эта буря будет приносить удовлетворение нативу.',
                        'Так же у человека может появиться новое хобби, увлечение, которое связано с экстримом, на грани жизни и смерти, либо связанно с эротикой, сексом, например, стрип-дэнс, танцы на пилоне или фотографии в стиле «ню».',
                        '(-) Энергетический всплеск может затронуть тему любовниц(-ков): человек с транзитным Плутоном по управителю 5 дома может влюбиться в партнера, которого надо спасать, или физическое насилие, или постоянные конфликты на фоне ревности или измен. Возможна чрезмерная смена сексуальных партнеров. Возможны сложности с детьми: от зачатия, вынашивания и родов до сложных ситуаций в общении с детьми – при прочих указаниях в других методах прогноза!',
                        'Так же возможны проблемные ситуации на отдыхе, на увеселительных мероприятиях, большие проигрыши в азартных играх.'
                    ]
                },
                6: {
                    title: 'Плутон по управителю 6 дома',
                    description: [
                        'Кризисным ситуациям подвергаются темы наёмного труда, рутинной, ежедневной работы, здоровья, домашних животных и заботы о них.',
                        '(+) У человека может возникнуть непреодолимое желание завести ящерицу, змею, паука в качестве домашнего животного (или хищника, опасного зверя).',
                        'На работе возможна смена должности на контролёра, ревизора, наблюдателя – аналитика. Возможно, управление массами людей. ',
                        '(-) На таком транзите высок риск экстренных операций, травм. Возможны душевные переживания из-за домашнего питомца (потеря или врачебные манипуляции).',
                        'Возможны стычки с коллегами на работе или подчиненными работниками. Либо конфликтные ситуации с недавно появившимся коллегой, на которые тратятся все силы. Аврал на работе, сверхвысокая загруженность без отдачи. Риск увольнения.'
                    ]
                },
                7: {
                    title: 'Плутон по управителю 7 дома',
                    description: [
                        'Трансформируются темы партнерства – от отношений с бизнеспартнером или супругом до контакта с аудиторией.',
                        '(+) При гармоничном Плутоне (в натальной карте) транзит сулит появление партнера с очень мощной энергетикой, который мастерски обращается с деньгами, талантливо убеждает, с легкостью выходит из любого кризиса.',
                        'При таком транзите совместные занятия спортом, психологией, экстримом, страстными танцами – укрепляют отношения.',
                        '(-) Кризисное время с партнером – недопонимание, конфликты, ссоры, ощущение будто «черная кошка пробежала». Давление в отношениях, насилие физическое или эмоциональное. Предательство партнера, напарника, компаньона. Указание на развод - при прочих подтверждающих указаниях в других методах прогноза!',
                        'Высок риск судебных разбирательств, масштабные скандалы, «все против меня»'
                    ]
                },
                8: {
                    title: 'Плутон по управителю 8 дома',
                    description: [
                        'Такое положение транзитного Плутона (как символический управитель) «включает» энергетику дома, тем самым увеличивая вероятность событий по сферам этого дома.',
                        'Трансформации подвергаются темы «чужих», заёмных денег, бизнеса, наследства. Возможно попадание под влияние или преднамеренное занятие магией, мистическими ритуалами.',
                        '(+) Человек может успешно начать заниматься гипнозом, управлять энергией людей, контактировать с мертвыми. На таком транзите человек может стать донором крови или начать помогать онкологическим больным.',
                        'Собственный магнетизм человека возрастает, к нему влечёт, манит. Может появиться тяга к экстриму, владению оружием, к состояниям с повышенным адреналином. Человек на таком транзите может с легкостью обращаться с большими суммами денег.',
                        '(-) Разрушениям подвергается всё отжившее и устаревшее. Большой риск возникновения опасностей, острых внезапных тяжелых заболеваний, операций, травм. Повышенный риск насильственных и агрессивных ситуаций. Опасность могут представлять толпы людей, скопления с мощным настроем и энергетикой – концерты, стадионы. Опасность невыплаты кредитов.'
                    ]
                },
                9: {
                    title: 'Плутон по управителю 9 дома',
                    description: [
                        'Изменениям подвергаются темы высшего образования, путешествий, дальних поездок, заграницы вцелом, меняется представление о «чужой» культуре. Меняется собственная философия жизни и мировоззрения, религия подвергается трансформации.',
                        '(+) Человек выбирает такие места для путешествий, которые его наделяют невероятной энергией, так называемые «места силы»; путешествия переворачивают привычное течение жизни, меняя взгляды на жизнь.',
                        'На таком транзите может встретиться уникальный коуч, оказывающий влияние на формирование идеологии (без нежностей и утешений, с острым языком, но, при этом, каждое слово прямо в сердце). Вообще, человек может получить какой-то навык, умение, знание, в результате которого произойдет трансформация личности.',
                        '(-) Высокий риск проблем в получении высшего образования, уникальных знаний; конфликты с наставником, преподавателем. Философия жизни может идти вразрез с обстоятельствами, что заставит переосмыслить цену собственных убеждений. ',
                        'Проблемы за рубежом – большой риск невылета из страны, потери документов или проблемы с получением гражданства.'
                    ]
                },
                10: {
                    title: 'Плутон по управителю 10 дома',
                    description: [
                        'Искажениям подвергаются темы карьеры, социального положения.',
                        'Трансформируется отношение к руководящим должностям, политике и моральные ценности натива приобретают новый смысл.',
                        '(+) Продвижение по карьерной лестнице. Начальник (в женской карте может быть супруг) оказывает сильное влияние на развитие личности. Может указывать на улучшения дел супруга (меняется социальный статус на «жена миллионера», к примеру).',
                        '(-) Человек ощущает себя социально нереализованным, ощущает недовольство своим положением в обществе. Возможно на таком транзите испортятся отношения с начальником на фоне конфликтов, ссор, манипуляций и агрессии со стороны вышестоящего. Высокий риск увольнения – при прочих подтверждающих указаниях в других методах прогноза! В женской карте – одно из указаний на тиранию со стороны супруга или развод (при прочих подтверждающих указаниях).'
                    ]
                },
                11: {
                    title: 'Плутон по управителю 11 дома',
                    description: [
                        'Перевороту подвергаются планы, идеи об идеальном мире, о будущем. Меняются отношения с единомышленниками, соратниками, с теми, с кем общая цель. Возможно участие в боевых дружинах или митингах.',
                        '(+) На таком транзите у натива могут рождаться новые идеи с космической скоростью, все мысли реформаторские, смелые. Легко находятся единомышленники в окружении. Человеку с таким транзитом легко удается оказывать влияние на массы людей, управлять ими.',
                        'Изучение астрологии, нумерологии может захватить человека с головой.',
                        '(-) Конфликты с близким кругом соратников, давящая атмосфера. Ревность к остальным участникам группы, манипуляции и зависть.'
                    ]
                },
                12: {
                    title: 'Плутон по управителю 12 дома',
                    description: [
                        'Стрессовые ситуации связаны с уходом от внешнего мира, уединением и изоляцией. Мощнейшая трансформация психики, самоанализ.',
                        '(+) У человека может появиться творческое вдохновение, осознание духовного мира, ясновидение, эзотерические способности. На транзите Плутона по управителю 12 дома человека может увлечь борьба с насилием, благотворительность в пользу обездоленных, борьба за экологию. Можно дать рекомендацию заниматься энергетическими практиками (йога, медитации) – они будут положительно влиять на здоровье.',
                        '(-) На таком транзите возможно ограничение свободы любого рода. В такой период опасность представляют враги, которые могут действовать скрытыми методами. Возможно развитие психосоматических заболеваний с неясным генезом, а также психоэмоциональных расстройств'
                    ]
                },
            }
        },
        neptun: {
            planet: {
                sun: {
                    title: '',
                    description: [
                        'Нептун по Солнцу - новое самоощущение личных и духовных качеств натива, новое личное восприятие реальности',
                        '(+) Время творчества и искусства, раскрытия новых духовных граней своей личности. Желание внутреннего развития, увлечение различными практиками (медитации, йога, психология). Обострение интуиции, чувственности. На таком транзите человек более склонен прощать кого-либо, отпускать обиды.',
                        '(-) Время сильных зависимостей и искаженного восприятия действительности. Проблемы психологического характера, подверженность чужому влиянию. Мнительность, депрессивные настроения. Натив склонен интерпретировать реальность неадекватно и принимать решения на основе неверных суждений.'
                    ]
                },
                moon: {
                    title: '',
                    description: [
                        'Нептун по Луне - усиленное проживание эмоций.',
                        '(+) Повышенная сентиментальность и восприимчивость к любым мыслям и высказываниям. Воображение и проницательность могут дать ответ при трудной ситуации. Активируется воображение, сновидения становятся «знаковыми», глубокими. Усиливаются творческие порывы и артистические способности. Натив становится более миролюбивым, спокойным, производит впечатление просвещенного.',
                        '(-) Опасность обманов, иллюзий и чужого влияния. Натив склонен уходить в мечты, далекие от реальности, идеализировать все вокруг и, как следствие, разочаровываться после. Болезненные подсознательные процессы, эмоциональные качели, глубокие зависимости, выхода из которых не видно. '
                    ]
                },
                mercury: {
                    title: '',
                    description: [
                        'Нептун по Меркурию - иное восприятие информации и новая поведенческая модель в коммуникациях с миром.',
                        '(+) Натив начинает мыслить глубже, видеть подоплеку событий и мотивы людей. Обостряется (или возникает) интерес к изучению нептунианских тем: религия, психология, мистика, искусство. В период данного транзита происходит соприкосновение с тайной, закрытой информацией. Люди могут сами рассказывать свои секреты, личные диалоги становятся очень глубокими.',
                        '(-) Подверженность обманам со стороны мошенников и склонность самого натива доносить информацию искаженно (осознанная подтасовка данных или неосознанное приукрашивание реальности – возможны любые проявления). Самообман, отсутствие успеха в учебе, затруднения в усвоении любого материала. Рассеянность, забывчивость, спутанность мыслей. Особое внимание необходимо обращать на подписание документов и ведение переговоров.'
                    ]
                },
                venus: {
                    title: '',
                    description: [
                        'Нептун по Венере - новое восприятие любовных отношений, чувственности, романтики.',
                        '(+) Усиливается эстетическое восприятие, потребность в духовном развитии и творческом самовыражении. Период благоприятен для изучения искусства. Один из лучших транзитов для актеров, художников, музыкантов. Повышается восприимчивость в отношениях, растет желание больше времени проводить с любимым человеком. Транзит может дать ощущение невероятной душевной близости с партнером и глубокую влюбленность тем, кто на момент транзита не в отношениях.',
                        '(-) Сложный период в отношениях, склонность к обманам, изменам (с обеих сторон). Трудности в любви, эмоциональные страдания, неадекватное восприятие отношений или партнера, перманентная эмоциональная неудовлетворённость ситуацией в паре. '
                    ]
                },
                mars: {
                    title: '',
                    description: [
                        'Нептун по Марсу – новая мотивация к действиям, для женщин может давать иное восприятие мужчин/любовников.',
                        '(+) Успеху в делах способствует предпринимательская интуиция, хитрость, здоровая конкурентоспособность. Получая подпитку от высшей планеты, Марс становится более устойчивым. Натив черпает силы из подсознания и глубинных психологических установок, что помогает оставаться верным выбранному курсу. Прекрасный период для активного развития и демонстрации талантов.',
                        '(-) Потеря бдительности, отсутствие фокуса в действиях, навязчивые, фанатичные убеждения. Маниакальное желание что-либо совершить.',
                        'Характерно вовлечение в интриги, борьбу за власть, тайную деятельность. Неадекватная оценка своих действий и абсолютное неприятие последствий.'
                    ]
                },
                jupiter: {
                    title: '',
                    description: [
                        'Нептун по Юпитеру - новое восприятие своей роли в социуме.',
                        '(+) Повышается духовная сознательность, приверженность религиозным идеям, щедрость души. Прекрасный период для путешествий и исследований. Хорошее время для фотографов, представителей сферы киноиндустрии, для деятелей искусства. Возможны тайные, закулисные контакты с влиятельными людьми.',
                        '(-) Характерен поиск себя в религии и различных учениях, внутренние метания. Неудачи или препятствия в поездках и путешествиях. Странные события, ситуации мошенничества, бессознательное участие в противоправной деятельности.',
                        'Неприятный период, когда все внутренние ориентиры, принципы и моральные нормы размываются, нативу сложно позиционировать себя в обществе.'
                    ]
                },
                saturn: {
                    title: '',
                    description: [
                        'Нептун по Сатурну - новое восприятие профессионального и личностного развития, чаще дает именно события, а не психоэмоциональный фон.',
                        '(+) Время глубокой оценки своего профессионального положения на соответствие внутренним запросам и ориентирам. Подлинные цели выходят на поверхность, человек предпринимает реальные действия и выбирает «путь души» (отказ от нелюбимой работы, даже если натив в ней успешен или изменение иного социального статуса).',
                        '(-) Карьера и репутация могут пострадать в результате обмана или мошенничества. Размываются цели, нет понимания, к чему двигаться. Внутренние идеалы и жесткая окружающая действительность не совпадают, натив погружается в уныние, потерю уверенности в себе.'
                    ]
                },
                uranus: {
                    title: '',
                    description: [
                        'Нептун по Урану, Плутону и своему натальному положению проявится в темах тех домов, где находятся эти планеты или которыми они управляют. Сила проявления этих обстоятельств зависит от аспектов и личных показателей в натальной карте. '
                    ]
                },
                neptun: {
                    title: '',
                    description: [
                        'Нептун по Урану, Плутону и своему натальному положению проявится в темах тех домов, где находятся эти планеты или которыми они управляют. Сила проявления этих обстоятельств зависит от аспектов и личных показателей в натальной карте. '
                    ]
                },
                pluto: {
                    title: '',
                    description: [
                        'Нептун по Урану, Плутону и своему натальному положению проявится в темах тех домов, где находятся эти планеты или которыми они управляют. Сила проявления этих обстоятельств зависит от аспектов и личных показателей в натальной карте. '
                    ]
                },
                nodes: {
                    title: '',
                    description: [
                        'Нептун по Северному/Южному узлам',
                        '(+) При соединении с Северным узлом возникает необходимость изучать темы Нептуна для нового понимания себя и своего пути.',
                        'Ситуативно такой транзит будет проявлять в нас глубинные убеждения и подсознательные программы. Тайные ситуации (чужие или с участием натива) могут стать важным кармическим уроком. Полезно заниматься благотворительностью.',
                        '(-) На поверхность выходят старые страхи, могут возвращаться люди из прошлого, демонстрируя нам важность принятых ранее решений.',
                        'Категорически не рекомендуется на таком транзите возобновлять старые тайные связи и контакты. Важно держать границы и не жертвовать собой.'
                    ]
                },
                lilit: {
                    title: '',
                    description: [
                        'Нептун по Лилит - опасный аспект. Время погружения в свои скрытые (деструктивные!) желания и зависимости. Процесс происходит медленно и незаметно для натива. '
                    ]
                },
            },
            house: {
                1: {
                    title: 'Нептун по 1 дому',
                    description: [
                        '(+) Натив становится мягче, восприимчивее, раскрываются творческие способности, проявляется интерес к духовности, психологии. Хороший период для уединения и практик. Развивается интуиция, сны становятся важной составляющей жизни.',
                        '(-) Период лени, апатии, рассеянности, неадекватной жалости к себе. Проявляются все имеющиеся зависимости и плотно входят в жизнь человека. Время самообмана, жизни в розовых очках (или наоборот излишне пессимистичных настроений). Все неприглядное и скрытое в глубине личности выходит на поверхность.'
                    ]
                },
                2: {
                    title: 'Нептун по 2 дому',
                    description: [
                        '(+) Для всех представителей нептунианских профессий – удачный финансовый период. Деньги могут появляться странными путями, буквально из ниоткуда. Благотворительность неожиданно приносит реальные плоды. Сам натив также может получать пожертвования или иную финансовую поддержку.',
                        '(-) Полная неразбериха в деньгах. Финансы постоянно куда-то «утекают», удержать их не представляется возможным. Риск быть обманутым, приобрести поддельный товар, нарваться на мошенников и т.п.'
                    ]
                },
                3: {
                    title: 'Нептун по 3 дому',
                    description: [
                        '(+) Раскрывается образное мышление и способность верно интерпретировать интуитивные озарения. Растет интерес ко всему загадочному, тайному, запутанному. Хорошее время для начала изучения языков или иностранной культуры, а также для развития поэтического или писательского дара. Жизнь в этот период постоянно сталкивает натива с чужими секретами.',
                        '(-) Существенно затрудняется восприятие информации. Мысли путаются, сосредоточиться очень тяжело. Вскрываются неприятные факты об окружении, отношения с ближайшим кругом становятся путаными, люди отдаляются друг от друга. Плохой период для подписания документов, неприятности, потери и обманы в поездках.'
                    ]
                },
                4: {
                    title: 'Нептун по 4 дому',
                    description: [
                        '(+) Глубокий эмоциональный интерес к изучению своих корней, истории рода. Может открываться новая интересная информация о семье и/или происхождении натива. Душевная атмосфера понимания в доме.',
                        '(-) Отношения с родными оставляют желать лучшего: недоговоренности, обиды, подозрения. Люди чувствуют себя чужими друг другу, отдаляются. Риски затопления в доме, неисправностей с водопроводом и т.п.'
                    ]
                },
                5: {
                    title: 'Нептун по 5 дому',
                    description: [
                        '(+) Время романтических влюбленностей, флирта, иногда романов на расстоянии или исключительно эмоциональной, душевной связи. Период вдохновения для творческих людей, есть возможность создать истинный шедевр. Повышается восприимчивость к красоте, рождаются новые нептунианские хобби.',
                        '(-) Случайные, запутанные романы и связи. Обманы и измены со стороны любовников или самого натива. Бесконтрольные развлечения, азартные игры (вообще игромания как таковая). Неадекватная зависимость от атмосферы праздника. Всплывают тайны, связанные с детьми.'
                    ]
                },
                6: {
                    title: 'Нептун по 6 дому',
                    description: [
                        '(+) При таком транзите более эффективными часто оказываются нетрадиционные методы лечения. Сам натив может глубоко увлечься психосоматикой, целительством, начать изучать БАДы и лечебные травы. Период привязанности к работе, можно заручиться поддержкой коллег.',
                        '(-) Неприятный период для здоровья: все внутренние тревоги, страхи и непроработанные эмоции проявляются через тело. Расцвет психосоматических болезней. Особенно частыми становятся отравления, неврозы, аллергии, риниты. Путаница в постановке диагноза. Для владельцев бизнеса период неразберихи в процессах и странных настроений среди подчиненных (вплоть до выявления недобросовестных сотрудников, мошенников). Кто-то может покинуть команду, внеся сумятицу в дела. Для наемных работников – лень и апатия на рабочем месте, риск увольнений, безработицы или «подковерных» игр.'
                    ]
                },
                7: {
                    title: 'Нептун по 7 дому',
                    description: [
                        '(+) Период жизни «душа в душу» с партнером. Начало важной связи с людьми нептунианского типа, а также скрытых, но значимых для натива отношений. Человек может стать центром притяжением чужих тайн, ему доверяют и посвящают в нечто сокрытое от посторонних глаз. Можно снискать публичное признание через трансляцию духовных идей, благотворительность меценатство.',
                        '(-) Время тотальных разочарований в деловом партнерстве и/или браке. Вскрываются тайны партнера, либо сам натив «уплывает» в отношения на стороне. Транзит может дать вынужденное длительное расставание с важным человеком. Нередки случаи, когда при таком транзите партнер уходит зависимости. Неблагоприятное время для подписания новых договоров или поиска деловых партнеров – можно нарваться на мошенников. Трудности в понимании других людей, неадекватная оценка их поведения. '
                    ]
                },
                8: {
                    title: 'Нептун по 8 дому',
                    description: [
                        '(+) Многократное обострение интуиции и внутреннего чутья. Знаковые сны, бесконечные подсказки пространства. Глубокое погружение в темы духовности, раскрытие внутренних экстрасенсорных способностей, проживание абсолютно новых ощущений, состояние измененного сознаний (гипнозы, регресс, мощные медитативные практики и т.п.) Возможность получения чужих денежных средств.',
                        '(-) Крайне опасный период для натива. При подтверждающих показателях в натальной карте возможны серьезные психические расстройства, амнезия, отравления токсинами, передозировка веществ и опасность на воде. Склонность к неоправданным рискам в бизнесе, серьезные потери денег, в том числе совместных или потери у партнера.'
                    ]
                },
                9: {
                    title: 'Нептун по 9 дому',
                    description: [
                        '(+) Время обретения новых ценностей, глубокое серьезное изучение религии, философии, психологии, мистики. Натив может укрепиться в своей вере или встретить значимого духовного наставника. Путешествия окрашены глубинными духовными осознаниями, часто происходят паломничества по святым местам. Благоприятный период для эмиграции при наличии иных имеющихся указателей.',
                        '(-) Идеализация и последующие разочарования, связанные с заграницей. Неприятные ситуации и мошенничества за рубежом. Внушаемость и подверженность новой вере. '
                    ]
                },
                10: {
                    title: 'Нептун по 10 дому',
                    description: [
                        '(+) Удача в профессиональной сфере для всех представителей нептунианских профессий, а также через публичные занятия благотворительностью. Достижение целей по темам домов, которыми управляет Нептун. Натив может заручиться тайной поддержкой от сильных мира сего, а также получить повышение (однако, часто не самым честным путем).',
                        '(-) Недоверие и обманы со стороны начальства. Надежды на продвижение по карьерной лестнице не оправдываются или новая должность в конечном итоге разочаровывает и оказывается совсем не такой, какой ее представлял натив. Человек плавает в собственных планах, не может ясно сформулировать цель.'
                    ]
                },
                11: {
                    title: 'Нептун по 11 дому',
                    description: [
                        '(+) Отношения с друзьями выходят на новый уровень доверия и взаимопонимания. Активация тем Нептуна через большие коллективы и группы единомышленников: натив может вступить в клуб защитников природы или какой-то иной, но с похожим нептунианским подтекстом.',
                        '(-) Вскрывается истинное положение дел в компании друзей. Период разочарований в дружбе, Нептун «вымывает» некоторых людей из окружения. Неадекватная, слепая приверженность отдельным общественным идеям или начинаниям.',
                    ]
                },
                12: {
                    title: 'Нептун по 12 дому',
                    description: [
                        '(+) Хороший транзит Нептуна, если он не поврежден в карте. Внутренний мир гармонизируется, человек ощущает целостность, устойчивость, незримую поддержку пространства. Полезны любые духовные практики, уединение, контакт с природой, углубление в темы психологии и мистики. Происходит интуитивное понимание законов мироздания и неосознанное следование им.',
                        '(-) Опасный транзит Нептуна, если планета в карте расположена неудачно. Психические расстройства и фобии проявляются очень сильно, уводя натива в зависимости, отшельничество, иногда просто человек начинает отгораживаться от общества, морально деградирует. Полная потеря и дезориентация в реальном слое пространства. Человек не способен трезво оценивать ни себя, ни поступки других людей.'
                    ]
                },
            },
            ruler: {
                1: {
                    title: 'Нептун по управителю 1 дома',
                    description: [
                        'Трансформации подвергаются личные проявления человека, внешний вид, манеры поведения, трансляция себя в обществе.',
                        '(+) Открывается талант чувствовать энергию вокруг себя, невероятная интуиция и истинное ощущение себя органичной частью мира.',
                        '(-) Натив может стать более оторванным от реальности, строить вокруг себя иллюзии и верить в них. Может впадать в жертвенность. Могут усилиться ситуации вранья, мошенничества. Высочайшая эмоциональность, мнительность.',
                        'При сильном Нептуне велика вероятность попасть в алкогольную зависимость – при прочих подтверждающих указаниях в натальной карте!'
                    ]
                },
                2: {
                    title: 'Нептун по управителю 2 дома',
                    description: [
                        'Возможны неразберихи, неясности финансовой стороны жизни.',
                        '(+) У натива часто случаются ситуации «только помечтал/сказал, оно в жизни появилось», способность визуализировать материальные блага. Средства поступают из неожиданных источников: подарки, находки, упражнения на расширения финансового потока, приметы.',
                        'Сохранность чужих тайн и секретов благоприятно влияет на финансы.',
                        '(-) Хаос в финансах, деньги как вода – только что были, и незаметно потрачено всё до последнего рубля. Риск мошенничества, потеря денег от гипноза (цыганка увлекла) или по собственной невнимательности.'
                    ]
                },
                3: {
                    title: 'Нептун по управителю 3 дома',
                    description: [
                        'Искажениям подвергаются темы коммуникаций, поездок, братьев/сестёр, транспорта, коммерции и документооборота. Человека может увлечь чтение или просмотр документальных фильмов, детективов',
                        '(+) Счастливый случай может помочь избежать ДТП или выбраться из него целым и невредимым.',
                        'Большая вероятность получить в подарок гаджет/телефон/машину. На таком транзите может получиться попасть в обучение за границей или обучение по обмену.',
                        '(-) Отношения с братьями/сестрами, лучшим другом может испортится из-за недомолвок, лжи, обмана или зависимости (алкогольной) как с одной, так и с другой стороны. Путаница с документами. Возможны обманы в социальных сетях, потери гаджетов, угон автомобиля. Следует дать рекомендацию быть аккуратнее за рулем, не садиться за руль в нетрезвом виде. '
                    ]
                },
                4: {
                    title: 'Нептун по управителю 4 дома',
                    description: [
                        'Изменениям подвергаются темы семьи, родственников, недвижимого имущества.',
                        '(+) Натива может охватить желание оформить свой дом в нептунианские декорации: добавить зеркал, купить аквариум, сделать фотостену с водопадом. Возможна удача при получении недвижимости в подарок. На таком транзите возможен переезд заграницу или приобретение недвижимости за рубежом.',
                        '(-) Возможны ссоры, конфликты с родными на почве обманов, лжи или зависимостей. Возможны тяжелые ситуации с документами при приобретении недвижимости, риск мошенничества. Возможны проблемы с жилищем, такие как ограбление, затопление соседями или соседей.'
                    ]
                },
                5: {
                    title: 'Нептун по управителю 5 дома',
                    description: [
                        'При транзите Нептуна по управителю 5 дома трансформациям подвергаются темы детей, отдыха, азартных игр, любовников. Хобби и увлечения могут носить более творческий характер (живопись, музыка, театр).',
                        '(+) У натива с транзитным Нептуном по управителю 5 дома увеличивается шанс на выигрыш в какой-либо благотворительной акции. Медитации, места силы, посещение религиозных мест могут помогать в детском вопросе (от зачатия и вынашивания до общения со взрослым ребенком). При актуальности вопроса можно дать рекомендацию посещение бассейна, занятия йогой, психологией (как изучение, так и консультации).',
                        '(-) Натив может влюбиться в очень странного, скрытного человека. Может посещать свидания из сострадания или из-за иррационального, необъяснимого порыва.',
                        'Высокие риски мошенничества и обманов в отпуске. Так же возможны проблемные ситуации на отдыхе, на увеселительных мероприятиях, большая вовлеченность в азартные игры.'
                    ]
                },
                6: {
                    title: 'Нептун по управителю 6 дома',
                    description: [
                        'Изменениям подвергаются темы наёмного труда, рутинной, ежедневной работы, здоровья, домашних животных и заботы о них.',
                        '(+) На таком транзите велика вероятность появления животного из приюта (можно дать такую рекомендацию для проработки негармоничного Нептуна). Нетрадиционная медицина, аюрведа, йога помогают поддерживать здоровье.',
                        '(-) Высок риск появления заболевания, которое не могут диагностировать врачи. Так же возможно неверное диагностирование, ошибочное назначение лечения.',
                        'На таком транзите возможны сплетни и обманы на работе, в рабочем коллективе.'
                    ]
                },
                7: {
                    title: 'Нептун по управителю 7 дома',
                    description: [
                        'Реформируются темы партнерства – от отношений с бизнеспартнером или супругом до контакта с аудиторией.',
                        '(+) При гармоничном Нептуне (в натальной карте) транзит сулит появление партнера-иностранца или партнера рыбьего типажа (творческий, хитрый, интуит, философ, йог, любит природу/рыбалку, пловец, врач, фармацевт и тд). При таком транзите совместные занятия йогой, психологией, посещение театров, музеев, кино – укрепляют отношения.',
                        '(-) Кризисное время с партнером – недопонимание, тайны от супруга. ',
                        'Возможно появление зависимостей или странных болезней супруга. ',
                        'Возможно появление токсичных отношений или отношений на расстоянии. Высок риск странных судебных разбирательств, возможно участие подставных людей.'
                    ]
                },
                8: {
                    title: 'Нептун по управителю 8 дома',
                    description: [
                        'Искажениям подвергаются темы «чужих», заёмных денег, бизнеса, наследства. Возможно попадание под влияние или преднамеренное занятие магией, эзотерическими ритуалами.',
                        '(+) Человек может успешно начать заниматься гипнозом, работой со снами (вещие сны, внетелесные путешествия), нейрографикой, арттерапией. Могут обрести вес шаманизм, тетахилинг, трансерфинг. На таком транзите человек может стать донором крови или начать помогать онкологическим больным.',
                        'Кризисные ситуации на таком транзите могут решаться каким-то чудом, магией.',
                        '(-) На таком транзите высок риск появления зависимостей разного рода. Опасность обмана при получении кредитных средств или наследства – возможны скрытые условия. ',
                        'Высок риск отравлений, системных заболеваний, психических заболеваний, гормонального сбоя. Следует быть осторожным на воде, остерегаться анестезии и неожиданного (побочного) действия лекарств.'
                    ]
                },
                9: {
                    title: 'Нептун по управителю 9 дома',
                    description: [
                        'Изменениям подвергаются темы высшего образования, путешествий, дальних поездок, заграницы вцелом, меняется представление о «чужой» культуре. Меняется собственная философия жизни и мировоззрения, религия подвергается трансформации.',
                        '(+) Человек выбирает такие места для путешествий, которые его наделяют невероятной энергией, так называемые «места силы»; путешествия переворачивают привычное течение жизни, меняя взгляды на жизнь.',
                        'На таком транзите может встретиться духовный наставник рыбьего типажа (философ, творческий, благородный, эмпатичный) оказывающий влияние на мировоззрение',
                        '(-) Высокий риск срыва путешествия. Так же возможны проблемы за рубежом – большой риск невылета из страны, потери документов или проблемы с получением гражданства.',
                    ]
                },
                10: {
                    title: 'Нептун по управителю 10 дома',
                    description: [
                        'Искажениям подвергаются темы карьеры, социального положения.',
                        'Трансформируется отношение к руководящим должностям, политике и моральные ценности натива приобретают новый смысл.',
                        '(+) При таком транзитном положении планет натив может устроиться на работу/должность, о которой он давно мечтал, возможно, в иностранной компании. Возможно смена начальника на более творческого, духовного, понимающего. В женской карте может означать чувственного, любящего природу, эмпатичного мужа.',
                        '(-) Возможно на таком транзите испортятся отношения с начальником на фоне сплетен, вранья, интриг. Высокий риск увольнения из-за собственной лени, неряшливости, неаккуратности – при прочих подтверждающих указаниях в других методах прогноза! Возможна потеря статуса и авторитета из-за мошенничества, «серых» схем, тайных махинаций.'
                    ]
                },
                11: {
                    title: 'Нептун по управителю 11 дома',
                    description: [
                        'Изменяются планы, идеи об идеальном мире, о будущем. Меняются отношения с единомышленниками, соратниками, с теми, с кем общая цель. Такой транзит может себя никак не проявлять – одно из самых размытых положений.',
                        '(+) Человек может оказаться в сообществе, связанном с религией, духовностью, психологией, творчеством, экологией, благотворительностью.',
                        '(-) Человек может оказаться незаметно втянут в секту. Чаще проявляется как вера в собственные выдуманные иллюзии по поводу будущего. ',
                    ]
                },
                12: {
                    title: 'Нептун по управителю 12 дома',
                    description: [
                        'Такое положение транзитного Нептуна (как символического управителя) «включает» энергетику дома, тем самым увеличивая вероятность событий по сферам этого дома.',
                        'Влияние транзита связано с уходом от внешнего мира, уединением и изоляцией. Происходит мощнейшая трансформация психики, самоанализ.',
                        '(+) У человека может появиться творческое вдохновение, осознание духовного мира, ясновидение, эзотерические способности. На транзите Нептуна по управителю 12 дома человека может увлечь благотворительность в пользу обездоленных, заключенных, борьба за экологию, за вымирающих животных. Можно дать рекомендацию заниматься энергетическими практиками (йога, медитации) – они будут положительно влиять на здоровье.',
                        '(-) На таком транзите натив может чувствовать себя непонятым, белой вороной. Может стремиться к отшельничеству, затворничеству. Возможно появление зависимостей любого рода.',
                        'Возможно развитие сложно диагностируемых, редких заболеваний, а также психоэмоциональных расстройств.'
                    ]
                },
            }
        },
        uranus: {
            planet: {
                sun: {
                    title: '',
                    description: [
                        'Уран по Солнцу - внезапные перемены в личности натива. ',
                        '(+) Резкий прилив энергии, способность раздвигать ранее поставленные ограничения, выходить за рамки собственных представлений о себе и своих способностях. Натив способен решиться на важные перемены, резко обозначив новый вектор развития на будущее. ',
                        '(-) Возникает резкое, преувеличенное желание свободы и обновления, натив способен разрушить все ранее выстроенное одним махом. Время странных экспериментов с внешностью, собственным позиционированием, увлечениями. Энергия бьет ключом и становится совершенно неуправляемой. Высок риск возникновения Уранических заболеваний и получения травм от электрических приборов. '
                    ]
                },
                moon: {
                    title: '',
                    description: [
                        'Уран по Луне - внезапные перемены в эмоциональном восприятии натива.',
                        '(+) Повышается интуиция, происходят озарения, натив четко ощущает, как нужно поступить. Удачный транзит Урана по Луне (включая соединение, если планеты в карте расположены гармонично) может стать хорошим периодом для проведения ЭКО. ',
                        '(-) Крайняя эмоциональная неустойчивость, трудности во взаимоотношениях, неврозы, психозы и просто неконтролируемое поведение. Нарушение режима сна и отдыха, нарушение месячного цикла у женщин, психосоматические заболевания. Непредсказуемые ситуации по сферам, связанным с Луной. '
                    ]
                },
                mercury: {
                    title: '',
                    description: [
                        'Уран по Меркурию - внезапные перемены в интеллектуальном и коммуникативном поле натива.',
                        '(+) Резко возрастает интерес к сложной, прогрессивной, оригинальной информации. Натив способен увлечься новой темой и осваивать большие объемы в короткие сроки. Отличное время для учебы и завязывания новых контактов, воплощения креативных идей и разработок. ',
                        '(-) Сложности в обучении и усвоении информации (особенно у детей). ',
                        'Ошибки при оформлении документов, ведении переговоров. ',
                        'Разбросанность ума, импульсивное принятие решений, игнорирование ранее приобретенного опыта. Традиционный транзит проблем с техникой и транспортом.'
                    ]
                },
                venus: {
                    title: '',
                    description: [
                        'Уран по Венере - внезапные перемены в творческой и любовной сфере натива.',
                        '(+) Новая струя творческого вдохновения и необычного видения красоты. Озарения и прорывы и у людей искусства. Неожиданные знакомства, внезапные влюбленности и эксперименты в чувственной сфере. Возрастает потребность в самовыражении. У женщин транзит провоцирует желание перемен во внешности. ',
                        '(-) Пагубные краткосрочные влюбленности, резкая и не вполне удачная смена имиджа. Человека привлекает все экстравагантное, броское, вычурное. Часто после приобретения подобных вещей или предметов наступает разочарование. Склонность к свободе и экспериментам в любви и сексе, которые могут переходить все границы натива. Однако последствия осознаваться будут уже после прохождения транзита.'
                    ]
                },
                mars: {
                    title: '',
                    description: [
                        'Уран по Марсу - внезапные перемены в поле физической активности натива. Рискованный и травмоопасный период.',
                        '(+) Инициативность, предприимчивость, неординарные шаги в предпринимательстве или политике. Период динамических и оригинальных поступков, ведущих к конструктивным преобразованиям во многих сферах. Хороший период для борьбы за независимость. Возможности удачи при рискованных операциях и сделках. ',
                        '(-) Импульсивность, вспышки гнева, стремление к независимости действий любой ценой, суетливость. Классический аспект травм, аварий и катастроф (при имеющихся указателях в натале). Опасность нападения и опасность агрессивного, неконтролируемого поведения со стороны натива '
                    ]
                },
                jupiter: {
                    title: '',
                    description: [
                        'Уран по Юпитеру - внезапные перемены в социальной жизни натива.',
                        '(+) Резкий взлет популярности и признания. Интерес к необычным областям знаний и практикам, к привлечению современных форм руководства. Неожиданный финансовый успех, удачи в поездках и командировках, в образовании, вовлеченность в групповые мероприятия и объединения. Внезапное продвижение дел за рубежом, активизация контактов с иностранцами. ',
                        '(-) Обрушение социального положения в обществе, потеря авторитета. Неприятности и странные стечения обстоятельств за рубежом. Общественное порицание.'
                    ]
                },
                saturn: {
                    title: '',
                    description: [
                        'Уран по Сатурну - внезапные перемены в профессиональном и личностном развитии натива.',
                        '(+) Период радикальных изменений в делах, профессии, карьере, социальном статусе. Успех в разработке долгосрочных планов и проектов, стратегии дальнейших действий, но при условии гармоничного синтеза старых и новых убеждений. Желание ощутить независимость вместе с фундаментом под ногами, выйти за рамки навязанных социальных правил. ',
                        '(-) Неожиданные происшествия и разочарования в работе, карьере, бизнесе, часто - прямые убытки и потери. Вероятен ущерб для репутации. Душевный кризис, внутренний конфликт сложившихся убеждений и новых желаний.'
                    ]
                },
                uranus: {
                    title: '',
                    description: [
                        'Уран по Нептуну, Плутону и своему натальному положению проявится в тех домах, где находятся эти планеты или которыми они управляют. Сила проявления этих обстоятельств зависит от аспектов и личных показателей в натальной карте. '
                    ]
                },
                neptun: {
                    title: '',
                    description: [
                        'Уран по Нептуну, Плутону и своему натальному положению проявится в тех домах, где находятся эти планеты или которыми они управляют. Сила проявления этих обстоятельств зависит от аспектов и личных показателей в натальной карте. '
                    ]
                },
                pluto: {
                    title: '',
                    description: [
                        'Уран по Нептуну, Плутону и своему натальному положению проявится в тех домах, где находятся эти планеты или которыми они управляют. Сила проявления этих обстоятельств зависит от аспектов и личных показателей в натальной карте. '
                    ]
                },
                nodes: {
                    title: '',
                    description: [
                        'Уран по Северному и Южному узлу.',
                        '(+) При соединении с Северным узлом возникают обстоятельства, в которых нативу необходимо учиться действовать иначе: быстро адаптироваться, принимать решения без какого-либо плана и подготовки, следовать за потоком событий и импровизировать на ходу. ',
                        '(-) При соединении с Южным узлом возникают ситуации, когда планы натива рушатся, происходит откат назад, приходится отказываться от личных благ в пользу других людей или идеи.'
                    ]
                },
                lilit: {
                    title: '',
                    description: [
                        'Уран по Лилит. Возрастает склонность к экстравагантным выходкам в поведении, появлению новых странных привычек. Натив становится неуправляемым даже для себя самого, в такой период часто говорят «от него всего можно ожидать».'
                    ]
                },
            },
            house: {
                1: {
                    title: 'Уран по 1 дому',
                    description: [
                        '(+) Желание изменить внешность и свой имидж. Многократно возрастает значение личной свободы, собственной независимости, что может привести к проблемам в партнерстве. В жизни натива могут произойти любые серьезные перемены (женитьба, развод). Также характерны новые увлечения, пристрастия, ритм жизни становится более насыщенным. Открытие новых возможностей в себе самом. ',
                        '(-) Эксцентричность, нездоровая склонность к эпатажу, разрушение привычного образа жизни. Нетерпимость к чужому мнению, навязчивое стремление изменить свою внешность кардинально. Непредсказуемость действий и импульсивность решений увеличивают вероятность травм и несчастного случая. Риск развода или увольнения.'
                    ]
                },
                2: {
                    title: 'Уран по 2 дому',
                    description: [
                        '(+) Доходы от нового вида деятельности (часто Уранического) и просто везение в финансовых делах. Материальное положение натива может измениться благодаря вмешательству независимых от него факторов. Высока вероятность выигрышей, при имеющихся указателях в радиксе. Удачное время для инвестиций и работы с инновационными отраслями. ',
                        '(-) Внезапные потери имущества, материальные убытки, переворот в финансовых делах. Риск необдуманных трат и вложений. Обстоятельства складываются против натива, в делах явно не везет. Финансовый ущерб возможен также в результате аварий, стихийного бедствия, выхода из строя техники. Транзит может дать проблемы со здоровьем и вообще внезапные кризисы (из-за оппозиции к VIII дому гороскопа).'
                    ]
                },
                3: {
                    title: 'Уран по 3 дому',
                    description: [
                        '(+) Натив становится более любознательным, красноречивым, изменяется образ мышления и круг знакомств. Хорошее время для изучения нового материала, в особенности связанного с астрологией, техникой, инновациями и т.п. Могут произойти внезапные события в жизнях братьев, сестер и ближайших родственников. ',
                        '(-) Высок риск аварий и проблем с транспортом и техникой. ',
                        'Возможны неприятности с учебой, резкая смена учебного заведения (часто вынужденная). Серьезные разногласия или разрыв отношений с родственниками. Неблагоприятный период для оформления важных документов и заключения сделок.'
                    ]
                },
                4: {
                    title: 'Уран по 4 дому',
                    description: [
                        '(+) Возможна удачная перемена места жительства, начало строительства собственного дома (или крайне выгодная его покупка), грандиозный ремонт, модернизация жилья, радикальные перемены в семье. Удача в делах с недвижимостью при гармоничном Уране в радиксе. ',
                        '(-) Вынужденный ремонта дома или переезд, внезапные проблемы с электричеством, техникой, проводкой. Риск разрушения и потери имущества из-за внешних обстоятельств или стихийного бедствия. Отношения с родителями резко портятся, возможен полный разрыв связей. '
                    ]
                },
                5: {
                    title: 'Уран по 5 дому',
                    description: [
                        '(+) В личной жизни наступает период романов, запоминающихся влюбленностей, необычных историй и партнеров. У женщин вероятна внезапная беременность или появление ребёнка (усыновление, внебрачные дети), если на то есть дополнительные указания. Время озарений и освоения нового творческого направления. Появляются оригинальные хобби. Увеличивается шанс крупного выигрыша при имеющихся показателях в гороскопе рождения. ',
                        '(-) Возможна внезапная незапланированная беременность или сложные роды. Рвутся старые любовные и творческие связи, а новые отношения не складываются. Спекуляции и азартные игры могут довести до банкротства. Период бунта у детей, отношения с ними усложняются, выходят из-под контроля.'
                    ]
                },
                6: {
                    title: 'Уран по 6 дому',
                    description: [
                        '(+) В рутинных делах появляется больше свободы и «воздуха». Хороший показатель для модернизации ежедневного труда, внедрения технологий в операционную деятельность. У предпринимателей – отличный шанс обновить команду. В темах, связанных со здоровьем, рекомендуется обращать внимание на прогрессивные методы лечения. Возможно внезапное излечение от болезни. ',
                        '(-) Риск внезапного увольнения, потеря работы, случаи производственных травм или техногенных аварий. Трудности с поиском работы или с адаптацией в новом коллективе. У предпринимателей резкие неконтролируемые перемены в составе подчиненных, бунты, общественное недовольство и т.д. Серьезные расстройства здоровья из-за стресса, перенапряжения, бесконечной суеты или неаккуратного обращения с техникой.'
                    ]
                },
                7: {
                    title: 'Уран по 7 дому',
                    description: [
                        '(+) Перемены в сфере взаимоотношений, неожиданные удачные повороты. Внезапное заключение брака или начало серьезных отношений. Появление новых интересных партнерских связей в бизнесе, контакт с прогрессивными, устремленными в будущее людьми. Резкий рост популярности, стремительное развитие общественно значимых проектов. ',
                        '(-) Высок риск разрыва отношений по всем фронтам: развод, расторжение деловых соглашений, предательство партнера, судебные разбирательства. Общество настроено неблагоприятно, бесконечные нападки недовольных и эксцентричных людей, новые враги.'
                    ]
                },
                8: {
                    title: 'Уран по 8 дому',
                    description: [
                        '(+) Возможны внезапные финансовые вливания в нашу жизнь (через наследство, увеличение финансового потока партнера, отчисления с каких-либо проектов). Удача в кризисных и стрессовых ситуациях, когда говорят «родился в рубашке». Резко повышается интерес к темам восьмого дома: оккультным наукам, ясновидению, глубинному познанию себя, экстремальному виду спорта и т.п',
                        '(-) Опасность катастроф, аварий, несчастных случаев. Натив может пережить опыт внезапной потери (от имущества до близкого человека). Возможны крупные материальные убытки, трудности с получением наследства. Высокая вероятность поражений электричеством. Возможны столкновения с криминалом, налоговыми службами, силовыми ведомствами. Риск развития тяжелых заболеваний.'
                    ]
                },
                9: {
                    title: 'Уран по 9 дому',
                    description: [
                        '(+) Возможна смена места жительства, эмиграция, период жизни, насыщенный путешествиями. Происходят важные изменения в мышлении и мировоззрении, революционная смена духовных ценностей. Натив способен глубоко погрузиться в изучение какойлибо Уранической темы. ',
                        '(-) Негативные аспекты затрудняют путешествия, возникают неудачные стечения обстоятельств и всякого рода неприятности.',
                        'Опасность стихийного бедствия, травмы, катастрофы за рубежом. ',
                        'Возможны проблемы, связанные с дальними родственниками или иностранцами. Трудный период для обучения, желание бросить учебу или сменить направление. Незапланированный или вынужденный переезд. '
                    ]
                },
                10: {
                    title: 'Уран по 10 дому',
                    description: [
                        '(+) Взлет популярности или резкая смена социального статуса. Успех в высокотехнологичных отраслях, информационных технологиях, новаторских проектах, редких необычных профессиях. Смена места работы. Характерно стремление начать свое дело, работать на себя и ради себя. Может встречаться как показатель на новый личный социальный статус (женитьба/замужество, рождение детей или внуков). ',
                        '(-) Резкое увольнение, поиски себя. Неудачная и поспешная смена работы. Конфликты с начальством, властями, государственными органами. Возможны проблемы с родителями: внезапная болезнь или травма. Встречается также как указатель на развод (при связях с седьмым домом).'
                    ]
                },
                11: {
                    title: 'Уран по 11 дому',
                    description: [
                        '(+) Обновление круга общения. Появляются новые знакомства и возможности участия в необычных мероприятиях. Меняются цели, идеалы, политические и общественные предпочтения. Может исполниться давняя мечта. ',
                        '(-) Крушение планов и надежд, конфликты со спонсорами и единомышленниками, разочарование в друзьях. Бесконечная смена круга общения и убеждений. ',
                    ]
                },
                12: {
                    title: 'Уран по 12 дому',
                    description: [
                        '(+) Хороший период для духовного развития, проработки давних комплексов и страхов. Могут открыться неожиданные способности и таланты. Скрытые враги «всплывают» на поверхность совершенно безболезненным для натива способом.',
                        '(-) Много жизненных неприятностей, разочарований, измен, клеветы. Вовлечение в тайные дела. Вынужденная изоляция от общества. Проявление интенсивных психозов, нарушения внимания. Внезапное появление тайных врагов. '
                    ]
                },
            },
            ruler: {
                1: {
                    title: 'Уран по управителю 1 дома',
                    description: [
                        'Трансформации подвергаются личные проявления человека, внешний вид, манеры поведения, трансляция себя в обществе. ',
                        '(+) Человек может во всех проблемах видеть позитивную ноту, переводить всё в шутку. Новые идеи генерируются с легкостью, находятся инновационные решения. Человеку на таком транзите легко изучать новую технику, гаджеты, электронику. ',
                        'На таком транзите натив может увлечься прогнозированием в астрологии, нумерологии. ',
                        '(-) Натив может стать более суетливым, торопливым, будто куда-то торопящимся. При сильном дисгармоничном Уране (в натальной карте) поведение натива можно описать как «нервозность, истеричность». Так же возможны изменения во внешнем виде – человек может окрасить волосы в кислотный цвет или сделать другие внешне заметные не традиционные изменения.'
                    ]
                },
                2: {
                    title: 'Уран по управителю 2 дома',
                    description: [
                        'Возможны непредвиденные изменения финансовой стороны жизни.',
                        'При транзитном Уране по управителю 2 дома возможна смена деятельности на фриланс, удалённую работу. ',
                        '(+) На таком транзите финансового благополучия могут добавлять участие в форумах, конференциях, ведение блога. Чем нестандартней и оригинальней поведение натива, тем больше доход. ',
                        '(-) На таком транзите возможны неожиданные убытки, резкие перепады. Возможны траты на тусовки или на приятелей. Так же есть вероятность незапланированной смены источника дохода.'
                    ]
                },
                3: {
                    title: 'Уран по управителю 3 дома',
                    description: [
                        'Искажениям подвергаются темы коммуникаций, поездок, братьев/сестёр, транспорта, коммерции и документооборота. ',
                        '(+) На таком транзите человек может увлечься электросамокатами, роликами, велосипедами или приобрести машину с электрическим двигателем (или яркую, странную, необычную). ',
                        'Ближайший круг общения может измениться на более интеллектуальный. Так же натив может начать вести блог, который приобретёт популярность. ',
                        '(-) Высокий риск ДТП, возможно с участием электросамокатов или велосипедов; следует дать рекомендацию быть внимательнее за рулем. В поездках и командировках могут случаться странные непредсказуемые ситуации, нужно быть аккуратнее с документами.'
                    ]
                },
                4: {
                    title: 'Уран по управителю 4 дома',
                    description: [
                        'Изменениям подвергаются темы семьи, родственников, недвижимого имущества. ',
                        '(+) Натива может охватить желание наполнить свой дом всевозможными датчиками, «умными» гаджетами: умный чайник, сенсорный дозатор мыла, электронное открытие штор. Возможно, натив приобретет недвижимость на высоком этаже, с панорамными окнами. ',
                        '(-) Возможны частые переезды, перестановка дома. Дом натива может часто выступать местом сбора единомышленников, товарищей – «не дом, а проходной двор». ',
                        'Могут преследовать проблемы с бытовой техникой.'
                    ]
                },
                5: {
                    title: 'Уран по управителю 5 дома',
                    description: [
                        'При транзите Урана по управителю 5 дома трансформациям подвергаются темы детей, отдыха, азартных игр, любовников. Хобби и увлечения могут носить более современный, модернистский характер. ',
                        'Возможно натив пересмотрит свой взгляд на свободные отношения. ',
                        '(+) У натива с транзитным Ураном по управителю 5 дома увеличивается шанс на беременность с помощью ЭКО, либо на попечительство приемного ребенка. ',
                        'Велика вероятность получить в подарок или выиграть технику, гаджеты, приспособления, облегчающие жизнь. На таком транзите натива может увлечь интеллектуальное хобби или занятие, связанное со скоростью, высотой, воздухом. ',
                        '(-) Натив может влюбиться в очень странного человека. Свидания могут проходить при весьма необычных обстоятельствах. ',
                        'При сильном дисгармоничном Уране (в натальной карте) сложности в детском вопросе (от прерывания беременности до трудностей в общении с ребенком, которому хочется свободы). ',
                        'Возможны неприятные сюрпризы на отдыхе, на увеселительных мероприятиях, корпоративных вечеринках.'
                    ]
                },
                6: {
                    title: 'Уран по управителю 6 дома',
                    description: [
                        'Изменениям подвергаются темы наёмного труда, рутинной, ежедневной работы, здоровья, домашних животных и заботы о них. ',
                        'На таком транзите возможны более частые командировки по работе (в сравнении с обычным рабочим процессом). ',
                        '(+) На таком транзите велика вероятность появления необычного, говорящего животного, например, попугая Ара (можно дать такую рекомендацию для проработки негармоничного Урана). ',
                        'Возможна смена профессиональной деятельности: астрология, инженерные системы, IT-сфера, исследовательские работы, организация выставок, event-мероприятий. Так же возможен переход на удалённый режим работы. ',
                        '(-) Высок риск появления заболевания, связанного с ЦНС, лёгкими. Можно дать рекомендацию попробовать медитацию и йогу (технику с определенным дыханием). ',
                        'На таком транзите возможны неожиданные перемены на работе, вплоть до внезапного увольнения, сокращения. Так же возможна частая смена работы.'
                    ]
                },
                7: {
                    title: 'Уран по управителю 7 дома',
                    description: [
                        'Реформируются темы партнерства – от отношений с бизнеспартнером или супругом до контакта с аудиторией. Возможно натив пересмотрит свой взгляд на свободные отношения, гостевой брак. ',
                        '(+) При гармоничном Уране (в натальной карте) транзит сулит появление партнера-интеллектуала или партнера водолейского типажа (душа компании, увлекается велосипедами/гонками/парашютами, пилот, IT-специалист, с неординарной внешностью). При таком транзите совместные занятия лыжами, велоспортом, совместные посещения тусовок, митингов, марш-протестов – укрепляют отношения. ',
                        '(-) Кризисное время с партнером, недопонимание, ссоры с супругом, риск развода – при прочих указаниях в других методах прогноза! ',
                        'Возможно неожиданное появление врагов. Высок риск странных судебных разбирательств, которые в процессе превращаются его в уранистический сюр.'
                    ]
                },
                8: {
                    title: 'Уран по управителю 8 дома',
                    description: [
                        'Искажениям подвергаются темы «чужих», заёмных денег, бизнеса, наследства. Возможно попадание под влияние или преднамеренное занятие магией, эзотерическими ритуалами. ',
                        '(+) Человек может успешно начать заниматься астрологией, нумерологией, human design. На таком транзите возможно неожиданное получение наследства, инвестиций извне. ',
                        'В сложных ситуациях удаётся «чудом» избежать или минимизировать негативные последствия. ',
                        '(-) На таком транзите высок риск попадания в несчастные случаи от электричества, высоты, скорости, молнии. Опасность обмана при получении кредитных средств или наследства – возможны непредвиденные расходы, внезапные комиссии или неожиданно изменившиеся условия.'
                    ]
                },
                9: {
                    title: 'Уран по управителю 9 дома',
                    description: [
                        'Изменениям подвергаются темы высшего образования, путешествий, дальних поездок, заграницы вцелом. ',
                        'Такой транзит может себя никак не проявлять – одно из самых размытых положений. ',
                        '(+) На таком транзите может встретиться духовный наставник водолейского типажа (одаренный, неординарный) оказывающий влияние на мировоззрение. Возможно удастся попасть на престижное обучение в сфере IT или общественно-политическом направлении. ',
                        '(-) Высокий риск отчисления из высшего учебного заведения. Так же возможны проблемы за рубежом – большой риск депортации, потери документов.'
                    ]
                },
                10: {
                    title: 'Уран по управителю 10 дома',
                    description: [
                        'Искажениям подвергаются темы карьеры, социального положения. ',
                        'Трансформируется отношение к руководящим должностям, политике и моральные ценности натива приобретают новый смысл. ',
                        '(+) При таком транзитном положении планет натив может серьезно увлечься и начать строить карьеру в сфере астрологии, политики, прогнозирования, заняться организаторской деятельностью, исследованиями или разработками. ',
                        'Возможны резкие изменения на работе, которые повлекут за собой позитивные результаты; также возможны командировки. В женской карте может означать креативного, с хорошим чувством юмора мужа. ',
                        '(-) В женской карте может указывать на чрезмерно свободолюбивого мужа, тусовщика, игромана. ',
                        'Возможно на таком транзите испортятся отношения с начальником из-за несовпадения взглядов. Высокий риск внезапного увольнения или сокращения – при прочих подтверждающих указаниях в других методах прогноза! Такой транзит можно трактовать как частую смену работы/должностей.'
                    ]
                },
                11: {
                    title: 'Уран по управителю 11 дома',
                    description: [
                        'Перевороту подвергаются планы, идеи об идеальном мире, о будущем. Меняются отношения с единомышленниками, соратниками, с теми, с кем общая цель. ',
                        '(+) На таком транзите натив может испытывать непреодолимое желание бороться за высокие идеалы, искать всевозможную поддержку среди единомышленников. Участие в общественных организациях может влиять на рост популярности и нести свои благоприятные плоды. ',
                        '(-) Натив на таком транзите может ощущать оторванность от реальности, не всегда уместное бунтарство. Частая смена друзей, окружения. Планы могут всё время рушиться по непредвиденным причинам, особенно если их озвучивать.'
                    ]
                },
                12: {
                    title: 'Уран по управителю 12 дома',
                    description: [
                        'Влияние транзита связано с уходом от внешнего мира, уединением и изоляцией. Происходит мощнейшая трансформация психики, самоанализ. ',
                        '(+) У человека может появиться творческое вдохновение и натив может всерьез начать заниматься режиссурой, кино, видео-монтажом. Нетрадиционная медицина, медитация и йога (техники с определенным дыханием) помогают поддерживать здоровье. ',
                        '(-) На таком транзите натив может чувствовать себя белой вороной. Может стремиться к отшельничеству, затворничеству. Возможно развитие психоэмоциональных расстройств, в зоне риска ЦНС, легкие, системные расстройства организма.'
                    ]
                },
            }
        },
        saturn: {
            planet: {
                sun: {
                    title: '',
                    description: [
                        '(+) Аспект существенно проигрывается только в жизни взрослых и серьезных людей. Большой успех и достижения приходят через упорство и труд. Возможен небольшой, но важный профессиональный и материальный прогресс. Натив становится более собранным, упорным, ответственным и практичным. ',
                        '(-) Пессимистичный и сложный жизненный период. Время ограничений в самовыражении и проявлении, период, когда личность натива проявить очень тяжело. Апатия, неуверенность в себе, кризис в творчестве, транзит также может дать ситуации одиночества и изоляции.'
                    ]
                },
                moon: {
                    title: '',
                    description: [
                        '(+) Доминирование холодного расчета и разума над эмоциями. Спокойное отношение ко всем сферам жизни. Отсутствие излишнего интереса к любым проблемам. Стабилизация финансовых, имущественных, хозяйственных и финансовых вопросов. Хороший период для приведения своих дел в порядок. ',
                        '(-) Классический аспект депрессий. Высокая подверженность различным страхам и заболеваниям. Вероятность семейных конфликтов из-за профессиональных дел. Беспросветное уныние, апатия, потеря важной эмоциональной связи с кем-либо.'
                    ]
                },
                mercury: {
                    title: '',
                    description: [
                        '(+) Высокая фокусировка внимания и хорошее усвоение тем, требующих серьезного подхода, аналитики и систематизации знаний. ',
                        'Время осмысленной серьезной концентрации на рабочих процессах. Удачное время для сдачи экзаменов, для исследований, работы с важными официальными документами. Благоприятно долгосрочное планирование. ',
                        '(-) Вынужденная смена окружения, потеря связей, ограничения в общении. Информация усваивается туго, мозг как замороженный. Период сложностей в учебе. Транзит часто дает затягивание процессов связанных с оформлением документов, организацией поездок и выполнением договоренностей в целом.'
                    ]
                },
                venus: {
                    title: '',
                    description: [
                        '(+) Время творческих проявлений в практических и прикладных направлениях (особенно для архитекторов и дизайнеров). Серьезные этапы и решения в любви, переход к более взрослой модели отношений. Хороший период для поиска финансовой поддержки. ',
                        '(-) Период проверки отношений на прочность, вынужденные расставания или время испытаний для пары. Творческий кризис, пессимистическое отношение к жизни. Отсутствие рабочего настроения, эмоциональная депрессия, вынужденная экономия на развлечениях и удовольствиях.'
                    ]
                },
                mars: {
                    title: '',
                    description: [
                        '(+) Марс в Козероге – это положение в экзальтации. Отсюда вывод, что благоприятные энергии Сатурна Марсом принимаются очень хорошо. Для сильных людей аспект способствует продвижению, дает конкретные практические результаты, силу и способность дисциплинированно идти к цели. Подчеркивает выносливость и ответственность. Отличный аспект для старта или возобновления тренировок. ',
                        '(-) Риск травм и переломов, вынужденного ограничения физических действий. Объем работы и груз ответственности обременителен для натива. Высока вероятность затяжных конфликтов (с оттенком «холодной войны»). Реализация планов затягивается во времени.'
                    ]
                },
                jupiter: {
                    title: '',
                    description: [
                        '(+) Период стабилизации положения в социуме и прогресса в достижении долгосрочных целей. Появляются хорошие возможности для продвижения в карьере, образовании, общественной и финансовой деятельности. Помощь влиятельных лиц, руководства или госструктур. ',
                        '(-) Период неудач и ограничений при продвижении к цели. ',
                        'Вынужденное снижение деловой активности и обесценивание заслуг натива. Потеря авторитета, социальная дистанция между человеком и его окружением (чаще в профессиональной среде).'
                    ]
                },
                saturn: {
                    title: '',
                    description: [
                        'Аспекты Сатурна к самому себе начинают активно проявляться в возрасте после 29,5 лет. С течением жизни интенсивность данного транзита будет возрастать. ',
                        '(+) Активирует выдержку и устойчивость к любым трудностям. ',
                        'Возможно вовлечение в политическую, научную, общественную или административную деятельность, способствует достижению успеха через реальный труд и дисциплину. ',
                        '(-) Транзит создает проблемы, связанные с ограничением чего-либо, уменьшением количества и потерями. Натив вынужден терпеть, ужиматься и довольствоваться малым.'
                    ]
                },
                uranus: {
                    title: '',
                    description: [
                        'Сатурн по Урану, Нептуну, Плутону проявится в темах тех домов, где находятся эти планеты или которыми они управляют. Сила проявления этих обстоятельств зависит от аспектов и личных показателей в натальной карте. '
                    ]
                },
                neptun: {
                    title: '',
                    description: [
                        'Сатурн по Урану, Нептуну, Плутону проявится в темах тех домов, где находятся эти планеты или которыми они управляют. Сила проявления этих обстоятельств зависит от аспектов и личных показателей в натальной карте. '
                    ]
                },
                pluto: {
                    title: '',
                    description: [
                        'Сатурн по Урану, Нептуну, Плутону проявится в темах тех домов, где находятся эти планеты или которыми они управляют. Сила проявления этих обстоятельств зависит от аспектов и личных показателей в натальной карте. '
                    ]
                },
                nodes: {
                    title: '',
                    description: [
                        '(+) В соединении с Северным узлом вынуждает брать ответственность за собственную жизнь в новых сферах или на более серьезном уровне. Важно приобретать опыт принятия самостоятельных взрослых решений. ',
                        '(-) В соединении с Южным узлом задерживает развитие привычных дел и возвращает фокус внимания на ранее взятые обязательства.'
                    ]
                },
                lilit: {
                    title: '',
                    description: [
                        'Хладнокровие и скупость по отношению ко всем сферам. Проявление жестокости и избыточной требовательности к окружающим людям (особенно по дому, где находится Лилит). Демонстрация негативных черт своей личности через давление, упрямство и бескомпромиссность.'
                    ]
                },
            },
            house: {
                1: {
                    title: 'Сатурн по 1 дому',
                    description: [
                        '(+) Повышается самодисциплина, уверенность в себе и собранность. Натив способен брать полную ответственность за свои поступки. Формируется здоровое эго и ощущение самодостаточности. Происходит наведение порядка во всех сферах жизни. Транзит часто сопровождается стремление к здоровому образу жизни, тренировкам и полезным привычкам (режимность и аскезы – как естественный элемент существования). ',
                        '(-) Обострение хронических заболеваний, затяжные депрессии, апатия и тотальный упадок сил. Транзит личных потерь и ограничений натива. Энергетически человек ощущает, как будто резко постарел. Ощущение одиночества, невозможности что-то изменить. '
                    ]
                },
                2: {
                    title: 'Сатурн по 2 дому',
                    description: [
                        '(+) Ожидаемые (не внезапные!) денежные успехи у серьезных и ответственных людей, а также у представителей сатурнианских профессий. Проявление настойчивости и последовательности в делах бизнеса. Узаконивание источников дохода, стабилизация финансовых потоков. ',
                        '(-) Режим тотальной экономии и контроля денег. Потеря привычных источников дохода, урезание зарплаты и положенных премий. Снижение общего объема денежной массы. Убытки, потери из-за устаревания инструментов труда.'
                    ]
                },
                3: {
                    title: 'Сатурн по 3 дому',
                    description: [
                        '(+) Укрепление взаимоотношений с ближайшим кругом. Развитие деловых контактов, знакомство с более взрослыми личностями. Заключение выгодных долгосрочных сделок. Хорошее время для работы с официальными бумагами. ',
                        '(-) Поломки авто из-за устаревания и изнашивания каких-либо деталей. Трудности в поездках, вынужденная отмена планов. Отстраненные отношения с братьями и сестрами, близкими друзьями, сокурсниками. Сатурн в этом доме часто ограничивает общение с кем-либо. Сложности в обучении, снижение успеваемости. Задержки в делах, связанных с оформлением документов.'
                    ]
                },
                4: {
                    title: 'Сатурн по 4 дому',
                    description: [
                        '(+) Ответственные шаги в семейной жизни, ремонт или покупка жилья. Помощь родственникам, ощущение надежности при контакте с ними. При гармоничном Сатурне прекрасное время для строительства. ',
                        '(-) Холодные отношения с родственниками, тяжелые семейные обязанности, эмоциональная депрессия и зависимость от родных. Возможно вынужденное расставание с родителями или обременительная забота о члене семьи. При имеющихся указаниях в натале, транзит может подтвердить прогноз потери члена семьи. Энергия старости в доме, обветшание жилья, необходимость вынужденно что-то ремонтировать.'
                    ]
                },
                5: {
                    title: 'Сатурн по 5 дому',
                    description: [
                        '(+) Систематизация творческих идей, увлечение сатурнианским спортом или серьезным хобби и даже стремление преобразовать это в профессию. Выбор одной деятельности среди различных увлечений. Укрепление любовных отношений, влюбленность в более зрелого человека. Серьезное отношение к теме воспитания детей. Если есть взрослые дети, в этот период они могут стать опорой и поддержкой. ',
                        '(-) Охлаждение романтических отношений, пессимистический настрой, возможно, расставание. Осложненная беременность, сложности с зачатием (если Сатурн особенно негативный, то может давать замирание беременности). Суровость в отношении детей, затяжные молчаливые конфликты. '
                    ]
                },
                6: {
                    title: 'Сатурн по 6 дому',
                    description: [
                        '(+) Интерес к диетам, здоровью и очищению организма. Успешно проходит борьба с хроническими болезнями. Стабильность в работе, помощь от коллектива, благоприятная оптимизация рабочей деятельности. Систематизация и стабилизация рабочих процессов. ',
                        '(-) Удар по здоровью, обострение всех хронических болезней, переутомление. Это время тяжелых обязанностей и выматывающей работы, которая потребует всей нашей дисциплины. Отношения с коллегами и подчиненными осложняются. Могут возникнуть проблемы в жизни домашних питомцев, вынужденное расставание с ними.'
                    ]
                },
                7: {
                    title: 'Сатурн по 7 дому',
                    description: [
                        '(+) Новые этапы и стабилизация в отношениях, узаконивание отношений. Прекрасное время для связей с общественностью у сатурнианцев и представителей сатурнианских профессий, заключения долгосрочного сотрудничества, подписания договоров. Все несерьезные связи и отношения уходят из жизни. ',
                        '(-) Завершение тяжелых отношений, расставание или отчужденность в паре. Конфликты и полный разрыв сотрудничества с партнерами, проблемы юридического характера, проигрыш в судебных делах и торможение правового процесса.'
                    ]
                },
                8: {
                    title: 'Сатурн по 8 дому',
                    description: [
                        '(+) Появление денежной помощи или финансирования, получение наследства, удачное вложение накоплений в долгосрочные проекты или преумножение собственности. ',
                        '(-) Крупные денежные потери, кражи, давящие кредиты. Возможно, пострадает финансовое положение партнера. Падение фертильности и снижение половой активности. Продолжительные болезни натива или его близких людей. Транзит часто вовлекает в чужие неприятности и страдания.'
                    ]
                },
                9: {
                    title: 'Сатурн по 9 дому',
                    description: [
                        '(+) Идеализированные ожидания от мира сменяются более реалистичными и прагматичными, натив проводит собственные исследования в темах мировоззрения. Обретение духовного роста в религии и философии, создание своих принципов в отношении честности и нравственности. Благоприятные деловые поездки за рубеж. Получение официального статуса, связанного с заграницей (вид на жительства, гражданство и т.д.) или образованием. ',
                        '(-) Поездки за границу совершаются с огромными препятствиями и трудностями, либо срываются вовсе. Сложный период обучения. Духовный кризис и разочарования в своей идеологии. Потери и ограничения вдали от дома.'
                    ]
                },
                10: {
                    title: 'Сатурн по 10 дому',
                    description: [
                        '(+) Высокая вероятность продвижения по карьерной лестнице. Транзит отлично сработает при реализации качеств Сатурна. Благоприятное отношение вышестоящих персон или косвенная (часто абсолютно без эмоциональная) поддержка от них. Получение какого-либо официального статуса, в том числе не связанного с работой. ',
                        '(-) Обесценивание результатов труда на работе, увольнение или понижение в должности. Трудности с начальством и властью, утомляемость и нежелание работать. Если натив только начинает свое дело, то ощущает пессимизм и сомнение по поводу успешности проекта.'
                    ]
                },
                11: {
                    title: 'Сатурн по 11 дому',
                    description: [
                        '(+) Завоевание авторитета в группе через служение потребностям коллектива, участие в благотворительности. Обретение надежных друзей и связей. ',
                        '(-) Период разочарований в собственных целях и мечтах. Часто натив лишается многих друзей, даже тех, с кем его связывала многолетняя дружба. Групповые работы могут протекать нервно, результаты присваиваются незаслуженно другим людям. '
                    ]
                },
                12: {
                    title: 'Сатурн по 12 дому',
                    description: [
                        '(+) Добровольное отстранение от людей и уединение, тайная деятельность, которая играет на руку нативу. Серьезная увлеченность медитациями, мистицизмом, парапсихологией. Здоровая потребность в духовном уединении, психологической терапии.',
                        '(-) Неприятности разной природы: болезни, вынужденная изоляция, преследования, потеря репутации или попадание под следствие. Психологически аспект переносится очень тяжело. Чувство гнетущего одиночества, крайне мрачные настроения.'
                    ]
                },
            },
            ruler: {
                1: {
                    title: 'Сатурн по управителю 1 дома',
                    description: [
                        '(+) Человек может быть более организован, собран. Может начать следовать распорядку дня или придерживаться диеты, соблюдать пост. Может начаться серьезное увлечение ЗОЖ. Человек на таком транзите становится более вынослив, трудолюбив и усидчив. ',
                        '(-) При действии такого транзита натив может вести себя более холодно, отстранённо. Могут участиться эпизоды подавленного состояния без видимых на то причин.'
                    ]
                },
                2: {
                    title: 'Сатурн по управителю 2 дома',
                    description: [
                        '(+) Натив может пожинать плоды прежней усердной работы и получать ожидаемое вознаграждение. Полученные ранее дипломы и сертификаты дают возможность зарабатывать выше рынка. Участие в корпорациях дает возможность получить дополнительные финансовые бонусы. Так же человек может начать откладывать деньги, копить на что-то. ',
                        '(-) У человека при действии сильного Сатурна может быть расписан и распланирован каждый рубль. Ограничения в средствах, урезание заработной платы, снижение ставки или лишение премий, бонусов. Средства достаются с большим трудом. Для проработки дисгармоничного Сатурна можно дать рекомендацию вести домашнюю бухгалтерию и/или коллекционировать антиквариат/драгоценные камни/часы.'
                    ]
                },
                3: {
                    title: 'Сатурн по управителю 3 дома',
                    description: [
                        '(+) При действии транзитного Сатурна человек может заметить, что ему легче концентрироваться на сложных, больших объемах информации. Общение человека может стать более официальным или в окружении появятся статусные персоны. ',
                        '(-) Такой транзит несёт возможные сложности в выстраивании новых контактов, ограничивающие ситуации в старте продаж услуг/товаров или покупки авто. Так же возможно сужение круга контактов до самых проверенных. ',
                        'При транзитном действии Сатурна могут охладиться отношения с братом/сестрой, лучшим другом. Могут случаться ситуации прессинга, давления в школе.'
                    ]
                },
                4: {
                    title: 'Сатурн по управителю 4 дома',
                    description: [
                        '(+) На действии такого транзита натив может захотеть избавить свой дом от лишних вещей, поменять дизайн интерьера на минимализм или ретро-стиль, либо переехать в элитное жилье/на высоком этаже/с видом на горы. Так же может захотеть приобрести земельный участок, дачу. ',
                        '(-) При покупке жилья (в новостройке) на таком транзите велик риск приобретения квартиры-долгостроя. Ремонтные работы, начатые в такой период, могут затянуться надолго. ',
                        'Человек так же может сменить место жительства на более скромное, тесное, общежитие или жилье от предприятия. ',
                        'Отношения с родственниками могут охладеть – натив может испытывать желание не раскрывать положение своих дел.'
                    ]
                },
                5: {
                    title: 'Сатурн по управителю 5 дома',
                    description: [
                        '(+) В жизни натива могут развиваться романтические отношения с человеком более зрелого возраста, мудрым и ответственным. На таком транзите простое хобби может перерасти в серьезное увлечение, дело жизни. ',
                        '(-) Внешние обстоятельства могут сдерживать увлечение любимым занятием. Такой транзит может минимизировать число поклонников и количество свиданий, либо в процессе романтических отношений возникают препятствия разного рода.'
                    ]
                },
                6: {
                    title: 'Сатурн по управителю 6 дома',
                    description: [
                        '(+) На таком транзите натив может более ответственно, системно подходить к вопросам здоровья, пройти плановое обследование. На работе может быть отмечено начальством устойчивое положение натива, профессионализм. ',
                        '(-) Дисгармоничный Сатурн (в натальной карте) может принести увеличение обязанностей на работе, давление со стороны начальства.',
                        'С коллегами отношения могут стать более сдержанными, сухими, деловыми. Следует с осторожностью относиться к позвоночнику, зубам, суставам.'
                    ]
                },
                7: {
                    title: 'Сатурн по управителю 7 дома',
                    description: [
                        '(+) На таком транзите велика вероятность заключения официального брака. Так же может увеличиться вероятность публичной деятельности, появления статусных клиентов. ',
                        '(-) В минусе такой транзит ведет к охлаждению отношений в паре: возможен излишний контроль, ограничения, давление, ревность. Так же увеличена вероятность затяжных судебных разбирательств, стычек с врагами.'
                    ]
                },
                8: {
                    title: 'Сатурн по управителю 8 дома',
                    description: [
                        '(+) При гармоничном Сатурне (в натальной карте) велика вероятность получить наследство. Так же при действии такого транзита человек может чувствовать и вести себя хладнокровней чем обычно в кризисных ситуациях, может помочь другим людям не только словом, но и делом. ',
                        '(-) При действии транзита по управителю 8 дома у человека может чрезмерно обостриться чувство долга и вины, завышенные требования к себе и окружающим. Может появиться неприятное наследство: выплата чужих долгов или кредитов.'
                    ]
                },
                9: {
                    title: 'Сатурн по управителю 9 дома',
                    description: [
                        '(+) Человек особенно сильно придерживается своих религиозных и идеологических убеждений, верен своим принципам. Так же натив может встретить мудрого наставника, компетентного мастера своего дела. ',
                        '(-) Престижное образование стоит под угрозой – сложности в обучении, либо внешние обстоятельства мешают учебе. При получении иностранного гражданства или иных документов могут возникнуть трудности.'
                    ]
                },
                10: {
                    title: 'Сатурн по управителю 10 дома',
                    description: [
                        '(+) При таком транзите человек может ощутить отдачу от ранее полученных дипломов, сертификатов. Может расти роль натива в социуме, уверенный карьерный рост. Возможно сменится руководитель натива на более профессионального. ',
                        '(-) Натив может ощущать давление со стороны начальника, руководителя. Карьера может стоят под угрозой из-за собственной несобранности, лени и иррациональности.',
                    ]
                },
                11: {
                    title: 'Сатурн по управителю 11 дома',
                    description: [
                        '(+) При действии транзита натив строит конкретные планы, четкие цели и задачи. Окружение составляют исключительно надежные, статусные соратники. Натив может организовать сам сообщество с единой целью с основательным подходом к будущему. ',
                        '(-) Окружение натива может состоят из сомнительных друзей, на которых нельзя положиться. Планы могут срываться из-за всевозможных причин, появляются ограничивающие внешние обстоятельства. Натив может ощущать себя белой вороной в коллективе.'
                    ]
                },
                12: {
                    title: 'Сатурн по управителю 12 дома',
                    description: [
                        '(+) Натив может уделять больше времени, чем обычно, одиноким пожилым людям, заняться благотворительностью в их адрес. Может пробовать различные методы самоограничения, аскетизм, отшельничество. Натива может охватить профессиональный подход к эзотерике и духовности. ',
                        '(-) Человек может испытывать тягу к затворничеству, отречению от всех благ. Может испытывать глубочайшее чувство долга и вины перед Создателем.'
                    ]
                },
            }
        },
        jupiter: {
            planet: {
                sun: {
                    title: 'Юпитер по Солнцу',
                    description: [
                        '(+) Время оптимизма и уверенности в себе. Чувство собственного веса вселяет уверенность. Нативу стоит ярко заявлять о себе, проявляться в творческих проектах. Прекрасное время для самообразования, повышения квалификации, расширения своего авторитета и влияния. Благоприятный период для ответственных выступлений, публичных мероприятий и развития личного бренда. ',
                        '(-) Излишний оптимизм и оторванность от реальной жизни, конфликты с авторитетными людьми из-за раздутого Эго. Хвастовство, необоснованное принижение заслуг других людей, превышение собственных полномочий, желание научить всех жизни.'
                    ]
                },
                moon: {
                    title: 'Юпитер по Луне',
                    description: [
                        '(+) Внутренняя гармония, ощущение спокойствия и защищенности. Повышенная эмоциональность по отношению к красоте окружающего мира, желание помогать другим, опекать и участвовать в благотворительных организациях. Натив становится более добрым и щедрым, от него веет благородством души. Транзит несет успех в бизнесе с продовольственными товарами, в сфере услуг и вообще в темах Луны (в моей практике есть случай точного соединения транзитного Юпитера с Луной на момент рождения ребенка). ',
                        '(-) Чрезмерная эмоциональная активность, стремление получить все и сразу без оглядки на реалии, излишний оптимизм и готовность многое принять на веру. Эмоциональная потребность чего-то большего, ранее недоступного. Пренебрежение своими обязанностями, злоупотребление едой и банальная лень.'
                    ]
                },
                mercury: {
                    title: 'Юпитер по Меркурию',
                    description: [
                        '(+) Рост количества контактов и появление полезных и влиятельных людей в близком окружении, значительное увеличение работы с информацией. Интеллектуальный оптимизм и интерес к образованию. Множество поездок и перемещений, связи и заключение договоров с иностранными партнерами. Хорошее время для издательской деятельности, повышения квалификации, связей со СМИ, учебы. Транзит удачен для подписания контрактов и юридических документов. ',
                        '(-) Избыток информации создает суету и неразбериху. Сложно вычленить главное и отделить истину от никчемной болтовни или пустых обещаний. Время сложных дискуссий, когда посягают на наши ценности и мировоззрение.'
                    ]
                },
                venus: {
                    title: 'Юпитер по Венере',
                    description: [
                        '(+) Романтические увлечения, новые влюбленности и кратковременные романы (или увеличение их количества). Период развития в искусстве, предпринимательстве и общественной деятельности. Финансовый успех, получение ощутимой прибыли, выгодные покупки. Крайне благоприятный аспект для творческих людей и представителей венерианских профессий. ',
                        '(-) Экстравагантность в тратах и расточительность, излишняя влюбчивость и множество мимолетных романов, которые ни к чему не ведут. Леность, злоупотребление удовольствиям, полное отсутствие серьезного настроя на что-либо. Возможны убытки из-за завышенных ожиданий.'
                    ]
                },
                mars: {
                    title: 'Юпитер по Марсу',
                    description: [
                        '(+) Энергии становится очень много, натив способен направить ее в правильное русло. Благодаря личным усилиям и активным действиям возможен прогресс в бизнесе и профессиональной сфере, карьерное продвижение, усиление позиций в обществе. Хорошее время для освоения новых видов спорта, для соревнований (благоприятный аспект Юпитер Марс – традиционное указание на победы и награды). Благоприятно решение правовых и юридических вопросов, получение консультаций, обращение в суд. ',
                        '(-) Повышенная конфликтность, ощущение своей правоты во всем. Фанатичное навязывание своей точки зрения и интересов, неэтичное, местами даже аморальное поведение. Склонность к неразумным и ошибочным действиям могут привести к ощутимым неприятностям, потере прибыли, убыткам. Юридические трудности, судебные разбирательства. Борьба за справедливость оказывается крайне выматывающей.'
                    ]
                },
                jupiter: {
                    title: 'Юпитер по Юпитеру',
                    description: [
                        '(+) Появление возможностей реализоваться в социуме, новые перспективы и предложения, которые нужно использовать. За это время можно заложить фундамент дальнейшего процветания, продвинуться в карьере и профессиональной деятельности. Помощь руководства, влиятельных лиц и спонсоров. Благоприятное разрешение правовых вопросов. Ощущение успеха, собственной значимости и авторитета. ',
                        '(-) Пересмотр своих ценностей, пессимистичное настроение и внутренний идеологический кризис. Характерны конфликты из-за разницы мировоззрений и интересов, а также образовательного уровня - с руководством, влиятельными лицами, партнерами. Навязчивое желание самоутвердиться за чужой счет. Неудачное время для обращения в суд или официальные органы.'
                    ]
                },
                saturn: {
                    title: 'Юпитер по Сатурну',
                    description: [
                        '(+) Возможность снятия ограничений, проработки блоков, расширения во всех сатурнианских делах. Благоприятный период для организаторской работы, труд приносит признание. Ответственность в такие моменты меньше давит на плечи, появляется рабочий оптимизм. ',
                        '(-) Растут жадность, неадекватная оценка или переоценка материи и своих планов. Увеличиваются нагрузки, ухудшаются отношения с людьми. Юпитер раздувает все негативные проявления Сатурна, человек больше, чем когда-либо ощущает одиночество и пессимизм.'
                    ]
                },
                uranus: {
                    title: '',
                    description: [
                        'Юпитер к Урану, Нептуну, Плутону проявляется в тех домах, где находятся эти планеты или которыми они управляют. Сила проявления этих обстоятельств зависит от аспектации и личных показателей в натальной карте'
                    ]
                },
                neptun: {
                    title: '',
                    description: [
                        'Юпитер к Урану, Нептуну, Плутону проявляется в тех домах, где находятся эти планеты или которыми они управляют. Сила проявления этих обстоятельств зависит от аспектации и личных показателей в натальной карте'
                    ]
                },
                pluto: {
                    title: '',
                    description: [
                        'Юпитер к Урану, Нептуну, Плутону проявляется в тех домах, где находятся эти планеты или которыми они управляют. Сила проявления этих обстоятельств зависит от аспектации и личных показателей в натальной карте'
                    ]
                },
                nodes: {
                    title: 'Юпитер по Северному/Южному узлам',
                    description: [
                        '(+) При соединении с Северным узлом открывается новый опыт в сферах социальной деятельности в качестве лидера, преподавателя или ответственного лица. Возможно обретение своего наставника или Учителя. Формирование важных нравственных ориентиров и ценностей. ',
                        '(-) При соединении с Южным узлом может происходить возврат долгов за ранее совершенные поступки и действия (в каком виде зависит от прежнего жизненного опыта и решений). Натив также может быть втянут в неприятную для него необходимость решать чужие проблемы за счет своего положения в социуме.'
                    ]
                },
                lilit: {
                    title: 'Юпитер по Лилит',
                    description: [
                        'Наиболее ярко проявляется у известных и влиятельных людей. Чрезмерное чувство собственной значимости, самомнение и преувеличенное честолюбие. Потеря нравственных ориентиров. Возможно психологическое влияние со стороны аморального сообщества.'
                    ]
                },
            },
            house: {
                1: {
                    title: 'Юпитер по 1 дому',
                    description: [
                        '(+) Человек становится «больше» во всём, появляется склонность к великодушным и благородным поступкам. Укрепляется здоровье, физическая активность, иммунитет и жизненные силы. Огромная уверенность в себе и оптимистичный подход к жизненным обстоятельствам. Это хорошее время, чтобы работать над своим духовным совершенствованием и перейти к более конструктивному образу жизни. Часто возникает сильное желание путешествовать, развиваться, начинать новые жизненные проекты. ',
                        '(-) Преувеличенное чувство собственной значимости и раздутое самомнение. Стремление поучать и помогать там, где этого не просили. Участие в идеологических и религиозных конфликтах (оппозиция к VII дому). Наблюдается увеличение веса и объёмов тела, а также появление проблем с печенью и давлением.'
                    ]
                },
                2: {
                    title: 'Юпитер по 2 дому',
                    description: [
                        '(+) Бонусы и премии на работе, дополнительные материальные возможности, новые варианты заработка, увеличение дохода. Особенно благоприятное время для представителей юпитерианских профессий. Хорошее время для покупки недвижимости и вообще крупных капиталовложений. Следует использовать любые возможности, которые появляются в этот период. ',
                        '(-) Неразумное управление своими ресурсами. Теряется чувство меры во всём, что ведет к чрезмерным и необдуманным тратам, к переплате за бренды и престиж. Самое распространенное – неоправданные финансовые ожидания и последующее расстройство из-за недополученной прибыли.'
                    ]
                },
                3: {
                    title: 'Юпитер по 3 дому',
                    description: [
                        '(+) Значительное увеличение контактов и связей, поездок, переговоров. Хорошее время для начала обучения чем-то новому и расширяющему мировоззрение. Прекрасные возможности для повышения квалификации в какой-либо области. Транзит может означать прирост чего-либо в жизни брата или сестры. ',
                        '(-) Много мелких дел, ненужных контактов, хлопоты с оформлением документов. Возможны столкновения интересов и ссоры с родственниками или людьми из ближайшего окружения. Любое «расширение» кого-либо из ближайшего круга приносит нам неприятности. '
                    ]
                },
                4: {
                    title: 'Юпитер по 4 дому',
                    description: [
                        '(+) Расширение по темам дома. Хорошее время для улучшения жилищных условий, хорошего ремонта, покупки или продажи недвижимости или предметов для дома. Также может произойти смена места жительства или смена страны проживания. Позитивные изменения в семье, интерес к истории своего рода или страны. Период замечательно подходит, чтобы наладить отношения с родителями. ',
                        '(-) Неблагоприятное время для преобразования жилья, покупки или продажи собственности. Конфликты в семье или с близкими, где одна из сторон пытает давить авторитетом. Ссоры с родителями, нежданное появление гостей в доме. Вынужденный уход из дома или переезд.'
                    ]
                },
                5: {
                    title: 'Юпитер по 5 дому',
                    description: [
                        '(+) Время активного участия в светских и торжественных мероприятиях, повышенный интерес к конкурсным и игровым увлечениям. Возможно получение призов, неожиданных сюрпризов, выигрыша в лотерею. Обретение нового хобби. Появляются новые возможности проявить свои творческие способности. Увлеченность романами и свиданиями, большое количество ухажеров. Удачный период для зачатия ребенка, часто может показывать и рождение в том числе. Уже имеющиеся дети становятся источником гордости натива. ',
                        '(-) Проявление зависимости и одержимости в азартных увлечениях. Избыточное количество романтичных связей и романов, иногда незапланированная беременность. Период конфликтов с детьми и напряженные отношения с ними (перетягивание одеяла чье мнение более значимо).'
                    ]
                },
                6: {
                    title: 'Юпитер по 6 дому',
                    description: [
                        '(+) Увеличение штата персонала для предпринимателей и расширение собственного рабочего функционала. Для наемных работников – возможность улучшить условия труда, получить льготы, признание и даже повышение. Хорошее время для профилактики заболеваний, отдыха в санаторно-курортных условиях. Транзит может дать появление домашнего животного.',
                        '(-) Риск появления доброкачественных новообразований, проблемы со здоровьем, ожирение. Операционная деятельность раздувается выполнением никому не нужных задач, в рабочем процессе много лозунгов и мало пользы.'
                    ]
                },
                7: {
                    title: 'Юпитер по 7 дому',
                    description: [
                        '(+) Блестящие возможности в партнёрских и деловых отношениях. Натив может стать более известным, популярным, авторитетным. Благоприятное время для рекламы своей деятельности. Возможно заключение брака или знакомство с крайне значимым персонажем в жизни натива. Улучшения в жизни партнера (имеются в виду все варианты: и личного, и делового партнерства). Хорошее время для решения юридических вопросов. ',
                        '(-) Появление властных и сильных конкурентов. Партнеры могут больше говорить, чем реально делать или просто излишне оптимистично смотреть на ситуацию. Идеологические трудности в новых отношениях. Возможны юридические проблемы, судебные разбирательства.'
                    ]
                },
                8: {
                    title: 'Юпитер по 8 дому',
                    description: [
                        '(+) Хорошее время для начала отношений, в которых натив объединяет свои ресурсы с другим человеком. Рост благосостояния партнера по браку. Участие в инвестиционных делах могут принести достойную прибыль. Получение наследства и прибыли от других людей. Успех в оккультных занятиях, эзотерике, магии. ',
                        '(-) Неудачный период для инвестиций, займов и крупных покупок. Возможны очень тяжёлые потери и ущерб — как для имущества, так и для здоровья. Ситуации, в которых приходится разбираться с неприятностями партнеров, брать на себя их долги. '
                    ]
                },
                9: {
                    title: 'Юпитер по 9 дому',
                    description: [
                        '(+) Прекрасное время для развития культурного и духовного сознания. Множество возможностей для учебы и путешествий, а также для обучения других людей. Успех в дальних поездках, благоприятные связи с представителями других стран. Поступление в ВУЗ, расширение кругозора, усвоение новых философских и религиозных концепций. Возможна удачная эмиграция. ',
                        '(-) Перемена места жительства принесет разочарования (снова речь об изначальных завышенных ожиданиях). Конфликты с влиятельными людьми на почве разных представлений о мире и морали.'
                    ]
                },
                10: {
                    title: 'Юпитер по 10 дому',
                    description: [
                        '(+) Возможность профессионально развиваться или получить общественное признание. Достижение вершины в карьере, блестящая репутация на работе, новая должность и функционал. Достижение жизненных целей, видное общественное положение. Транзит указывает на получение различных наград. ',
                        '(-) Злоупотребление властью и престижем из эгоистических соображений. Придирчивое отношение начальства к выполнению рабочих обязанностей. Риск скандалов и публичного позора. Слава приходит, но обычно одновременно с общественным осуждением.'
                    ]
                },
                11: {
                    title: 'Юпитер по 11 дому',
                    description: [
                        '(+) Интерес к культурным, интеллектуальным и научным идеям, участие в благотворительных делах. Активная социальная и культурная жизнь, расширение круга единомышленников, помощь от влиятельного друга или просто его активное участие в жизни натива. Обретение авторитета в какой-либо группе. ',
                        '(-) Друзья могут стать причиной значительного ущерба для социального статуса. Вынужденный отказ от личных идеалов ради общественности, неприятности и разочарования в коллективной деятельности. Конфликты из-за принадлежности к определенной группе'
                    ]
                },
                12: {
                    title: 'Юпитер по 12 дому',
                    description: [
                        '(+) Глубокое понимание искусства, религии, философии. Проявление подсознательных мотиваций и сострадания к тем, кто нуждается в помощи. Повышение интереса к психологии, парапсихологии, оккультным и другим закрытым сферам деятельности. Возможность заработать авторитет в этих областях, получать от них доход. ',
                        '(-) Невозможность уединения и сохранения конфиденциальности. Все проблемы натива оказываются на виду, что приносит моральный дискомфорт. Увеличение врагов и недоброжелателей. Навязчивое желание проявить деструктивные черты своего характера. '
                    ]
                },
            },
            ruler: {
                1: {
                    title: 'Юпитер по управителю 1 дома',
                    description: [
                        '(+) Натив может ощущать себя неким наставником, мудрецом, к которому обращаются за советом. Натив может быть настроен более оптимистично, появляется желание изучать философию, иностранные языки, путешествовать, развиваться. ',
                        '(-) Натив может часто раздавать непрошенные советы. Может вести себя несколько высокомерно или пафосно. Юпитер может повлиять на внешний вид – возможно появление лишней массы тела.'
                    ]
                },
                2: {
                    title: 'Юпитер по управителю 2 дома',
                    description: [
                        '(+) На таком транзите прибыль принесёт элитное, возможно заграничное образование, работа в иностранных компаниях. На работе возможны различные премии и бонусы, повышение заработной платы. ',
                        '(-) Транзит Юпитера по управителю 2 дома может дать излишние иррациональные траты (например, на брендовые вещи оформляется кредит). Расходы выше доходов. Так же человек может тратить деньги на религиозную атрибутику.'
                    ]
                },
                3: {
                    title: 'Юпитер по управителю 3 дома',
                    description: [
                        '(+) Возможна дружба, сближение с людьми из элитного общества. Транзит ведет к увеличению числа поездок, встреч, обучений, нового материала. Натив может испытывать желание начать какое-либо обучение, повышение квалификации. ',
                        '(-) В поездках натива могут преследовать юридические проблемы, проблемы с юридическими документами. На таком транзите возможны излишние траты на автомобиль, гаджеты.'
                    ]
                },
                4: {
                    title: 'Юпитер по управителю 4 дома',
                    description: [
                        '(+) На таком транзите натив может приобрести элитную недвижимость, в том числе за границей. Указание на расширение семьи – появление новых членов семьи. Указание на переезд, на эмиграцию – при прочих указаниях в натальной карте и других методах прогноза! ',
                        '(-) Возможны юридические проблемы при манипуляциях с недвижимостью. Возможны конфликты с родственниками из-за расхождений религиозных или философских убеждений. Так же возможны частые неприятные гости в доме.'
                    ]
                },
                5: {
                    title: 'Юпитер по управителю 5 дома',
                    description: [
                        '(+) На таком транзите велика вероятность получения дорогих, элитных подарков. В этот период натива увлечет любовная тема, свидания могут запоминаться своей грандиозностью и великолепием. На таком транзите натив может часто посещать всевозможные светские мероприятия, торжества. ',
                        '(-) Такой транзит несёт большое количество любовных приключений, без дальнейшего развития отношений. На свиданиях натив может вести себя высокомерно, надменно. Так же, возможно, натива увлекут азартные игры.'
                    ]
                },
                6: {
                    title: 'Юпитер по управителю 6 дома',
                    description: [
                        '(+) Транзит может дать расширение штата для руководящих должностей; повышение в должности наемного работника. Натив может сменить работу, устроившись в иностранную организацию. Отличный период для оздоровительных процедур: чистка организма или наращивание мышечной массы. ',
                        '(-) Возможно расширение обязанностей на работе. Уверенность в своей правоте и желание доказать коллегам свою точку зрения может мешать на службе. Следует дать рекомендацию обратить внимание на свое здоровье, пройти развернутое обследование на предмет новообразований!'
                    ]
                },
                7: {
                    title: 'Юпитер по управителю 7 дома',
                    description: [
                        '(+) В жизни натива может появиться статусный партнер/ ответственный компаньон, напарник; эти взаимоотношения приведут к хорошим результатам совместного труда. Натив может стать более известным, популярным, авторитетным благодаря публичным выступлениям. Возможно появление VIP-клиентов. ',
                        '(-) Возможно, в жизни натива появится враг, который будет подавлять авторитетом. Указание на затяжные судебные разбирательства. '
                    ]
                },
                8: {
                    title: 'Юпитер по управителю 8 дома',
                    description: [
                        '(+) На таком транзите натив может выиграть на инвестициях, объединении капитала с покровителем. Велика вероятность получения наследства. Натив может оказывать духовную поддержку людям, попавшим в тяжелую жизненную ситуацию. ',
                        '(-) Из-за авантюризма и геройства могут возникать кризисные ситуации, на грани жизни и смерти. Возможны юридические проблемы из-за инвестиций, займов и крупных покупок.'
                    ]
                },
                9: {
                    title: 'Юпитер по управителю 9 дома',
                    description: [
                        '(+) На таком транзите натив может захотеть изменить свою жизнь, сделать ее более возвышенной, благородной; может захотеть внести «иностранных веяний». Велика вероятность начала престижного, элитного обучения или получения гранта. ',
                        '(-) На таком транзите натив может усиленно продвигать собственные мировоззренческие представления, спорить с окружающими о происходящем в мире. Натив может разочароваться в чужой культуре, потерять интерес к путешествиям.'
                    ]
                },
                10: {
                    title: 'Юпитер по управителю 10 дома',
                    description: [
                        '(+) На таком транзите натива ждет продвижение по службе, расширение полномочий. Возможно получение сертификатов, дипломов, наград. Улучшаются отношения с вышестоящими, появляются покровители. ',
                        '(-) Возможно преждевременное повышение в должности, обязанности которой слишком сложны. Придирчивое отношение начальника, проверки выполнения обязанностей натива. Скандальная слава. Транзит выявляет проблемы, вызванные чрезмерно оптимистическим, надуманным планированием карьеры.'
                    ]
                },
                11: {
                    title: 'Юпитер по управителю 11 дома',
                    description: [
                        '(+) Натив окунается в общественную и коллективную деятельность. Может поглотить участие в благотворительных акциях, шефство над группой людей (детей). Транзит способствует приобретению авторитета среди единомышленников. ',
                        '(-) Общественная деятельность доставляет массу хлопот, отнимает все силы. Натив может оказаться перед выбором «собственные идеалы или общее благо». Натива могут окружать ненадежные влиятельные друзья, которые много говорят, но мало делают.'
                    ]
                },
                12: {
                    title: 'Юпитер по управителю 12 дома',
                    description: [
                        '(+) Натив сознательно скрывает значительную часть своей жизни, успешно хранит чужие тайны, секретную информацию. На таком транзите натив усердно и сосредоточенно занимается своими делами в уединении. ',
                        '(-) Нативу сложно бороться со своими тайными пороками, зависимостями. Возможно активизируются тайные враги, которые захотят раскрыть секреты натива. Удержать что-то от лишних глаз будет сложно.'
                    ]
                },
            }
        },
        lilit: {
            planet: {
                sun: {
                    title: 'Лилит по Солнцу',
                    description: [
                        'Гордыня, тщеславие, эгоизм. ',
                        'Искаженное восприятие высказываний на свой счет, болезненное отношение к критике и любому вниманию со стороны. Неблагоприятный период для принятия важных решений. В это время у натива много энергии, из-за чего действие часто опережает осознание. Преувеличение своей значимости и активное навязывание мнения окружающим. Духовное падение, отсутствие интереса к жизни, полное разочарование в своих силах.'
                    ]
                },
                moon: {
                    title: 'Лилит по Луне',
                    description: [
                        'Капризы, потакание своим слабостям, эмоциональные манипуляции.',
                        'Повышенная чувствительность к своим личным проблемам и отсутствие интереса к тяжелым ситуациям окружающих. Натив перестает воспринимать чужую боль, но сам становится ранимым, обидчивым и истеричным. Всплывают многие комплексы, обиды, скрытые стороны натива. '
                    ]
                },
                mercury: {
                    title: 'Лилит по Меркурию',
                    description: [
                        'Обманы, мошенничества. ',
                        'Столкновение с клеветой, ложью, дезинформацией, участие натива в этих темах. Натива тянет на дурные мысли и поступки, его окружают сплетни и слухи. Все новые знакомые приходят в жизнь не просто так, а чтобы высветить нашу тень и преподнести какой-то урок.'
                    ]
                },
                venus: {
                    title: 'Лилит по Венере',
                    description: [
                        'Лень, похоть. ',
                        'Натива сопровождает чувство недовольства собой и другими. Часто всплывают сексуальные комплексы, которые приводят к порочному образу жизни. Столкновение с искушениями супружеской верности, влечение к деньгам и азарту, праздному образу жизни, лени и разгильдяйству. Склонность к странным покупкам, большим тратам.'
                    ]
                },
                mars: {
                    title: 'Лилит по Марсу',
                    description: [
                        'Агрессия, гнев, хамство. ',
                        'Натив проявляет всю свою мощную силу на деструктивные и порочные действия. Может стать буйным, неуправляемым, агрессивным. Обратная сторона медали – сам натив может оказаться жертвой подобного поведения. Стремление доминировать, либо столкновение с чужим давлением и напором. '
                    ]
                },
                jupiter: {
                    title: 'Лилит по Юпитеру',
                    description: [
                        'Гордыня, превышение полномочий, аморальное поведение. ',
                        'Нежелание подчиняться, пренебрежение к общественной морали. Превышение полномочий (особенно у властных и статусных людей) и навязчивое желание легко разбогатеть или прославиться. Натив стремится навязывать свою точку зрения, либо сам сталкивается с такими людьми.'
                    ]
                },
                saturn: {
                    title: 'Лилит по Сатурну',
                    description: [
                        'Зависть, мстительность, жестокость, тотальный контроль. ',
                        'Деспотичное поведение по отношению к окружающим, желание контролировать абсолютно все. Склонность к подавлению других людей. Риск серьезных депрессий, которые могут привести к одиночеству. '
                    ]
                },
                uranus: {
                    title: '',
                    description: [
                        'Лилит по Урану, Нептуну и Плутону проявляется в тех домах, где находятся эти планеты или которыми они управляют. Сила проявления этих обстоятельств зависит от аспектации и личных показателей в натальной карте.'
                    ]
                },
                neptun: {
                    title: '',
                    description: [
                        'Лилит по Урану, Нептуну и Плутону проявляется в тех домах, где находятся эти планеты или которыми они управляют. Сила проявления этих обстоятельств зависит от аспектации и личных показателей в натальной карте.'
                    ]
                },
                pluto: {
                    title: '',
                    description: [
                        'Лилит по Урану, Нептуну и Плутону проявляется в тех домах, где находятся эти планеты или которыми они управляют. Сила проявления этих обстоятельств зависит от аспектации и личных показателей в натальной карте.'
                    ]
                },
                nodes: {
                    title: 'Лилит по Северному/Южному узлам',
                    description: [
                        'Транзит по Северному узлу указывает на порочные стороны натива на внешнем уровне или через окружение. Натив встречает “знаки” повсюду, происходит визуализация всех темных сторон жизни как указание на личностную проработку. ',
                        'Транзит по Южному узлу дает возможность посмотреть внутрь себя и осознать свои негативные склонности. Возникают ситуации, обязывающие помогать людям с психологическими проблемами, которые уже случались в жизни самого натива.'
                    ]
                },
                lilit: {
                    title: '',
                    description: [
                        'Данных нет'
                    ]
                },
            },
            house: {
                1: {
                    title: 'Лилит по I дому',
                    description: [
                        'Стремление реализовать свои негативные качества. Часто - зацикленность на внешнем виде, излишний эгоизм и нарциссические нотки. Искажение в восприятии себя и своих потребностей. Внутри происходит борьба между добром и злом. Могут обостряться психические проблемы.',
                    ]
                },
                2: {
                    title: 'Лилит по II дому',
                    description: [
                        'Зацикленность на деньгах, удовольствиях, еде, покупках. Жадность и постоянная потребность к накоплению, либо большим растратам. Искушение ленью, перееданием, деньгами и ресурсами. Склонность к аморальным способам заработка, клептомании, мошенничеству. ',
                    ]
                },
                3: {
                    title: 'Лилит по III дому',
                    description: [
                        'Искушение информацией, связями, контактами, получением запретных данных. Сам натив вызывает сплетни вокруг своей персоны, либо активно распространяет слухи о других. Любые новые знакомства принесут больше проблем, чем пользы. Фальсификация данных, манипуляции окружением, стремление разведывать данные нечестным путем. Мошенничество в продажах или в работе с документами. ',
                    ]
                },
                4: {
                    title: 'Лилит по IV дому',
                    description: [
                        'Появление семейных тайн, осознание своей родовой кармы через опыт прошлого. Столкновение с предательством в семье, неприятными родовыми историями. Трудности в общении с родителями (особенно у детей). В семье много недосказанности, присутствует недоверие и разобщенность. Могут быть манипуляции по разделению собственности или наследства, нечестность родных и близких. Обострение детских комплексов, связанных с воспитанием.',
                    ]
                },
                5: {
                    title: 'Лилит по V дому',
                    description: [
                        'Зацикленность на удовольствиях и хобби в ущерб здравому смыслу. Обострение азартности и игромании. Проявление инфантильного и глупого поведения у взрослых людей. Часто — слишком сильная привязанность к ребёнку, либо полное безразличие. Склонность манипулировать своими детьми и партнерами. Постоянный недостаток любви и поиск любовных приключений.',
                    ]
                },
                6: {
                    title: 'Лилит по VI дому',
                    description: [
                        'Перебои в вопросах здоровья и самочувствия. Деструктивный режим жизни (рутина изматывает). Хроническое ощущение апатии и болезненности, психосоматические заболевания, проблемы с диагностированием и постановкой диагноза. Сложные отношения с сослуживцами, интриги в коллективе, постоянно вылезают недочеты в той работе, которую выполняет натив.',
                    ]
                },
                7: {
                    title: 'Лилит по VII дому',
                    description: [
                        'Предательства со стороны близких людей и партнеров. Подозрительность в семейных отношениях, склонность к изменам и манипуляциям. Искаженное восприятие людей (особенно новых знакомых). Нечестность в судебных процессах, ложные обвинения, клевета.',
                    ]
                },
                8: {
                    title: 'Лилит по VIII дому',
                    description: [
                        'Нездоровое увлечение эзотерическими темами, вопросами жизни и смерти или неадекватное восприятие этих тем. Опасные ситуации с чужими деньгами и ресурсами, рисковые и экстремальные ситуации, крупные финансовые потери, опасные сексуальные эксперименты. Риск грабежа или нападения. Обострение фобий и страхов.',
                    ]
                },
                9: {
                    title: 'Лилит по IX дому',
                    description: [
                        'Риск попадания в секты и другие групповые течения. Сложный период для религиозных людей, искушение новой верой и идеологией. Опасность в дальних поездках и при знакомстве с иностранцами. Идеализации чужой культуры и заграничной жизни. Проблемы в учебном коллективе. Контакт с лже-учителями или гурумошенниками.',
                    ]
                },
                10: {
                    title: 'Лилит по X дому',
                    description: [
                        'Искушение властью и высоким положением. Бескомпромиссное стремление к статусу, личные цели ставятся превыше остальных, приходит желание идти по головам. Использование других людей в корыстных целях, коррупционные действия. Обман со стороны статусных людей, начальников. Дурная слава в социуме.',
                    ]
                },
                11: {
                    title: 'Лилит по XI дому',
                    description: [
                        'Опасность попадания под психологическое давление со стороны сект и партийных движений. Сложные отношения с друзьями, возможны подставы и предательства.',
                    ]
                },
                12: {
                    title: 'Лилит по XII дому',
                    description: [
                        'Проблемы психического характера, эмоциональные расстройства, нарушение сна, депрессивные мысли. Увлечение странными тайными занятиями. Искушение чужими секретами, ложные духовные учения, зависимость от собственных грёз и фантазий, внушаемость. Потеря контакта с реальностью, фанатичная религиозность, вступление в секту. Появляются тайные враги и недоброжелатели. Обостренная реакция на одиночество. ',
                    ]
                },
            },
        },
        personal: {
            sun: {
                sun: {
                    title: 'Солнце по Солнцу',
                    description: [
                        'Данный транзит отдельно и очень подробно рассматривается в разделе СОЛЯР.',
                        '(+) Натив переполнен энергией и желанием творить! Возможность реализовать многие творческие инициативы и проекты, давно намеченные планы. Солнце стремиться проявить себя теми положительными качествами, какие заложены в гороскопе рождения.',
                        '(-) Самооценка натива внезапно устремляется вниз. Ухудшается физическое самочувствие, повышается конфликтность. Солнце стремиться проявить себя теми отрицательными качествами, какие заложены в гороскопе рождения.',
                    ]
                },
                moon: {
                    title: 'Солнце по Луне',
                    description: [
                        '(+) Время, когда сознание и подсознание приходят в баланс. Натив становится чувствительнее, внимательнее к окружающим, стремиться позаботиться о ком-либо из своего окружения. Благоприятное время для контактов с публикой и обретения популярности, для деловой и общественной активности.',
                        '(-) Внутреннее содержание и внешнее проявление человека в конфликте. Нервозность и обидчивость создают неблагоприятный фон для любых начинаний. Падает активность, физические и душевные силы на исходе, раздирают внутренние противоречия. Быстрая смена настроения и эмоциональная уязвимость мешают в любых делах.',
                    ]
                },
                mercury: {
                    title: 'Солнце по Меркурию',
                    description: [
                        '(+) Появление новых идей и их признание. Продуктивен обмен мнениями, брифинги, конференции. Хороший период для начала служебных и творческих командировок, заключения сделок, подписания контрактов и других важных бумаг. Появление новых деловых связей и знакомств, обретение полезной новой информации.',
                        '(-) Страдает память, концентрация внимания, способность к усвоению материала. Трудность в контактах, сумбурность происходящего вокруг. В общении проявляется хвастовство, желание к5азаться лучше, чем есть на самом деле.',
                    ]
                },
                venus: {
                    title: 'Солнце по Меркурию',
                    description: [
                        '(+) Появление новых идей и их признание. Продуктивен обмен мнениями, брифинги, конференции. Хороший период для начала служебных и творческих командировок, заключения сделок, подписания контрактов и других важных бумаг. Появление новых деловых связей и знакомств, обретение полезной новой информации.',
                        '(-) Страдает память, концентрация внимания, способность к усвоению материала. Трудность в контактах, сумбурность происходящего вокруг. В общении проявляется хвастовство, желание к5азаться лучше, чем есть на самом деле.',
                    ]
                },
                mars: {
                    title: 'Солнце по Марсу',
                    description: [
                        '(+) Время личной активности, инициативности и самоуверенности. Все действия совершаются энергично и приносят результат. Благоприятный период для бизнеса. Спорт, физический труд и любая активная деятельность доставляют удовольствие и приносят заметные результаты. Растет сексуальное желание.',
                        '(-) Возрастет неадекватное стремление к лидерству и конфликтность. Увеличивается внутреннее напряжение, раздражительность, агрессия. Натив склонен решать вопросы силовыми методами.',
                    ]
                },
                jupiter: {
                    title: 'Солнце по Юпитеру',
                    description: [
                        '(+) Возрастает авторитет и внимание к нативу. Хороший период для самообразования, повышения квалификации и демонстрации себя. Щедрость и благородство – главные спутники подобного транзита.',
                        '(-) Высокомерное и пафосное отношение к другим, стремление выделиться любой ценой. Позерство и хвастовство. Лживая благодетель',
                    ]
                },
                saturn: {
                    title: 'Солнце по Сатурну',
                    description: [
                        '(+) Натив склонен проявлять упорство, выдержку в делах и планах, усердие в работе. Хорошее время для обращения в официальные инстанции, для сложной работы, где необходима системность, дисциплина и порядок.',
                        '(-) Затяжное само копание, пессимизм, плохие мысли и известия. Натив ощущает себя одиноким, покинутым. Расстройство многих планов, обременительные обязанности, напоминание о долгах и обязательствах.',
                    ]
                },
                uranus: {
                    title: 'Солнце по Урану',
                    description: [
                        '(+) Стремление к свободе действий и поиску возможностей оригинального творческого самовыражения. Время прозрений и удачных начинаний. Встречи с новыми людьми, вдохновенный труд, внезапно открывшиеся перспективы способствуют успеху. Удачно протекают связи с общественностью, хорошее время для политиков и лидеров различных движений.',
                        '(-) Ощущения полного отсутствия порядка в жизни. Суета, неконтролируемые события, в которые вовлечен натив.',
                    ]
                },
                neptun: {
                    title: 'Солнце по Нептуну',
                    description: [
                        '(+) Пробуждение интуитивных и творческих способностей. Хорошее время для благотворительности, меценатства, духовных практик. Благоприятный период для людей творческих профессий.',
                        '(-) Риск отравлений, активации зависимостей. Состояние нервозности, капризности, повышенной эмоциональности. Есть склонность к дурному влиянию.',
                    ]
                },
                pluto: {
                    title: 'Солнце по Плутону',
                    description: [
                        '(+) Время пробуждения лидерства и желания управлять. Сила воли, концентрация, изобретательность и напор дадут возможность многое изменить в жизни.',
                        '(-) Склонность манипулировать или риск попасть под психологическое влияние. Плохое время для посещения масштабных мероприятий.',
                    ]
                },
                nodes: {
                    title: 'Солнце по Северному/Южному узлам',
                    description: [
                        '(+) Соединение с Северным узлом – время судьбоносных знаков и возможностей себя проявить. Важно становится активным, проявлять ответственность и самостоятельность в своих решениях.',
                        '(-) Соединение с Южным узлом – плохое время для важных стартов и начинаний, заторможен процесс проявления личности в мир.',
                    ]
                },
                lilit: {
                    title: '',
                    description: [
                        '',
                        '',
                        '',
                    ]
                },
            },
            mercury: {
                sun: {
                    title: 'Меркурий по Солнцу',
                    description: [
                        '(+) Время эффективного выражения своих идей и всплеск интеллектуальной активности.',
                        '(-) Много суеты и лишних контактов в окружении. Просыпается дух противоречия, натив может высказывать неуместные идеи, о которых впоследствии будет сожалеть.',
                    ]
                },
                moon: {
                    title: 'Меркурий по Луне',
                    description: [
                        '(+) Эмоции дружат с коммуникациями. Отличное время для развития эмоционального интеллекта, для осознания и анализа своих чувств. Транзит может принести приятное дружеское общение.',
                        '(-) Обострение мнительности, капризности и желание делиться со всеми своими личными проблемами. Несущественные разговоры, эмоциональная неустойчивость, трудности в общении.',
                    ]
                },
                mercury: {
                    title: 'Меркурий по Луне',
                    description: [
                        '(+) Эмоции дружат с коммуникациями. Отличное время для развития эмоционального интеллекта, для осознания и анализа своих чувств. Транзит может принести приятное дружеское общение.',
                        '(-) Обострение мнительности, капризности и желание делиться со всеми своими личными проблемами. Несущественные разговоры, эмоциональная неустойчивость, трудности в общении.',
                    ]
                },
                venus: {
                    title: 'Меркурий по Венере',
                    description: [
                        '(+) Успех в творчестве и искусстве (особенно поэзия, писательство). Приятные знакомства и общение с противоположным полом. Натив больше склонен к пониманию интересов и чувств других людей. Остроумие, проявления хорошего вкуса и манер усиливают привлекательность и помогают завязать полезные контакты.',
                        '(-) Нарушение и препятствия в деловых, общественных и светских связях. Трудности в общении, невозможность выразить свое мнение и интересы. Рассеянность и необъективность, что ведет к принятию неправильных решений.',
                    ]
                },
                mars: {
                    title: 'Меркурий по Марсу',
                    description: [
                        '(+) Хороший период для конструктивного обсуждения и полезных споров, а также для выработки деловой стратегии. Мышление становится более динамичным, а натив более решительным.',
                        '(-) Споры, ссоры, проявление грубости и агрессии. Раскоординированные действия на дороге и в поездках, возможны аварии или поломка транспорта. Желание командовать и гнуть свою линию.',
                    ]
                },
                jupiter: {
                    title: 'Меркурий по Юпитеру',
                    description: [
                        '(+) Позитивное мышление, интеллектуальный оптимизм и интерес к образованию. Благоприятны дальние и длительные поездки, связи и заключение договоров с иностранными партнерами. Хорошее время для издательской деятельности, повышения квалификации. Удачно протекают связи со средствами массовой информации, рекламные мероприятия. Повышается интерес к культурной жизни.',
                        '(-) Нереалистичные идеи и планы, под которыми нет оснований. В общении много фальши и хвастовства. Натив склонен преувеличивать и/или придавать значение несущественным вещам',
                    ]
                },
                saturn: {
                    title: 'Меркурий по Сатурну',
                    description: [
                        '(+) Мозг работает слаженно и системно. Хорошая организованность способствует усиленной интеллектуальной работе или учебе. Полное погружение и концентрация на выбранной специальности. Благоприятный период для сдачи экзаменов, для серьезных исследований, работы с документами и их подписания.',
                        '(-) Мышление тормозит, информация усваивается тяжело. Натив становится упрямым, сокращает контакты, мыслительный процесс разворачивается вовнутрь, отсюда сложности с публичными выступлениями.',
                    ]
                },
                uranus: {
                    title: 'Меркурий по Урану',
                    description: [
                        '(+) Внезапное озарение и появление новых проектов и идей, которые важно зафиксировать и сохранить на будущее. Творческое мышление, стремление к преобразованиям. Неожиданные новости.',
                        '(-) Полный хаос в мыслях. Разбросанность ума, импульсивное принятие решений, возникновение неожиданных проблем, неприятных обстоятельств. Поломки средств коммуникации, электроники, оргтехники.',
                    ]
                },
                neptun: {
                    title: 'Меркурий по Нептуну',
                    description: [
                        '(+) Идеальное время для творчества. Натив становится более чувствительным, а его мышление более глубоким. На полную мощность работает воображение и интуиция. Благоприятный период для коммуникаций с нептунанцами (психологи/эзотерики).',
                        '(-) Неразбериха, путаница в делах и общении, разум как будто замутнен, трезво оценивать ситуацию и принимать взвешенные решения невозможно. На поверхность вылезают все потаенные страхи, растет тревожность.',
                    ]
                },
                pluto: {
                    title: 'Меркурий по Плутону',
                    description: [
                        '(+) Большая сила убеждения и возможность ее применения. Натив может использовать свою речь, как инструмент влияния. Хороший период для исследований, в том числе деловых и финансовых вопросов, для применения интуиции и проницательности.',
                        '(-) Настойчивое желание утвердить свою власть и влияние, из-за чего возможны столкновения с окружением. Натив легко подвергается негативному влиянию. Опасность в поездках и при обращении с техникой.',
                    ]
                },
                nodes: {
                    title: 'Меркурий по Северному/Южному узлам',
                    description: [
                        '(+) Соединение с Северным узлом – появление знаков через информационное поле вокруг натива (новости, телевидение, книги, диалоги). Важно обретать новый меркурианский опыт (учиться, выступать, объяснять).',
                        '(-) Соединение с Южным узлом – заторможенное мышление и умственная деятельность, вынужденные контакты с неинтересными людьми.',
                    ]
                },
                lilit: {
                    title: '',
                    description: [
                        '',
                        '',
                        '',
                    ]
                },
            },
            venus: {
                sun: {
                    title: 'Венера по Солнцу',
                    description: [
                        '(+) Окружающим становятся заметны обаяние и физическая привлекательность натива. Устанавливается благоприятный контакт с противоположным полом, будь то начальство, ухажеры или деловые партнеры. Период благоприятен для воздействия на публику, повышения популярности. Возможен коммерческий успех, получение прибыли, подарков. Творческая деятельность принесет успехи в это время.',
                        '(-) Склонность потакать своим прихотям в погоне за чрезмерными удовольствиями. Экстравагантные порывы, расточительство, лень. Возможны недоразумения и конфликты, особенно с людьми противоположного пола. Неудовлетворенность от романтичных союзов, нарциссизм.',
                    ]
                },
                moon: {
                    title: 'Венера по Луне',
                    description: [
                        '(+) Гармонизация эмоций, чуткое отношение к миру, чувство расслабленности, текучести, восхищения и вдохновения. Крайне благоприятно протекает общение с женщинами, детьми, животными. Хороший период для женских процедур и заботы о доме.',
                        '(-) Обидчивость, мнительность и концентрация на мелких недоразумениях, что мешает сосредоточиться на делах. Привлекательным кажется размеренный и максимально ленивый образ жизни, появляется склонность к перееданию и безалаберности.',
                    ]
                },
                mercury: {
                    title: 'Венера по Меркурию',
                    description: [
                        '(+) Натива увлекают новые направления и хобби. Проявление писательского творчества, сочинительства и красноречия. Благоприятный транзит для певцов и ораторов. Легко заводятся полезные знакомства, общение приносит приятные эмоции, легко разрешаются конфликты. Возможны удачные короткие деловые поездки, получение хороших известий.',
                        '(-) Витание в облаках, мечтательность и невнимательность к серьезным задачам. Трудности в деловом общении и при контактах с нужными людьми. Чувствительность к влиянию извне и мнению других людей. Настойчивое и часто неуместное желание получить словесную похвалу.',
                    ]
                },
                venus: {
                    title: 'Венера по Венере',
                    description: [
                        '(+) Идеальное время для проработки качеств Венеры. Абсолютная энергия любви, красоты и удовольствий. Время творчества, ухода за собой, создания чего-то прекрасного. Увеличивается привлекательность натива в глазах других людей, сам человк становится более дружелюбным и мягким.',
                        '(-) Болезненное отношение к критике и любому мнению со стороны, разочарование в себе, проявление зависти к другим. Характерны леность и чувствительность, нежелание работать. Финансовая расточительность, особенно на развлечения, предметы роскоши и личную жизнь.',
                    ]
                },
                mars: {
                    title: 'Венера по Марсу',
                    description: [
                        '(+) Проявление страстного импульса к чему-либо, даже к работе. Венера мягко активирует Марс, что может дать приятное возбуждение от необходимости выполнить какое-либо дело. Хороший транзит для общения с противоположным полом.',
                        '(-) Повышенная эмоциональная возбудимость, классический аспект ревности и выяснения отношений с самыми близкими людьми. Склонность к игнорированию чувств и интересов окружающих, в том числе деловых и любовных партнеров.',
                    ]
                },
                jupiter: {
                    title: 'Венера по Юпитеру',
                    description: [
                        '(+) Транзит успешного свершения дел (вы будет часто его использовать, особенно если у натива одна из этих планет связана с важными домами). Прекрасное время для социально заметной деятельности, реализации проектов на глазах у общества (выступления, презентации, показы и т.д.).',
                        '(-) Лень, чрезмерное стремление к удовольствиям и демонстративности, необдуманные покупки, позерство и неадекватная щедрость.',
                    ]
                },
                saturn: {
                    title: 'Венера по Сатурну',
                    description: [
                        '(+) Все венерианские проявления человека упорядочиваются, обретают форму и почву под ногами. В отношениях хочется стабильности, в творчестве – точности. Вкус, стиль, отношение к любви вдруг становятся более консервативными. Благоприятными могут стать контакты с людьми старшего возраста.',
                        '(-) Проблемы в отношениях как делового, так и личного характера изза проявляемого недоверия и сдержанности, скупости чувств. Краткосрочный период упадка сил, уныния, все кажется серый и некрасивым. На таком транзите очень плохо переносится одиночество.',
                    ]
                },
                uranus: {
                    title: 'Венера по Урану',
                    description: [
                        '(+) Необычное творческое вдохновение. Приятный эмоциональный настрой, некоторая возбужденность, проживание нового, нестандартного чувственного опыта. Хорошее время для обновления внешности (если обе планеты в карте добрые).',
                        '(-) Непрактичное, безответственное отношение ко многим проблемам и обязанностям. Странная потребность в сексуальных экспериментах, неразбериха у чувствах, неадекватная тяга к новому.',
                    ]
                },
                neptun: {
                    title: 'Венера по Нептуну',
                    description: [
                        '(+) Транзит чистого вдохновения. Психологическая чувствительность и обостренное восприятие прекрасного. Натив очень тонко понимает искусство, это один из лучших транзитов для музыкантов и вообще творческих людей. Гармония в отношениях, сильная влюбленность, романтизм.',
                        '(-) Разбалансировка чувственной и эмоциональной сферы, психологические переживания. Навязчивые фантазии, тайные действия в отношениях, активация зависимостей.',
                    ]
                },
                pluto: {
                    title: 'Венера по Плутону',
                    description: [
                        '(+) Благоприятный период для обновления и преобразования любовных отношений. Возникновение новых романтических возможностей. Смелость и уверенность в своих стремлениях, возможность получить желаемое.',
                        '(-) Вынужденные разлуки, конфликты, вспышки ревности, неуемная сексуальность. Неразборчивые интимные связи. Транзит может активировать/запустить болезни по Венере.',
                    ]
                },
                nodes: {
                    title: 'Венера по Северному/Южному узлам',
                    description: [
                        '(+) Соединение с Северным узлом приносит новый опыт в любви и взаимоотношениях, творческие порывы, эстетические увлечения и новые венерианские хобби.',
                        '(-) Соединение с Южным узлом несет отсутствие интереса к любовной сфере, отчуждение и безынициативность в партнерстве, откат к старым моделям поведения в личной жизни.',
                    ]
                },
                lilit: {
                    title: '',
                    description: [
                        '',
                        '',
                        '',
                    ]
                },
            },
            mars: {
                sun: {
                    title: 'Марс по Солнцу',
                    description: [
                        '(+) Возрастает активность, инициативность и самоуверенность. Благоприятный период для бизнеса, начала новых проектов. Энергия возрастает в разы, отличное время для спортивной и рабочей деятельности.',
                        '(-) Агрессивное, импульсивное поведение, необдуманные поступки даже у самых спокойных людей. Стремление к конкуренции и болезненное восприятие критики.',
                    ]
                },
                moon: {
                    title: 'Марс по Луне',
                    description: [
                        '(+) Возрастает энергичность в решении эмоциональных проблем. Прямота и честность, честолюбие и предприимчивость преобладают в чувствах. Возрастает сексуальная активность и интенсивные переживания.',
                        '(-) Эмоциональная неустойчивость, импульсивность и высокая раздражительность, необходимость решать одновременно деловые и домашние проблемы. Мелкие неприятности вызывают гнев. Остро воспринимаются выходки окружающих. Возможны физические проблемы – бессонница, головная боль, расстройство пищеварения.',
                    ]
                },
                mercury: {
                    title: 'Марс по Меркурию',
                    description: [
                        '(+) Способность динамичного планирования, координации действий и предприимчивость в коммуникациях. Хороший период для переговоров, обсуждения дальнейшей стратегии работы. Возможность освоить новый материал в короткие сроки.',
                        '(-) Эмоциональны споры и агрессивное отстаивание своих позиций в диалоге. Риски в управлении транспортом и техникой. Может возникнуть неисправность средств связи. Транзит несет суетливость и спешку.',
                    ]
                },
                venus: {
                    title: 'Марс по Венере',
                    description: [
                        '(+) Повышается сексуальное влечение, жажда обладания чем-либо или кем-либо. Завязываются интересные знакомства, появляются новые увлечения. Высокая потребность в развлечениях и шопинге. Марс придает динамики венерианским настроениям и старается их проявить через действия.',
                        '(-) Возрастает агрессивность и неумеренная импульсивность. Преувеличенная сексуальная заинтересованность может привести к скандалам. Возможны разногласия, ссоры, расставания, проблемы в любви. Вспышки ревности сочетаются с игнорированием интересов близких. Натив предпочитает силовое решение, но может и сам стать жертвой такого обращения.',
                    ]
                },
                mars: {
                    title: 'Марс по Марсу',
                    description: [
                        '(+) Крайн благоприятный транзит для обладателей гармоничного Марса. Способность преодолевать любые препятствия и выполнять все намеченные цели. Увеличивается выносливость, стремление к победе. Идеальное время для спортивной деятельности и соревнований. Хороший период для работы руками. Повышается сексуальная активность, склонность к завязыванию краткосрочных интимных отношений.',
                        '(-) Импульсивность, вспышки гнева и агрессивность. Вероятны скандалы и даже драки. Часто этот период характеризуется разрывом отношений, разлуками. Опасность травм, ожогов и перенапряжения.',
                    ]
                },
                jupiter: {
                    title: 'Марс по Юпитеру',
                    description: [
                        '(+) Прилив силы и энергии в решении масштабных проектов. В этот период можно достичь мирного урегулирования многих личных проблем. Отличное время для достижения высоких спортивных результатов.',
                        '(-) Неуравновешенность, недовольство и склонность отстаивать мировоззренческие позиции силовым путем. Возникают конфликтные ситуации в социуме с вероятностью потерять авторитет.',
                    ]
                },
                saturn: {
                    title: 'Марс по Сатурну',
                    description: [
                        '(+) Высокие требования на работе и серьезные обязательства, требующие пристального внимания натива. Потребность в физическом труде. Успешна работа, требующая применения физической силы, расчета, собранности и различных инструментов и механизмов. Хороший период для решения хозяйственных вопросов, для ремонта, обустройства жилья.',
                        '(-) Опасность травм и классический аспект переломов. Время ссор, конфликтов, особенно с людьми старшего возраста. Возможны разлуки, расставания и, одновременно, борьба с одиночеством.',
                    ]
                },
                uranus: {
                    title: 'Марс по Урану',
                    description: [
                        '(+) Характерно большое стремление к независимости. Усиливается изобретательность и инициативность. Часто возобновляются и пускаются в ход дружеские связи. Благоприятны контакты с различными группировками, общественными организациями.',
                        '(-) Тоже классический аспект травм. Следует соблюдать повышенную осторожность при пользовании электрических приборов, быть внимательнее за рулем. Транзит приносит импульсивность, вспышки гнева, стремление к независимости действий любой ценой, крайний эгоцентризм. Разногласия с друзьями, отсутствие их поддержки.',
                    ]
                },
                neptun: {
                    title: 'Марс по Нептуну',
                    description: [
                        '(+) Повышенная чувствительность и обострение сексуальных желаний. Усиливается фантазия и творческие способности. Прекрасный период для развития и демонстрации различных талантов и умений. В успешном течении дел заметное место занимает предпринимательская интуиция, здоровая конкурентоспособность.',
                        'Хороший период для контактов с благотворительными, религиозными, культурными организациями. Приятные достижения ожидаются в спорте и духовных практиках.',
                        '(-) Потеря бдительности, увлечение алкоголем или другими одурманивающими веществами приводят к тяжким последствиям. Навязчивые сексуальные фантазии и фанатизм. Столкновение с интригами, мошенничеством, шантажом, угрозами.',
                    ]
                },
                pluto: {
                    title: 'Марс по Плутону',
                    description: [
                        '(+) Невероятная сила воли, жажда действий и власти. Благоприятны связи с широкими массами и общественными организациями, если планеты в карте гармоничны. Увеличение сексуального желания, готовность к экспериментам, стремление доминировать.',
                        '(-) Импульсивность действий, вспышки агрессии, тяга к насилию и манипуляциям (либо сам натив оказывается жертвой подобного поведения). Стремление к лидерству может поставить в серьезные условия борьбы и конкуренции. При плохо аспектированных планетах в натале – высокий риск нападения или травмы при нахождении в толпе.',
                    ]
                },
                nodes: {
                    title: 'Марс по Северному/Южному узлам',
                    description: [
                        '(+) Соединение с Северным узлом дает появление в нашем поле примеров людей марсианского и воинственного поведения, которому мы можем/хотим/должны обучиться. Время осмысления своей силы, и активности.',
                        '(-) Соединение с Южным узлом дает снижение физической активности и формирует обстоятельства вынужденного участия в мероприятиях, где требуются марсианские качества.',
                    ]
                },
                lilit: {
                    title: '',
                    description: [
                        '',
                        '',
                        '',
                    ]
                },
            },
        }
    },
    solar: {
        'planet-in-house': {
            sun: {
                1: {
                    title: 'Солнце в 1 доме Соляра',
                    description: [
                        'Важнейший год, крайне значимый для натива.',
                        'В такие периоды закладывается фундамент изменений, обычно происходят события, которые в долгосрочной перспективе меняют весь уклад жизни. При сопутствующих показателях сюда могут относиться свадьбы, рождения детей, кардинальная смена работы или даже переезд — всё то, что сильно влияет на жизнь человека. Какие именно события произойдут - подскажут положения остальных планет Соляра и дополнительные методы прогноза.',
                        'Рекомендуется быть активным, проявлять инициативу, максимально задействовать собственное «Я» для достижения результата. При поражённом Солнце может проявляться эгоцентризм, высокомерие, нежелание слушать окружающих. При слабом Солнце (плохо проработано) человек с трудом ощущает и проявляет свою волю, вышеописанные тенденции работают значительно слабее и не приносят яркие переломные события.'
                    ]
                },
                2: {
                    title: 'Солнце во 2 доме Соляра',
                    description: [
                        'На первый план выходят темы финансов.',
                        'Много внимания и энергии уделяется заработку, тема становится актуальной и «подсвеченной» как никогда. Это период, когда следует откладывать деньги, так как в течение года может возникнуть необходимость в крупных покупках, чаще всего они и происходят.',
                        'Гармоничное положение Солнца даёт хорошие возможности заработать, благоволит получению средств извне (включая подарки, гранты и т.п.), существенно развивает тему финансов в жизни, генерирует новые источники дохода. Дисгармоничное Солнце часто указывает на неэффективные траты, слив бюджета, необдуманные покупки и желание пустить пыль в глаза.',
                        'В гороскопе женщин может указывать на финансовое положение мужа.'
                    ]
                },
                3: {
                    title: 'Солнце в 3 доме Соляра',
                    description: [
                        'Тема года – коммуникации.',
                        'Такое положение Солнца увеличивает количество контактов в году, несёт активное общение, необходимость собирать информацию, работать с документами, перемещаться. Прекрасное время для коммерческой деятельности и учебы (особенно для разного рода курсов). Подходит для активных продаж, рекламы и поиска новых клиентов. Может также указывать на важные события в ближайшем круге (братья/сестры/близкие друзья), в которые человек будет вовлечен.',
                        'Поражённое Солнце может являться причиной ссор и споров с родственниками, сплетен и вымыслов, неприятностей в поездках, сложностей в учебе.'
                    ]
                },
                4: {
                    title: 'Солнце в 4 доме Соляра',
                    description: [
                        'На первый план в этом году выходят темы семейной жизни, рода и недвижимого имущества.',
                        'Положение может указывать на приобретение квартиры/дома/участка, если другие показатели это подтверждают, переезд (при задействовании 3 дома) или ремонтные работы. Может увеличить пребывание натива в доме (как вариант работы из дома). Дела семьи оказывают сильное влияние на человека, становятся основным лейтмотивом года. Положение может указывать на важные перемены или события в жизни родителей.',
                        'Напряжённые аспекты проявят споры в семье, с родителями, в особо тяжёлых случаях и при иных дисгармоничных указаниях могут означать разрыв отношений, уход из семьи или потерю одного из старших родственников (проверяем всегда транзитами!).',
                        'Хорошее время для работы с собственными корневыми установками, изучения темы рода и проработок по нему.'
                    ]
                },
                5: {
                    title: 'Солнце в 5 доме Соляра',
                    description: [
                        'Темы года: романы, влюбленности, эмоциональные переживания, творчество, дети.',
                        'Солнце в своем доме отличный помощник для того, чтобы проявлять себя. Рекомендуется выступать на сцене, участвовать в разного рода мероприятиях, заявлять о себе. Если планируете рождение/зачатие ребёнка, то это отличный период (при других благоприятных показателях). В жизни человека активизируются любовные истории, контакты и связи, повышается популярность у противоположного пола. Также год может стать важным в жизни детей. Хорошее время для реализации творческих проектов и погружения в собственные хобби, особенно благоприятно такое положение для спортсменов, художников, актёров театра и кино, людей, работающих в индустрии развлечений.',
                        'Доброе Солнце сулит выигрыши и подарки, особенно при вовлечении 2 дома. Спортивные и творческие достижения, тёплые отношения с любимым человеком, а также хороший активный отдых и насыщенную светскую жизнь.',
                        'Злое Солнце приводит к разочарованиям в любви, трудностям в отношениях с детьми.'
                    ]
                },
                6: {
                    title: 'Солнце в 6 доме Соляра',
                    description: [
                        'Темы года: здоровье, работа, рабочий коллектив.',
                        'Год, когда своему физическому состоянию необходимо уделить внимание, особенно если Солнце стоит в напряженных аспектах. Работы и рутинных дел в таком году будет огромное количество. Возможны перемены по этой зоне жизни: изменение в условиях труда, расписании, функциях и т.п.',
                        'Гармоничное Солнце приведёт к тому, что усилия будут оценены по достоинству и человек сможет получить новое назначение, улучшить отношения с коллегами, создать более комфортную обстановку на работе.',
                        'Злое Солнце будет препятствовать деловым достижениям, создавать конкуренцию. Работа будет оставлять человека в подавленном, зависимом состоянии.'
                    ]
                },
                7: {
                    title: 'Солнце в 7 доме Соляра',
                    description: [
                        'Темы года: партнерство и его построение, публичная работа, суды, социальное окружение.',
                        'Происходят важные события или осознания в теме партнерства. В этом году стоит уделить время завязыванию долгосрочных отношений, как деловых так и романтических. Позитивные аспекты Солнца несут серьезные отношения и брак (при прочих указаниях!), новых деловых партнеров, самовыражение и одобрение окружающих. Негативные аспекты могут вести к ссорам, противостояниям, разводам, судебным разбирательствам, конкуренции за власть.',
                        'При таком положении натив либо сам оказывает влияние на других, либо оказывается в зависимом положении от кого-либо (на практике также встречается зависимость от взятых на себя обязательств, а не от конкретного человека). Аспектация Солнца будет говорить о положении дел партнёров и складывающихся с ними отношениях.'
                    ]
                },
                8: {
                    title: 'Солнце в 8 доме Соляра',
                    description: [
                        'Темы года: трансформации, кризисные ситуации, переход на новый уровень, разрушение старых программ.',
                        'Это кризисное положение солярного Солнца и часто оно трактуется как негативное. Такое положение требует компенсаторики в течение года и может нести мощную трансформацию. Возможны потери, ситуации, вынуждающие к изменениям, вовлечение в финансовые вопросы супруга или делового партнёра, рост долговых обязательств.',
                        'Сильное Солнце может говорить о получении наследства, кредита и победу в возникающих финансовых спорах. Часто является благоприятным для политиков, ведущих агрессивную кампанию. Может также указывать на масштабирование бизнеса, постоянную связь с инстанциями (оформление документов, налогов и т.д.)',
                        'Злое Солнце и дополнительные показатели соответствующих домов - есть риск жить под финансовым или психологическим давлением со стороны других людей или обстоятельств.'
                    ]
                },
                9: {
                    title: 'Солнце в 9 доме Соляра',
                    description: [
                        'Темы года: расширение горизонтов, получение образования, дальние путешествия, преподавательская деятельность.',
                        'Удачный дом для Солнца, всем сферам задается толчок к расширению. В этом году благоприятно начинать и завершать учебу, получать образование, степени, официальные серьёзные дипломы, строить долгосрочные планы, путешествовать, знакомиться с чужим образом жизни и культурой. Полезно писать книги, научные труды, издавать их. Один из указателей на успешную эмиграцию (при иных подтверждающих положениях в прогностике).',
                        'Сильное Солнце позволяет оформить взгляды на жизнь, разрешить юридические и судебные вопросы.',
                        'Если Солнце поражено - человек может проигнорировать возможности развития и остаться при своих старых взглядах.'
                    ]
                },
                10: {
                    title: 'Солнце в 10 доме Соляра',
                    description: [
                        'Темы года: карьерный рост, популярность, признание, изменение социального статуса (как профессионального, так и личного).',
                        'Одно из лучших положений Солнца в Соляре, натив запомнить такой год, как один из значимых. Активное время, наполненное яркими событиями. Чем сильнее Солнце, тем значительнее эти события. Период благоприятен для продвижения по карьерной лестнице, политической, административной, организационной деятельности, смены работы на более статусную, часто является указанием к повышению или увеличению узнаваемости личного бренда.',
                        'Гармоничное Солнце будет помогать в развитии, подарит покровителей, обеспечит поддержку. Слабое Солнце может подарить возможности, но не дать их реализовать, злое Солнце - создать препятствия на пути к карьерному росту.',
                        'В женских гороскопах положение может указывать на значимые события в жизни мужа и его карьерные достижения.'
                    ]
                },
                11: {
                    title: 'Солнце в 11 доме Соляра',
                    description: [
                        'Темы года: дружеские контакты, изменение планов на жизнь, выход в онлайн.',
                        'Тоже одно из удачных положений. Период, когда человек способен прийти к своей мечте. Этот год дает много возможностей реализовать таланты в кругу друзей или общественных организациях. Аспектация и диспозитор Солнца укажут на характер планов и то, что будет помогать или мешать осуществить их.',
                        'При гармоничном положении новые проекты успешно реализуются. Можно рассчитывать на помощь друзей и единомышленников. Рекомендуется любая командная работа, организация группы (можно вступать в волонтерские программы, как вариант). Выход в онлайн, трансляция своих идей на широкую аудиторию.',
                        'При поражении Солнца возможны разногласия с друзьями и подпадание под их влияние. Планы могут быть под угрозой из-за самонадеянности или ожидания помощи со стороны. Часто цели в начале Соляра и в конце – совершенно разные из-за переосмысления планов в течение года.'
                    ]
                },
                12: {
                    title: 'Солнце в 12 доме Соляра',
                    description: [
                        'Темы года: уединение, самоанализ, рефлексия, творчество.',
                        'В этом году стоит остановиться и дать себе паузу, потому что проявлять себя и реализовываться вовне будет сложно. Лучше посвятить время глубокой работе над собой. Если вы работаете в сфере эзотерики или творчества, этот год может нести вам удивительные озарения.',
                        'Сильное Солнце приведет к просветлению, духовному росту, развитию интуиции, важным осознаниям.',
                        'Слабое - к погружению в депрессивные настроения, жалости к себе, ощущению усталости, апатии и желанию спрятаться от реальности.',
                        'В особо негативных ситуациях указывает на события, связанные с изоляцией. Не обязательно физической. Иногда человек просто оказывается «связан по рукам и ногам» в своих действиях без возможности что-либо изменить.'
                    ]
                },
            },
            moon: {
                1: {
                    title: 'Луна в 1 доме Соляра',
                    description: [
                        'Год знаменуется повышенной эмоциональностью. Следует обратить внимание на здоровье, эмоциональное и психическое состояние.',
                        'Эмоциональные качели в течение года. В этом году человек становится чувственнее, беспокойнее, более ранимым и впечатлительным. Хочется перемен, но не очень ясно, каких именно. В женском соляре может указывать на зачатие, беременность или роды, в мужском — знакомство с важной для него женщиной и/или большое влияние матери. Важными становятся дела дома, семьи, имущества и наследства.',
                        'При гармоничной Луне приходит популярность и репутация душевного человека.',
                        'При пораженной Луне человек становится капризным, тревожным и чрезмерно обидчивым.'
                    ]
                },
                2: {
                    title: 'Луна во 2 доме Соляра',
                    description: [
                        'Положение, указывающее на приливы и отливы в области финансов. Ситуация с деньгами в течение года может быть крайне нестабильна, а эмоциональный фон как раз привязан именно к этой сфере. Поэтому классическая рекомендация для такого периода - составить финансовый план, пересмотреть текущую ситуацию и создать подушку безопасности.',
                        'Если Луна слабая или битая, возможно ослабление финансового положения семьи, эпизодические поступления денег, непредвиденные расходы на дом или членов семьи. При особо негативном положении может стать указателем на кражи.',
                        'Сильная Луна с хорошими аспектами: прибавка к доходам, удачные покупки, благоприятные перемены в поступлении средств. Особенно за счет Лунной деятельности или товаров. На практике такая Луна показала себя крайне положительно у клиентки, которая занимается продажей посуды для дома (в натале у Луны очень сильное положение).'
                    ]
                },
                3: {
                    title: 'Луна в 3 доме Соляра',
                    description: [
                        'Положение несет изменение круга общения, эмоциональные переживания, связанные с близкими людьми.',
                        'Такое положение Луны может принести необходимость постоянно обмениваться информацией, мелкие проблемы с обучением, суету и беготню. Взгляды и интересы будут подвержены влиянию окружающих. Возможно посредничество или оформление документов на недвижимость. Также встречается как указание на рождение младших братьев/сестер или племянников.',
                        'В мужском соляре дает повышенное влияние женщин, а в женском — попадание в женский коллектив.',
                        'Негативно аспектированная Луна: может привести к обидам и выяснениям отношений, сплетням, оторванности от окружающих.'
                    ]
                },
                4: {
                    title: 'Луна в 4 доме Соляра',
                    description: [
                        'Указывает на некие перемены в теме недвижимости или семьи. Часто становится указанием к переезду (при дополнительных указателях) или временному пребыванию в другом доме. Натива тянет что-то изменить: сделать ремонт, обновить обстановку. Желание переехать тоже может быть исключительно эмоциональным, без каких-либо реальных действий.',
                        'На человека в этом году влияет обстановка в семье, дела, связанные с домом. Повышается влияние родителей. Человек переживает из-за имущества и отношений с родными. Может появиться интерес к истории рода или родного края.',
                        'Трактовка положения Луны сильно зависит от аспектации, может давать поиск душевного покоя, внутреннее беспокойство, а также неврозы и недомогания.',
                        'Самостоятельно на зачатие такое положение указывать не может, но при связях с V и I домом – является поддерживающим фактором.'
                    ]
                },
                5: {
                    title: 'Луна в 5 доме Соляра',
                    description: [
                        'Эмоциональная привязка и интерес к любовным связям, хобби и светской жизни.',
                        'Такое положение Луны усиливает чувственное восприятие и повышает потребность человека быть любимым, есть риск отправиться на поиски новых отношений, если в старых чего-то недостает. На практике крайне часто дает новые эмоции в любовной сфере, через новые романы (но важно смотреть на реальность человека и изначальное положение натала, есть ли такие предрасположенности). Человек сильнее погружается в дела своих детей. Возможно появление творческих хобби.',
                        'В женском соляре может означать зачатие ребенка (и это уже серьезный указатель, который может срабатывать даже самостоятельно).',
                        'Гармоничная Луна: популярность, активная светская жизнь, год, полный развлечений.',
                        'Пораженная Луна: потакание влечению, эгоизм в любви, случайные связи, плохая репутация, пренебрежение детьми.'
                    ]
                },
                6: {
                    title: 'Луна в 6 доме Соляра',
                    description: [
                        'Человека ждут изменения в условиях работы или отношениях с коллегами.',
                        'Такое положение Луны не слишком благоприятно для торговли, медицинской практики и деятельности в сфере туризма из-за перепада трудоспособности. Стоит обратить внимание на здоровье, особенно на питание и состояние желудка. Возможна хроническая усталость и активизация психосоматических заболеваний. В мужской карте может указывать на состояние здоровья значимой женщины.',
                        'Эмоции, связанные с рабочим коллективом, особенно, если он состоит из женщин.'
                    ]
                },
                7: {
                    title: 'Луна в 7 доме Соляра',
                    description: [
                        'Изменения, связанные с партнерами (супруги, бизнес компаньоны). Изменения в жизни натива из-за их действий или влияния.',
                        'Луна знаменует стремление влиться в общество и стать популярным. Ожидаются перемены в общественном положении. Положение обращает внимание на дела супруга.',
                        'В мужском соляре такое положение может указывать на влияние и поддержку от женщины, в женском — на зачатие или рождение ребёнка.',
                        'Позитивная аспектация Луны: удача в общественной деятельности, улучшение своего положения, влияние на других, взлет популярноости и значимости.',
                        'Пораженная Луна: кризис в отношениях с партнером, возможен развод или расторжение договоров.'
                    ]
                },
                8: {
                    title: 'Луна в 8 доме Соляра',
                    description: [
                        'Человека ждут форс-мажорные обстоятельства.',
                        'Такое положение Луны может давать эмоционально неспокойный год, ситуации, связанные с уплатой налогов, долгами, пошлинами. Возможно управление деньгами партнера по бизнесу или браку. Возрастает эротическое возбуждение, появляется желание получить от секса больше эмоций.',
                        'Луна с гармоничными аспектами: успех в бизнесе, удача в финансовых делах и в делах, касающихся совместного имущества, начало нового бизнеса или, что происходит чаще – благоприятные изменения в финансовых делах партнера. Получение наследства от рода, возможно конкретной женщины, если на то есть указания в радиксе или реальная ситуация этому не противоречит.',
                        'Негативная аспектация Луны: кризисные ситуации, нервозность, подозрительность, депрессия, мнительность, опасные моменты при вынашивании и родах.'
                    ]
                },
                9: {
                    title: 'Луна в 9 доме Соляра',
                    description: [
                        'Луна указывает на изменения, связанные с заграницей, мировоззрением или учебой.',
                        'Такое положение заставляет испытывать тягу ко всему неизведанному и к перемене места. Год благоприятен для расширения круга общения, туризма, эмиграции (при наличии дополнительных указаний), общения с иностранной публикой. Человек знакомится с новыми идеями и истинами, может изменить свою картину миру.',
                        'Повышается вдохновение, интуиция, способность к обучению, восприятию сложного материала.',
                        'Гармоничная Луна: путешествия, образование, связь с иностранными культурами.',
                        'Луна с негативными аспектами: неудобство в поездках, мечты о далеких краях и неудовлетворенность местом жительства.'
                    ]
                },
                10: {
                    title: 'Луна в 10 доме Соляра',
                    description: [
                        'Луна несет перемены в карьере и репутации в обществе',
                        'Человеку хочется получить оценку своих способностей, возрастают амбиции и желание признания. Сила или слабость Луны даст понять, насколько эти стремления будут успешны. Хорошее положение для политиков, артистов и тех, кто работает с населением, поскольку человек начинает чувствовать себя более уверенно. Возможно появление покровителя, особенно сильной женщины.',
                        'Гармоничная Луна: популярность, контакты, карьерный успех, удачные перемены в социальном статусе.',
                        'Дисгармоничные аспекты Луны: ненадежная поддержка сверху, перемены в отношениях с начальством, карьерный кризис. В мужской карте — проблемы со здоровьем родителей или жены, в женской — препятствие к браку.'
                    ]
                },
                11: {
                    title: 'Луна в 11 доме Соляра',
                    description: [
                        'Изменения в дружеском кругу и/или среди единомышленников.',
                        'Такое положение Луны дает человеку возможность найти уют в кругу своих друзей. На первый план выходит совместный досуг и отдых, построение планов на будущее. Дом, которым управляет Луна, расскажет, в какой сфере жизни строятся планы, а диспозитор Луны покажет мотивацию к планированию.',
                        'Круг друзей постоянно обновляется, в нем становится больше женщин. Семейная жизнь в этом году может отойти на второй план. Много внимания уделяется политике, есть шанс изменить свои взгляды.',
                        'Негативные аспекты Луны: измены, разочарование в друзья, крах планов и надежд.'
                    ]
                },
                12: {
                    title: 'Луна в 12 доме Соляра',
                    description: [
                        'Луна указывает на тревогу и глубокие внутренние переживания.',
                        'В минусе несет чувство одиночества и покинутости. В мужском соляре — возможны измены и разочарования от женщин, в женском — желание потакать своим желаниям и не думать о последствиях.',
                        'Области жизни, указанные домом, в котором находится Луна, могут быть вытеснены из поля зрения или могут принести проблемы. Натив может существенное время жить в уединенном месте или попасть в больницу.',
                        'Позитивная аспектация Луны: год благоприятен для духовных практик и упорного труда, обретения душевного покоя.',
                        'Негативная аспектация Луны: ощущение, что кругом враги, интриги или заговоры, подавленность, глубокая погруженность в себя и свои страхи.'
                    ]
                },
            },
            mercury: {
                1: {
                    title: 'Меркурий в 1 доме Соляра',
                    description: [
                        'На первый план выходят мысли о личных делах человека.',
                        'Возникает потребность в общении и обмене информацией, активизируется учеба. Есть желание строить планы и записывать свои мысли. Человек становится более любознательным, подвижным и активным. Трактовка Меркурия сильно зависит от его аспектов, поэтому их обязательно нужно учитывать при расшифровке соляра.',
                        'Гармоничный Меркурий: период благоприятен для учебы, торговли и меркурианской деятельности в целом.',
                        'Пораженный Меркурий: натив становится суетным, проявляются сложности в выражении своих мыслей, большое количество мелких дел.'
                    ]
                },
                2: {
                    title: 'Меркурий во 2 доме Соляра',
                    description: [
                        'Человека захватывают мысли о материальных вещах и способах заработка.',
                        'В этот период благоприятно формировать доход от меркурианской деятельности (посредничество, литература, работа с информацией, репетиторство). Вероятно появление нескольких источников заработка. Человек часто говорит о вещах и деньгах, в решение финансовых вопросов могут вмешиваться родственники или знакомые.',
                        'Позитивные аспекты Меркурия: получение хорошей прибыли.',
                        'Поражённый Меркурий: потери или кража денег, расходы по мелочам и беспорядок в тратах.'
                    ]
                },
                3: {
                    title: 'Меркурий в 3 доме Соляра',
                    description: [
                        'Чаще всего мысли человека вращаются вокруг получения новой информации и/или дел родни и ближайшего круга.',
                        'Активизируются новые знакомства, участие в обсуждениях и форумах. Подходящий период для сбора информации, работы с документами, установления нужных контактов, учебы и поступления. Может дать огромное количество бумажной работы.',
                        'Сильный гармоничный Меркурий: улучшается память, человек быстро обрабатывает информацию, учеба дается легко.',
                        'Пораженный Меркурий: суета, человек забывает о делах и обещаниях.'
                    ]
                },
                4: {
                    title: 'Меркурий в 4 доме Соляра',
                    description: [
                        'Указание на возможный временный переезд или постоянную необходимость мотаться туда-сюда (дом/дача, свой дом/дом партнера и другие варианты). В этом году человека ждет плотное общение с семьей, визиты родных, много разговоров о семейных делах. Может указывать на возобновление обучения или получение дополнительного образования.',
                        'Много интеллектуальной деятельности дома, можно получать образование или работать из дома.',
                        'Пораженный Меркурий: сплетни, ссоры, прерывание связей с семьей.'
                    ]
                },
                5: {
                    title: 'Меркурий в 5 доме Соляра',
                    description: [
                        'Мысли вокруг романтических отношений, хобби и творчества.',
                        'В этот период человек много анализирует свои чувства и отношения, обсуждает их с другими. Год благоприятен погружения в свои интересы и хобби. Часто несет ситуации разрешения проблем ребенка, глубокого ментального погружения в его дела, интеллектуальную помощь детям.',
                        'Добрый Меркурий: флирт, романтические переписки, интеллектуальные развлечения, публичные выступления приносят удовольствие.',
                        'Злой Меркурий: ссоры, резкое обращение с любимыми людьми, желание спорить и одерживать верх.'
                    ]
                },
                6: {
                    title: 'Меркурий в 6 доме Соляра',
                    description: [
                        'Человека ждет обсуждение рабочих обязанностей, помощь людям.',
                        'Меркурий находится в своем доме и добавляет обстоятельности и методичности мышлению. На работе возможно небольшое повышение или выполнение новых поручений, возникает необходимость в общении с подчиненными и коллегами, рабочих поездках, оформлении документации. Суета на работе.',
                        'Время, благоприятное для медицинской, торговой, посреднической деятельности, оказания услуг населению, преподавания.',
                        'Гармоничный Меркурий: продуктивное общение с коллегами, быстрое решение всех дел на работе, удачное оформление служебных бумаг.',
                        'Пораженный Меркурий: нервное переутомление, сложно выражать свои мысли, обилие дел и забот, бесконечная бумажная рутина.'
                    ]
                },
                7: {
                    title: 'Меркурий в 7 доме Соляра',
                    description: [
                        'Меркурий несет с собой обсуждение партнерских отношений и размышления вокруг этой темы.',
                        'В этом году человек осмысливает человеческие отношения, вовлекается в дела партнера по бизнесу или супруга. Важную роль играют советы окружающих, может наблюдаться зависимость от мнениях других.',
                        'Благоприятный период для сотрудничества и соавторства, работы в паре. Может указывать на защиту диссертации или сдачу экзамена, выступления на радио или ТВ, удачную трансляцию своих идей публике, расширение консультационной деятельности, участие в интеллектуальных конкурсах.',
                        'Пораженный Меркурий: ссоры с супругом или партнерами, обманы, неразборчивость в связях и подписание неудачных договоров.'
                    ]
                },
                8: {
                    title: 'Меркурий в 8 доме Соляра',
                    description: [
                        'В этом году человека ждут кризисы в учёбе, интеллектуальной деятельности, общении.',
                        'Натив может вовлекаться в ссоры, в жизни возникает напряжение или ощущается давление со стороны окружающих. Может указывать на изменения в характере обучения, направления или смену учебной дисциплины (как проявление трансформации по меркурию).',
                        'Возможны контакты с финансовыми и административными органами, решение вопросов, связанных с наследством или чужими деньгами, переосмысление своего стиля общения с людьми, поездки, сопряженные со стрессом и темами восьмого дома.'
                    ]
                },
                9: {
                    title: 'Меркурий в 9 доме Соляра',
                    description: [
                        'Такой Меркурий побуждает раздвигать интеллектуальные горизонты, интересоваться новыми сферами, учить языки. Возникает желание общаться с иностранцами, учеными. Может указывать на краткосрочные деловые поездки. Возможен выход на зарубежный рынок или обсуждение торговых интересов за границей.',
                        'Год благоприятен для исследований, преподавания, распространения своих идей, поступления в аспирантуру, получения дополнительных знаний, публикаций в СМИ, издательской работы. Прекрасное время для получения серьезного образования или сдачи экзамена ради важного сетрификата/диплома',
                        'Гармоничный Меркурий: можно запускать рекламу, заявлять о себе, повышать квалификацию.',
                        'Пораженный Меркурий: препятствия во всех сферах девятого дома.'
                    ]
                },
                10: {
                    title: 'Меркурий в 10 доме Соляра',
                    description: [
                        'Человек много размышляет о своих текущих целях, возможны перемены в занимаемой должности или смена руководства. Начинает волновать репутация и мнение начальства, новости и политика. Происходит обмен информацией и общение с вышестоящими по должности людьми. Активный обмен деловой документацией «сверху – вниз» и обратно.',
                        'В женском соляре может указывать на дела супруга.',
                        'Негативные аспекты Меркурия: необдуманные слова и действия, которые наносят вред карьере. Нужно тщательно следить за документами и поступающей информацией.'
                    ]
                },
                11: {
                    title: 'Меркурий в 11 доме Соляра',
                    description: [
                        'Активизируется общение с друзьями и единомышленниками, строятся новые планы и оформляются новые мечты.',
                        'В этом периоде расширяется круг общения, появляются совместные проекты. Появляется желание проходить обучения, связанные с хобби, идеи быстро осуществляются при помощи единомышленников.',
                        'Сильный Меркурий: человек генерирует идеи, заводит друзей в процессе переписки или поездок',
                        'Пораженный Меркурий: ссоры с друзьями, потеря симпатии и интереса, совместные планы не осуществляются'
                    ]
                },
                12: {
                    title: 'Меркурий в 12 доме Соляра',
                    description: [
                        'Год знаменуется скрытностью, погружением в свои мысли.',
                        'В этот период человек настолько увлечен своим внутренним миром, что у него практически нет потребности в общении. Год хорош для самоанализа, литературной деятельности, поездок в одиночестве. Возможны переживания из-за разговоров, стиля общения, неудачных встреч, трудности в выражении своих мыслей.',
                        'Период не очень благоприятен для журналистики и преподавания.',
                        'Позитивные аспекты Меркурия: благоприятны исследования, скрытая интеллектуальная деятельность.',
                        'Негативные аспекты Меркурия: скрытность, меланхолия, лень.'
                    ]
                },
            },
            venus: {
                1: {
                    title: 'Венера в 1 доме Соляра',
                    description: [
                        'Венера в первом доме проявит в человеке обаяние, шарм. Этот год очень важен в плане реализации инициатив человека. Он даже начинает выглядеть иначе: находится в приподнятом настроении, выглядит более привлекательным, чаще веселится и больше интересуется своим имиджем. Женщинам полезно добавлять компенсаторику по Венере в течение такого года – развивать нужные качества будет легче.',
                        'Венера благоприятствует сферам того дома, управителем которого она является. В этом году личные контакты станут важными для будущего человека.',
                        'Гармоничная Венера: приподнятое настроение, везение в любви, благосклонность окружающих.',
                        'Пораженная Венера: проблемы в отношениях, капризность. Стремление к лени, перееданию, может сказаться на здоровье, в особенности через проблемы с кожей, гормонами и лишним весом.'
                    ]
                },
                2: {
                    title: 'Венера во 2 доме Соляра',
                    description: [
                        'Благоприятное положение Венеры, часто приносит улучшение в финансах и гармонизацию этой сферы.',
                        'Венера обычно провоцирует (но и дает возможности) купить что-то шикарное. Может означать первый заработок в жизни, если соляр строится для молодого человека. Важно накопить некоторую денежную массу, а не растранжирить все, что пришло.',
                        'Положение особенно благоприятно для работников венерианских сфер, банкиров и инвесторов. Для мужчины может указывать на траты на женщин, в случае негативных аспектов – излишние или неоправданные.',
                        'Пораженная Венера: стремление пускать деньги на ветер, траты на роскошные вещи, переедание.'
                    ]
                },
                3: {
                    title: 'Венера в 3 доме Соляра',
                    description: [
                        'В этот период у человека вероятны приятные знакомства и контакты. Может указывать на начало отношений на расстояния или связи в поездках, а также на отношения с человеком младше натива по возрасту. Благоприятный указатель для гармонизации отношений с ближайшим кругом (братья/сестры, родственники). Приятное окружение в течение года будет давать поддержку и энергетически подпитывать натива, в связи с чем рекомендуется инициировать контакты с интересными людьми.',
                        'Год хорош для тех, кто занимается журналистикой, литературой или посредничеством. Благоприятная среда на учебе, особенно хороши курсы по тематике Венеры.',
                        'Гармоничное положение: интересные знакомства и приятная компания, успех в любви.',
                        'Напряженное положение: льстецы в окружении, беспорядочные связи.'
                    ]
                },
                4: {
                    title: 'Венера в 4 доме Соляра',
                    description: [
                        'В этот период человеку больше всего хочется проводить время дома, там складывается самая уютная атмосфера, налаживаются отношения с семьей.',
                        'Актуальна тема недвижимости: может появиться желание сделать ремонт, навести уют, украсить жилье или купить квартиру/дачу/участок. Обычно при таком положении Венеры и при отсутствии других негативных указателей сделки по недвижимости проходят гармонично.',
                        'Может указывать на пополнение в семье, а в соляре одинокого мужчины — на появление женщины в доме, а также на благоприятное окончание какого-либо жизненного цикла (по какой сфере подскажет натальный дом).',
                        'Негативные аспекты Венеры: лень, предпочтение комфорта всему, человек «счастлив» только дома.'
                    ]
                },
                5: {
                    title: 'Венера в 5 доме Соляра',
                    description: [
                        'Венера указывает на новые любовные отношения или развитие существующих. При дополнительных указаниях такое положение Венеры может нести помолвку или рождение/зачатие ребенка. Благоприятный период для общения с детьми, творчества, спорта, хобби.',
                        'Период подходит для приобретения популярности и выхода в свет, возможно спонсорство и поддержка от влиятельных людей.',
                        'Пораженная Венера: расставание, разочарования, пристрастие к азартным играм и закономерные проигрыши.'
                    ]
                },
                6: {
                    title: 'Венера в 6 доме Соляра',
                    description: [
                        'Венера призывает обратить внимание на отношения с коллегами и обстановка на работе. Может указывать на небольшое повышение в должности и благоприятную обстановку на работе, возможен служебный роман. Гармонизация отношений с коллегами, улучшение здоровья, режима питания и отдыха человека.',
                        'Хорошо аспектированная Венера: повышение дохода, привлечение деловых партнеров.',
                        'Негативно аспектированная Венера: недовольство работой и оплатой труда, может пострадать здоровье, особенно почки и мочеполовая система.'
                    ]
                },
                7: {
                    title: 'Венера в 7 доме Соляра',
                    description: [
                        'В этом году будет важна гармонизация отношений с окружающими. Это дом Венеры и, попадая в него, она дарит возможность завоевать симпатии людей. Человек начинает уделять больше внимания общественной деятельности, становится социально активным. В этом году можно привлечь новых союзников и деловых партнеров, а также создать себе отличную репутацию. Могут завязаться серьезные личные отношения.',
                        'Позитивные аспекты Венеры: год брака, развития отношений, романтики и приятных деловых связей.',
                        'Негативные аспекты Венеры: неверность, неисполнение обязательств, использования обаяния в личных целях.',

                    ]
                },
                8: {
                    title: 'Венера в 8 доме Соляра',
                    description: [
                        'Человека ждут изменения/трансформации в любовной сфере и (в лучшем случае) возможность заработать. В этом году может наступить кризисный период в отношениях: сомнения, ревность, кризисы. Они могут привести или к переходу отношений на новый уровень, или к разрыву. Возможны проверки финансовых организаций, получение небольшого наследства. Год благоприятен для размещения капитала, раздела доходов.',
                        'Гармоничная Венера: прибыль от вкладов, инвестиций или операций с чужими деньгами. Возможно получение гранта или поддержки.',
                        'Напряженная Венера: измены, рискованные финансовые операции, переживания из-за отношений.'
                    ]
                },
                9: {
                    title: 'Венера в 9 доме Соляра',
                    description: [
                        'Этот год хорош для стажировки и повышения квалификации, интеллектуальной деятельности. Будут расширяться связи с иностранными гражданами или компаниями. Человек стремится путешествовать. Может дать любовную связь с человеком издалека (или просто с человеком не своего круга).',
                        'Гармоничная Венера: успех в сферах преподавания, издания книг, выхода на зарубежный рынок.',
                        'Пораженная Венера: напрасные надежды, несостоявшиеся путешествия, идеализация любовных отношений.'
                    ]
                },
                10: {
                    title: 'Венера в 10 доме Соляра',
                    description: [
                        'В этом году человек может продвинуться по карьерной лестнице с помощью своего обаяния и манер. Успех может зависеть от отношения начальства или влиятельных людей. Может указывать на появление покровителя. Для женщины такое положение может указывать на встречу с будущим мужем.',
                        'Гармоничная Венера: отличные отношения с начальством, хорошая репутация, успехи в профессии.',
                        'Негативные аспекты Венеры: потеря репутации, скандал, увольнение, отсрочка брака.'
                    ]
                },
                11: {
                    title: 'Венера в 11 доме Соляра',
                    description: [
                        'В этот период человек проводит много времени с друзьями, завязывает новые знакомства, дружит со знакомыми супруга или супруги. Кроме того, это год осуществления мечты и реализации проектов.',
                        'Реализуются планы домов, которыми руководит Венера в соляре, а также дома в радиксе, в который попадает куспид одиннадцатого солярного дома. Человек может открыть в себе новые таланты и получить помощь от женщин.',
                        'Напряженные аспекты Венеры: снижение шансов на реализацию планов, склонность к заблуждениям, обман со стороны друзей, разочарование.'
                    ]
                },
                12: {
                    title: 'Венера в 12 доме Соляра',
                    description: [
                        'В этом году большинство планов, особенно связанных с домами Венеры, не осуществятся. Есть риск остаться в одиночестве (возможно просто на уровне ощущений) или погрязнуть в воспоминаниях о прошлых отношениях.',
                        'Натив отстраняется, избегает общения, больше замыкается в себе. Позиция благоприятна для индивидуальной работы, размышлений, венерианских духовных практик.',
                        'Гармоничная Венера частично сглаживает негативные эффекты такого положения, может указывать на время творческих прорывов.',
                        'Пораженная Венера: вынужденное одиночество, тайные связи, огорчения и разочарования.'
                    ]
                },
            },
            mars: {
                1: {
                    title: 'Марс в 1 доме Соляра',
                    description: [
                        'Такой Марс приносит даже самому спокойному человеку желание действовать, достигать, делать, а также спорить, соревноваться или вмешиваться в чужие ситуации. Усиливается сексуальность, человек может стать чрезмерно напористым и действовать по первому побуждению. Период благоприятен для спорта (стоит дать такую рекомендацию), работы в армии или полиции. Возможно физическое переутомление. В любом случае год активных действий, энергию нужно расходовать!',
                        'Гармоничный Марс: сильные лидерские качества.',
                        'Напряженный Марс: повышенная агрессия'
                    ]
                },
                2: {
                    title: 'Марс во 2 доме Соляра',
                    description: [
                        'Марс – планета трат, когда мы смотрим на нее в контексте второго дома. Деньги «горят» в руках. Такой Марс может давать безрассудность и полное отсутствие финансового планирования: человек не думает о будущем и финансовых обязательствах. Поэтому год может привести к кризису бюджета. Иногда на статьи расходов может указывать натальный дом, в который проецируется солярный Марс. Дает возможность приложить усилия для заработка, вообще говорит о том, что зона денег требует наших активных действий.',
                        'Гармоничный Марс: хорошее время для основания бизнеса (все стартапы требуют усилий и активности) и заработков через личную активность.',
                        'Напряженный Марс: растраты, конфликты на почве денег.'
                    ]
                },
                3: {
                    title: 'Марс в 3 доме Соляра',
                    description: [
                        'Такой Марс может вызвать разногласия с нынешним кругом общения, но подарить много новых знакомств (преимущественно тех, что мы инициируем сами) и коротких поездок. Повышается интеллектуальная активность, благоприятно начинать интенсивные обучения. Много энергии направлено на поддержание связей, переписку, учебу. Хорошее время для агрессивных рекламных кампаний.',
                        'Гармоничный Марс активирует все наши контакты, может дать знакомство с мужчиной (для женских Соляров).',
                        'Негативные аспекты Марса могут дать необдуманные поступки, неудачные поездки, ссоры с родственниками (особенно братьями и сестрами), ситуации угрожающие личному транспорту.'
                    ]
                },
                4: {
                    title: 'Марс во IV доме Соляра',
                    description: [
                        'Человек в этом году будет проявлять активность в имущественных вопросах. Такой Марс сулит ремонт, строительство или покупку участка. Может дать разлады в семье с возможным переездом. Иногда такая позиция встречается в годы рождения или зачатия детей. Для компенсации положения можно рекомендовать ручной труд в собственном доме. Самостоятельно что-то починить или отремонтировать, например.',
                        'Гармоничный Марс: натив черпает силу и мотивацию в собственной семье.',
                        'Напряженный Марс: угроза сохранности имущества. Также необходимо следить за здоровьем (особенно это касается желудка), угроза хорошим отношениям в семье.'
                    ]
                },
                5: {
                    title: 'Марс во V доме Соляра',
                    description: [
                        'В этом году активизируется эмоциональная и творческая жизнь.',
                        'Период, когда у человека активизируются чувственные и интимные связи: бурные ссоры и бурные примирения, частые влюбленности. В солярах людей обоих полов позиция может указывать на возможность зачатия и рождения ребенка. В женских Солярах – роман с мужчиной. Кроме того, это положение может указывать на склонность к азартным играм, развлечениям, спорту, неожиданным поступкам. Человек стремится самовыражаться через хобби и творчество, ведет активную светскую жизнь. Возможны конфликты с детьми, споры.'
                    ]
                },
                6: {
                    title: 'Марс во VI доме Соляра',
                    description: [
                        'Марс предсказывает активность в профессиональной деятельности, по большей части в рутине. В этот период работа будет требовать больше сил и энергии, чем обычно, возможен поиск работы, напряжение отношений с коллегами. Может указывать на неудовлетворенность местом работы и нежеланием работать вообще, состояние несвободы',
                        'Год благоприятен для физической работы, работы с инструментами и механизмами, медицины, инженерного и военного дела. Стоит обратить внимание на здоровье, возможны ожоги или воспалительные процессы.',
                        'Злой или пораженный Марс: конфликты на работе, ссоры с коллегами и подчиненными, увольнение. Не рекомендуется планировать медицинские операции.'
                    ]
                },
                7: {
                    title: 'Марс во VII доме Соляра',
                    description: [
                        'Марс обещает активность в вопросах партнерства.',
                        'Человек начинает активнее себя проявлять на публике, создавать новые связи, участвовать в делах партнеров. Год благоприятен для заключения договоров и подписания контрактов, активного (!) консультирования, рекламы себя и своих услуг. Полезно широко и громко заявлять о себе.',
                        'Может указывать на зависимость деятельности натива от других людей, на ссоры с партнерами и конфликтные ситуации. Возможно начало судебного разбирательства.',
                        'Негативные аспекты Марса: разрыв договоров или развод, падение популярности или скандальная популярность.'
                    ]
                },
                8: {
                    title: 'Марс во VIII доме Соляра',
                    description: [
                        'Год активизирует сексуальную жизнь человека.',
                        'В этот период у человека часто возникают новые интимные связи, но возможны и ожесточенные конфликты с партнерами. Если у Марса есть аспекты с Венерой, Солнцем или Юпитером, обаяние и привлекательность повышаются (как и либидо). В финансовой сфере такой Марс может указывать на увеличение расходов, разбирательства с налоговой или наследством, пользование деньгами других людей. На денежные вопросы, а также на разрешение споров, придется потратить много энергии.',
                        'Пораженный Марс увеличивает риск несчастных случаев, аварий. Лучше всего не заниматься вопросами денег в этом году, не брать кредиты, не иметь дела с большими суммами денег.'
                    ]
                },
                9: {
                    title: 'Марс во IX доме Соляра',
                    description: [
                        'В этом году усиливается связь с другими странами: дела выходят на заграничный рынок, активные контакты с иностранцами, зарубежные командировки. Для женщин возможны отношения с мужчиной из другой страны. Возрастает интерес к религии, философии и литературе, желание учиться и расширять горизонты. Активно расширяется сфера жизни, которую описывает натальный дом проекции Марса. Много сил потребует начатое обучение.',
                        'Пораженный Марс: идеологические споры, неприятности в поездках, конфликты на учебе, разрыв связей с иностранцами.',
                    ]
                },
                10: {
                    title: 'Марс во X доме Соляра',
                    description: [
                        'На первый план выйдут карьерные достижения, реализация планов. Именно на эту сферу придется потратить много сил.',
                        'Такой Марс стимулирует человека вести себя целеустремленно (если Марс не слабый) и достигать поставленных целей. При приложенных усилиях может измениться социальный статус человека. Если женщина ставила целью замужество, оно может произойти в этот период, если на ито есть дополнительные указания.',
                        'Слабый Марс (не проявлен в натале и жизни человека): небрежность безынициативность и множество упущенных карьерных возможностей.',
                        'Пораженный Марс: потеря авторитета, неблагоприятные перемены на работе, агрессия в достижении целей, споры с начальством.'
                    ]
                },
                11: {
                    title: 'Марс во XI доме Соляра',
                    description: [
                        'В этом году какие-то друзья активно проявляются в жизни человека и участвуют в его делах, а с другими может произойти полный разрыв отношений. Кроме того, появляются новые друзья и знакомые. Для женщин возможно появление интересного мужчины в кругу друзей.',
                        'Может указывать на смену планов и активное участие в волонтерских организациях, в долгосрочной перспективе такой год будет ощущаться как важный и судьбоносный.',
                        'Негативные аспекты Марса: дружеские отношения не складываются, планы оказываются поспешными, симпатии могут быть потеряны. Ссоры с друзьями.'
                    ]
                },
                12: {
                    title: 'Марс во XII доме Соляра',
                    description: [
                        'В этом году человека ждут проблемы с проявлением себя',
                        'В такой период не хочется активной деятельности, возникает желание отложить все на потом. Если идти за апатией, то получить негативную обратную связь от начальства и коллег.',
                        'Человек страдает от недостатка энергии и стремится к уединению. Я называю такой период «проснулся и уже устал». Физическая энергия обращена вовнутрь, отчего ей сложно распоряжаться вовне. Могут появиться болезни, страхи и неврозы. Если хорошо развита интуиция, есть шанс сделать то, что нужно, и в правильное время.',
                        'Проработка такого положения – любая физическая активность, которая соответствует двенадцатому дому (бассейн, йога).'
                    ]
                },
            },
            jupiter: {
                1: {
                    title: 'Юпитер в 1 доме Соляра',
                    description: [
                        'Юпитер подарит возможности для самореализации человека.',
                        'Легче всего развитие в этот период будет в доме, на куспиде которого находится Стрелец, а также в доме соляра, в который Юпитер дает аспекты. У человека появляется оптимизм, начинается новый период в жизни, есть желание развиваться. Такой Юпитер обещает помощь от окружающих и дает возможность личного расширения: свой бизнес, ребёнок, увеличение заработка, значимости и так далее. Человек с радостью делится своим опытом, а окружающие считают его умным и дружелюбным. Благоприятный период для юристов, преподавателей и начала нового обучения.',
                        'Слабый или пораженный Юпитер: трудности в реализации, переедание и набор веса, пустые слова, хвастовство и переоценка своих возможностей.'
                    ]
                },
                2: {
                    title: 'Юпитер во 2 доме Соляра',
                    description: [
                        'Юпитер подарит возможности финансового роста.',
                        'Указывает на появление новых источников дохода, увеличение доходов от области натального дома, в который попал Юпитер. Для молодых людей может значить начало самостоятельного заработка. Может давать крупные покупки и траты на благотворительность. Благоприятный период для инвестирования, развития своих проектов, запуска новых продуктов, направленных на монетизацию деятельности.',
                        'Пораженный Юпитер: трудности в достижении желаемого уровня дохода, непредвиденные расходы, недовольство своим финансовым положением.'
                    ]
                },
                3: {
                    title: 'Юпитер в 3 доме Соляра',
                    description: [
                        'Юпитер подарит возможности для общения и обучения.',
                        'В такой период резко растет коммуникабельность, появляются новые связи и знакомства. Становятся важными сферы обучения, посредничества и интеллектуального труда. Такой Юпитер дает возможность подписать выгодные соглашения, получить помощь окружения. Хочется повышать квалификацию, учить языки и узнавать новое. Возможны многочисленные поездки и перемещения, подписание важных бумаг.',
                        'Пораженный Юпитер: неприятности в поездках, такое количество обещаний по общению и обучению, которое невозможно выполнить.'
                    ]
                },
                4: {
                    title: 'Юпитер в 4 доме Соляра',
                    description: [
                        'Юпитер подарит расширение перспектив, связанных с делами дома и семьи.',
                        'Такой период обещает ремонт, объединение/расширение квартир, хлопоты по дому и много общения с родственниками. Возможна покупка недвижимости или прибыль в результате операций с имуществом. Можно рассчитывать на помощь родителей и других родственников.',
                        'Человек крепко стоит на ногах и может получить кредит на покупку жилья или основание семейного бизнеса. Благоприятный период для занятий философией и религией, изучения семейных традиций и истории рода.',
                        'Пораженный Юпитер: трудность с недвижимостью, семейные проблемы, слишком много общения с родными, юридические вопросы, связанные с недвижимостью.'
                    ]
                },
                5: {
                    title: 'Юпитер в 5 доме Соляра',
                    description: [
                        'Юпитер подарит возможности в сфере светской жизни и романтических связей. Такой Юпитер побуждает участвовать в вечеринках, флиртовать, развлекаться и получать удовольствие. У человека много планов, желание реализовать себя в творчестве',
                        'Появляется возможность получить помощь от поклонников и покровителей, удачно сыграть на бирже или в сфере азартных игр. Может указывать на события в жизни детей (указание на то, что будет повод гордиться собственным чадом). Один из показателей к рождению/зачатию ребенка.',
                        'Негативные аспекты Юпитера: финансовая неустойчивость, чрезмерное увлечение удовольствиями, показная щедрость.'
                    ]
                },
                6: {
                    title: 'Юпитер в 6 доме Соляра',
                    description: [
                        'Юпитер подарит возможность расширения на работе.',
                        'В такой период обычно расширяется фронт работ, возможно совместительство. Работа дается легко. Год благоприятен для медицинской деятельности, торговли, лечения, бухгалтерской деятельности. Иногда встречается и обратная ситуация: расширяется количество свободного времени от работы, если на то была изначальная тенденция.',
                        'Гармоничный Юпитер: человек гордится своей работой, учит других, работа приносит доход и новый функционал. У предпринимателей может указывать на расширение штата сотрудников.',
                        'Пораженный Юпитер: мнительность, переедание, чрезмерная забота о здоровье, перегруз обязанностями на работе.'
                    ]
                },
                7: {
                    title: 'Юпитер в 7 доме Соляра',
                    description: [
                        'Юпитер подарит возможности для расширения сотрудничества.',
                        'Период бурной социальной жизни, полезных связей, удачного партнерства. Благоприятный год для консультирования, политической карьеры и преподавания. В целом для любой деятельности, где нужно быть на виду и/или в контакте с другими людьми.',
                        'Юпитер дает возможности для рекламы, привлечения новых крупных клиентов, участия в общественных мероприятиях. Человек становится популярен, к нему относятся с симпатией, ему доверяют. При подтверждении другими показателями удачный год для вступления в брак или год, когда можно встретить значимого человека.',
                        'Негативные аспекты Юпитера: правовые споры, завышенные ожидания от партнеров.'
                    ]
                },
                8: {
                    title: 'Юпитер в 8 доме Соляра',
                    description: [
                        'Юпитер подарит масштабные перемены в жизни. Этот год обещает крупные изменения в сфере жизни, на которую указывает проекция Юпитера в тот или иной дом радикса. Может полностью поменяться мировоззрение, смениться сексуальный партнер, часть отношений будет разорвана. Такой Юпитер дает приток денег от сексуальных партнеров, дивиденды, наследство или возвращение долгов (в моем собственном примере проигрался как подарок существенной суммы денег от родителей).',
                        'Пораженный Юпитер: неприятности с проверяющими организациями, увеличение долгов, чрезмерное пользование чужими деньгами, незаконные финансовые операции.',
                    ]
                },
                9: {
                    title: 'Юпитер в 9 доме Соляра',
                    description: [
                        'Юпитер подарит возможности для развития связей с заграницей, а также преподавательской деятельности.',
                        'Такой Юпитер обещает экспансию во всех интеллектуальных областях: преподавание, агитация, стажировки, исследования, деловые связи, меценатство, получение образования. Повышается интерес к культурам других народов, к религии и философии. Год благоприятен и для получения второго образования, для создания репутации и повышения авторитета. Возможна успешная деятельность за границей.',
                        'Слабый Юпитер: ложные надежды, неосуществленные планы, недостаточно умений, чтобы их реализовать.',
                        'Пораженный Юпитер: неприятности в поездках, чрезмерная вера в себя.'
                    ]
                },
                10: {
                    title: 'Юпитер в 10 доме Соляра',
                    description: [
                        'Юпитер подарит расширение в профессиональной деятельности.',
                        'Такой Юпитер указывает на получение высшего образования (как новый статус), на первые шаги в карьере или начало профессиональной деятельности. Человек получается возможность повышения в должности.',
                        'Может указывать на замужество у женщины в соляре. Удачный год для получения гражданства (тоже новый статус), смены работы на ту, которая даст продвижение по социальной лестнице.',
                        'Негативные аспекты Юпитера: хлопоты, конфликты с начальством, затруднения в делах десятого дома.'
                    ]
                },
                11: {
                    title: 'Юпитер в 11 доме Соляра',
                    description: [
                        'Юпитер подарит расширение круга связей и поддержку окружающих.',
                        'Такой Юпитер дарит помощь от друзей и соратников. Увеличивается количество связей, человек обретает симпатии и поддержку. Может указывать на вступление в коллектив, партию, команду. Всегда найдутся люди, которые помогут и даже профинансируют.',
                        'Удачный период для построения планов на много лет вперед и работы с массами: реклама, политика, преподавание.',
                        'Негативные аспекты Юпитера: друзья становятся помехой планам, поддержки нет, споры с единомышленниками.'
                    ]
                },
                12: {
                    title: 'Юпитер в 12 доме Соляра',
                    description: [
                        'Человека ждет внутренняя работа и скрытая деятельность.',
                        'В этом году человеку интереснее наедине с собой, период отлично подходит для работы над собой, установками и подсознанием. Может указывать на помощь от тайных лиц и неожиданную удачу. Может говорить о тайной деятельности, особенно в сферах домов, к которым имеет отношение Юпитер.',
                        'Пораженный Юпитер: стремление уехать в другую страну и разорвать все связи, разлука, болезненность.',
                    ]
                },
            },
            saturn: {
                1: {
                    title: 'Сатурн в 1 доме Соляра',
                    description: [
                        'Сатурн указывает на некоторые ограничения в действиях, большое количество ответственности.',
                        'В такой период на человека сыплются всевозможные обязательства, кажется, что времени на себя вообще нет. Все действия как будто идут через сопротивление и даются тяжело. Человек может воспринимать этот год как полосу невезения или препятствий (на деле необходимо демонстрировать собранность и дисциплину). Уровень энергии снижен, приходится контролировать себя и свое время. Ситуации из прошлых лет, которые не были проработаны, всплывают снова и вызывают проблемы',
                        'Негативные аспекты Сатурна: урон в области, указанной натальным домом, в который попал Сатурн.'
                    ]
                },
                2: {
                    title: 'Сатурн во 2 доме Соляра',
                    description: [
                        'Сатурн указывает на ограничения или стабилизацию в финансовой сфере. Также может выступать указателем на узаконивание доходов.',
                        'Тема денег требует вовлеченности и дисциплины. В этот период человеку приходится экономить, отказывать себе в чем-то. Может указывать на крупную покупку, на которую придется копить, или просто на сложности с зарабатыванием денег. Проблемы с получением средств из банка, задержки. Год неблагоприятен для новых проектов (если они не относятся к сатурнианской сфере). Недостаток энергии, апатия, нет сил на творчество.',
                        'Негативные аспекты Сатурна: финансовые потери, усталость, потеря прибыли или ее снижение'
                    ]
                },
                3: {
                    title: 'Сатурн в 3 доме Соляра',
                    description: [
                        'Сатурн указывает на ограничения в сфере общения или на то, что контакты становятся преимущественно деловыми и/или официальными.',
                        'Такой Сатурн может указывать на охлаждение отношений в дружеском кругу или с братьями/сестрами. Человеку не хочется общаться, он ощущает скованность. С другой стороны, с теми, кто остался в круге общения, отношения становятся более зрелыми и крепкими. Как правило, контакты осуществляются только с теми, кто «проверен временем». Возникают проблемы с документами, учеба в этом году дается тяжело. Необходимо быть осторожным в поездках.',
                        'Гармоничный Сатурн: хороший период для научной работы и задач, требующих усидчивости.',
                        'Пораженный Сатурн: сложности и затруднения в коммуникациях.'
                    ]
                },
                4: {
                    title: 'Сатурн в 4 доме Соляра',
                    description: [
                        'В этом году могут происходить конфликты в семье, разрыв отношений с родителями или начало жизни отдельно от них. При напряженном Сатурне отношения в семье далеки от теплых. Возникают проблемы в решении жилищных вопросов, будь то сделка или ремонт (Сатурн обычно затягивает решение каких-либо вопросов по этим темам). Домашние и хозяйственные дела отвлекают человека от работы.',
                        'Неблагоприятный период для политиков и крупных руководителей.',
                        'Негативные аспекты Сатурна: возможность несчастного случая или нападения, потеря урожая.'
                    ]
                },
                5: {
                    title: 'Сатурн в 5 доме Соляра',
                    description: [
                        'Сатурн указывает на напряжение в сфере романтических отношений.',
                        'Такой Сатурн проверяет любовные связи трудностями или расстоянием. Человек может быть недоволен отношениями или устать от них, поэтому лучше соблюдать некоторую дистанцию. В этот период хочется заняться творчеством, но возникают проблемы с самореализацией. Отношения с детьми могут становиться хуже, так как дети начинают выстраивать собственные границы. В женской карте может давать трудности с зачатием ребенка.',
                        'Негативные аспекты Сатурна: неблагоприятный период для тем пятого дома.'
                    ]
                },
                6: {
                    title: 'Сатурн в 6 доме Соляра',
                    description: [
                        'Сатурн указывает на ограничения, связанные с работой.',
                        'В этот период человек недоволен условиями на работе, обязанностей становится слишком много, отношения с коллегами и начальством не устраивают. Повышение в этом году затруднено. Здоровье может ухудшиться из-за перенапряжения или охлаждения. Также часто проявляют себя хронические заболевания и, если на то есть указания в радиксе, проблемы с кальцием в организме (зубы/кости). Для предпринимателей может обозначать потерю какого-то важного кадра, а также процесс жесткого выстраивания работы, документирование обязанностей сотрудников и подобное. В любом случае это год, требующий полного вовлечения и самодисциплины на рабочем месте.',
                        'Гармоничный Сатурн: повышение возможно, но обязанностей еще добавится.'
                    ]
                },
                7: {
                    title: 'Сатурн в 7 доме Соляра',
                    description: [
                        'Напряженный Сатурн указывает на разрыв отношений любого характера.',
                        'При таком Сатурне человек сторонится окружающих и общается только с самыми проверенными людьми. При позитивных аспектах может давать укрепление некоторых отношений, переход на более взрослый или официальный уровень. Также может указывать на развод. Год неблагоприятен для политической карьеры и публичной деятельности. Могут возникать проблемы в общении с деловыми партнерами.',
                        'Гармоничный Сатурн: заключение контрактов, помощь вышестоящих лиц, на мнение человека полагаются.',
                        'Негативные аспекты Сатурна: конфликты и расставания, возможно судебное разбирательство.'
                    ]
                },
                8: {
                    title: 'Сатурн в 8 доме Соляра',
                    description: [
                        'Сатурн указывает на ограничения со стороны других людей. Такой Сатурн может указывать на несчастные случаи по вине других людей, ухудшение здоровья и большой стресс. Такой год запоминается как неприятный и сложный. Нежелательно заниматься вопросами финансов, есть риск задержек, вмешательства налоговой или потерь. Также указывает на перемены в сфере жизни натального дома, куда он попал. Перемены могут быть и в лучшую сторону, но все равно воспринимаются как стресс и резкий перелом в привычном ходе жизни.',
                        'Пораженный Сатурн: усиление неприятностей, угроза здоровью или жизни.',
                    ]
                },
                9: {
                    title: 'Сатурн в 9 доме Соляра',
                    description: [
                        'Сатурн указывает на ограничения, связанные с учебой или дальними поездками.',
                        'В этот период приходится жертвовать отдыхом ради учебы и получения новых знаний. Поездки откладываются, тяжело происходит адаптация к новому или изучение новой культуры. Период неблагоприятен для политиков, преподавателей.',
                        'Позитивные аспекты Сатурна: укрепление связей за рубежом, в том числе деловых. Благоприятен для интеллектуальной деятельности (литературы, науки, преподавания, обучения).',
                        'Напряженный Сатурн: трудности в обучении, человек почти не приобретает новых знаний, информация идет тяжело, желание бросить учебу, дисквалификация, трудности на экзаменах, в поездках. Отсутствие перспектив.'
                    ]
                },
                10: {
                    title: 'Сатурн в 10 доме Соляра',
                    description: [
                        'В своем доме Сатурн обычно проявляет себя довольно мощно.',
                        'Может означать, что у человека в этом году в принципе нет целей, он плывет по течению. В ином случае — задачи есть, но выполнить их сложно из-за других обязанностей, каждый результат приходится зарабатывать тяжелым трудом. В классическом понимании год неблагоприятен для продвижения по карьерной лестнице, политики, руководства. Однако на практике Сатурн часто дает новые обязанности через труд и упорство. Возможны также важные события в жизни одного из родителей.',
                        'Позитивные аспекты Сатурна: стабильность в положении человека, планомерное продвижение к цели.',
                        'Негативные аспекты Сатурна: споры в семье и на работе. выяснения, кто главный, плохая репутация, потеря положения.'
                    ]
                },
                11: {
                    title: 'Сатурн в 11 доме Соляра',
                    description: [
                        'Поверхностные связи исчезают из жизни человека, остаются только проверенные временем друзья. В этот период тяжело получать похвалу или симпатию. Общение больше направлено на решение практических вопросов. Человека ждут существенные перемены в сфере того натального дома, куда попал Сатурн. Натив находится на распутье. Год неблагоприятен для приобретения популярности или рекламы.',
                        'Позитивные аспекты Сатурна: претворение планов в жизнь через медленную, но упорную работу.',
                        'Негативные аспекты Сатурна: постоянное невезение, склонность к изменам, страх перед будущим, попытки отстраниться от участия в чемлибо.'
                    ]
                },
                12: {
                    title: 'Сатурн в 12 доме Соляра',
                    description: [
                        'Сатурн указывает на чувство покинутости и изоляции.',
                        'Такой Сатурн обещает испытания и трудности, связанные с проблемами натального дома, в который проецируется Сатурн. Человек чувствует, что его бросили, появляются тайные враги и недоброжелатели, а все его достижения бесполезны. Может указывать на затяжные проблемы со здоровьем, повышенную конфликтность, увольнение.',
                        'Гармоничный Сатурн: страхи беспочвенны, победа над врагами.',
                        'Напряженный Сатурн: судьба сковывает человека, общество его не принимает. Возможны психологические проблемы и болезни.'
                    ]
                },
            },
            uranus: {
                1: {
                    title: 'Уран в 1 доме Соляра',
                    description: [
                        'Такой Уран знаменует начало нового периода , желание перемен.',
                        'Человека ждут неожиданные повороты событий в сфере ответственности натального дома, где находится Уран. Хочется активных действий и самовыражения, появляется вдохновение. Указывает на стремление к общению и переменам во внешности. Человек может попытаться улучшить жизнь общества в целом путем вступления в организации.',
                        'Напряженный Уран: нервозность, ухудшение здоровья, бунтарство, несдержанность.'
                    ]
                },
                2: {
                    title: 'Уран во 2 доме Соляра',
                    description: [
                        'Такой Уран знаменует изменение материального состояния, возможно появление новых источников дохода.',
                        'Период вносит свежую струю в финансовую сторону жизни человека. Он хватается за новые бизнес-идеи, может начать тратить больше или делать это сумбурно. Большую роль в финансовых делах человека играют его друзья. Построить прочный фундамент в этом году не удается.',
                        'Напряженный Уран: внезапные потери. Не стоит вкладывать или размещать капитал.'
                    ]
                },
                3: {
                    title: 'Уран в 3 доме Соляра',
                    description: [
                        'В этом году человека охватывает тяга к новым знаниям.',
                        'Такой Уран резко повышает обучаемость человека, его интересуют необычные знания, а также космонавтика, астрономия или техника. Часто указывает на новое обучение. Время благоприятно для научной, исследовательской деятельности. В этом году может меняться круг общения, появляются новые знакомые, которые сыграют важную роль в жизни человека. Приоритет отдается более интересным и развитым собеседникам.',
                        'Напряженный Уран: нужно быть осторожнее в поездках и за рулем, при заключении договоров. Трудности в общении со знакомыми, сумбурные идеи.'
                    ]
                },
                4: {
                    title: 'Уран в 4 доме Соляра',
                    description: [
                        'Человека ждут перемены в сфере семьи.',
                        'Такой Уран указывает на стремление отделиться от родителей, уход из семьи или обустройство своей жизни. Может означать зачатие ребенка или неожиданные перемены в семье.',
                        'Гармоничный Уран: ремонт, появление дома современной техники, домашние посиделки с друзьями. Все это также можно рекомендовать в качестве компенсаторики по году.',
                        'Дисгармоничный Уран: потеря имущества, конфликты в семье, резкая перемена места жительства.'
                    ]
                },
                5: {
                    title: 'Уран в 5 доме Соляра',
                    description: [
                        'Человека ждут изменения в романтических отношениях.',
                        'Такой Уран может указывать на новые связи и влюбленности, усложнение отношений с детьми или их отъезд. Дети требуют свободы, в их жизни может произойти серьезная перемена (иногда это просто смена основного хобби, иногда смена школы или детского сада). Возможны выигрыши, подарки или другие сюрпризы. Возрастает творческая активность, год благоприятен для сочинений, актерской игры, увлечений и хобби.',
                        'Негативные аспекты Урана: следует избегать азартных игр, финансовых рисков. Опасное положение при беременности, может дать непредсказуемое протекание процесса или какие-либо аномалии. Не рекомендуем такой год для планового зачатия.'
                    ]
                },
                6: {
                    title: 'Уран в 6 доме Соляра',
                    description: [
                        'В этом году стоит ждать изменений в сфере профессиональной деятельности. Такой Уран указывает на смену места работы, желание уволиться, смену вида деятельности — какие-то резкие перемены на работе под влиянием обстоятельств. Состояние здоровья тоже неустойчиво. Возможны психосоматические болезни, которые лучше лечить с минимальным вмешательством.',
                        'Гармоничный Уран: на работе дружеские отношения и плодотворная атмосфера. Расписание становится более свободным, натив не чувствует себя зажатым обстоятельствами.',
                        'Дисгармоничный Уран: опасность для здоровья, следует быть осторожнее с техникой и электричеством. Опасность увольнения.'
                    ]
                },
                7: {
                    title: 'Уран в 7 доме Соляра',
                    description: [
                        'На первый план выйдет обновление круга общения и отношений с партнерами.',
                        'Такой Уран освобождает человека от старых отношений, которые не развиваются, и у которых нет шансов на будущее. Какое-то время натив может быть одинок, но есть шанс для появления новых знакомств, которые помогут в осуществлении всех идей. Год благоприятен для работы с людьми, хотя наблюдается некоторая конфликтность. Возможны неожиданные итоги выступлений, экзаменов, общения с СМИ.',
                        'Добрый Уран: приобретение популярности.',
                        'Злой Уран: нестабильность отношений, разрыв с партнером, дурная слава.'
                    ]
                },
                8: {
                    title: 'Уран в 8 доме Соляра',
                    description: [
                        'Такой Уран знаменует неожиданные кризисы и осложнения.',
                        'Год проходит в тяжелом душевном состоянии. Осложнения с наследством, в финансовых сделках. Неблагоприятный год для размещения капитала. Внезапные и не особенно приятные изменения в сфере жизни, описываемой натальным домом с проекцией Урана.',
                        'Сильный Уран: стимулирует человека искать выход из ситуации, улучшение интуиции, склонность к сексуальным экспериментам.',
                        'Пораженный Уран: аварии, угроза здоровью, банкротство партнеров, неблагоприятное время для операций (крайне неблагоприятное!).'
                    ]
                },
                9: {
                    title: 'Уран в 9 доме Соляра',
                    description: [
                        'Такой Уран знаменует заграничные связи, тягу к перемене мест и необычным знаниям.',
                        'В жизни человека неожиданно появляются связи с заграницей, которые расширяют его горизонты. Он узнает новое из области религии и философии, интересуется новым и неизведанным. Может указывать на перемены в мировоззрении, получении огромного количества новой информации, поездки.',
                        'Негативные аспекты Урана: опасные ситуации в поездках, интерес быстро проходит, есть риск бросить учебу.'
                    ]
                },
                10: {
                    title: 'Уран в 10 доме Соляра',
                    description: [
                        'Такой Уран знаменует резкие карьерные перемены.',
                        'Может указывать на смену места работы, должности, начальства. Возможны конфликты с вышестоящими персонами. Важный год для человека, начало обновления или нового цикла в жизни. Возможны столкновения с властями или ограничение свободы воли. Уран способен резко изменить социальный статус натива (встречается в самых разных ситуациях: от замужества или развода до потери родителя, важно смотреть проекцию на натальный дом и другие методы прогнозирования).',
                        'Сильный Уран: человек уходит искать новую профессию или место работы, друзья помогают продвинуться, появляется репутация изобретателя или реформатора.',
                        'Негативные аспекты Урана: опасные ситуации.'
                    ]
                },
                11: {
                    title: 'Уран в 11 доме Соляра',
                    description: [
                        'Человека ждут новые планы и смена жизненной идеологии.',
                        'Такой Уран дарит множество планов, не все из которых осуществятся, активизируется общение с друзьями. Кроме того, появляется желание поработать на благо общества. Год может запомниться как неудачный, поскольку будет много неожиданных перемен, которые не зависят от человека. Но он еще и один из переломных.',
                        'Позитивные аспекты Урана: резкое изменение планов самим человеком, помощь друзей, новые знакомства.',
                        'Негативные аспекты Урана: дух противоречия, эксцентричность, опасность из-за друзей, социальные потрясения.'
                    ]
                },
                12: {
                    title: 'Уран в 12 доме Соляра',
                    description: [
                        'В этом году человека ждет потеря интереса к планам и общению',
                        'Такое положение чревато неприятностями в сфере натального дома, в который попал Уран. Человек не хочет общаться и идти к своим целям, либо делает это втайне. Может указывать на отъезд друзей или друзей, которые занимаются скрытой деятельностью. Благоприятны групповые психологические тренинги и проработки.',
                        'Гармоничные аспекты Урана: развитие интуиции.',
                        'Негативные аспекты Урана: неврозы, страхи, навязчивые мысли, возможна госпитализация.'
                    ]
                },
            },
            neptun: {
                1: {
                    title: 'Нептун в 1 доме Соляра',
                    description: [
                        'Нептун делает акцент на внутреннюю жизнь человека.',
                        'Такое положение Нептуна обещает достаточно сумбурный год. Человек несколько погружен в себя и выглядит пассивным или меланхоличным. Он больше реагирует на свои эмоции и попадает в двусмысленные ситуации, подчиняется воле других людей. Но такая позиция дает большое вдохновение и благоприятна для работы с подсознанием. Человек чаще помогает другим.',
                        'Гармоничный Нептун: интерес к искусству и религии, улучшение интуиции и развитие чувствительности.',
                        'Пораженный натальнтый Нептун: уход в мир фантазий, отказ от реальности, возможное применение психотропных средств, депрессии.',
                        'Негативные аспекты Нептуна: тревога, переживания.'
                    ]
                },
                2: {
                    title: 'Нептун во 2 доме Соляра',
                    description: [
                        'Нептун делает акцент на отсутствие контроля в финансах.',
                        'Такое положение может указывать на финансовую беспечность: деньги приходят и уходят. Часто дает ситуацию, в которой денег приходит много, но и «вымывается» также много. Отрегулировать поток почти не удается, запланировать что-то также проблематично.',
                        'Гармоничный Нептун: материальный комфорт, финансовая поддержка (как соуправитель Стрельца, может дать расширение средств).',
                        'Пораженный Нептун: потери из-за обмана партнеров, риск потерять крупную сумму денег, нечестный заработок.'
                    ]
                },
                3: {
                    title: 'Нептун в 3 доме Соляра',
                    description: [
                        'Такой Нептун указывает на непонятные/запутанные отношения с окружающими.',
                        'Это положение указывает на более интуитивное, мягкое, эмпатийное отношение со стороны человека, однако он заблуждается насчет качеств окружающих людей. Непосредственное общение часто переходит в переписки или телефонные разговоры.',
                        'Тяжелый период для обучения, поскольку сосредоточиться и воспринимать информацию нелегко. Но отличное время для духовных практик и знаний, обучения в области эзотерики.',
                        'Гармоничный Нептун: душевность в контактах.',
                        'Пораженный Нептун: разочарование в поездках, потеря документов, постоянные недоразумения при общении, ложь.'
                    ]
                },
                4: {
                    title: 'Нептун в 4 доме Соляра',
                    description: [
                        'Такой Нептун указывает на запутанную ситуацию в семье или сложные моменты с недвижимостью.',
                        'Могут появиться или вскрыться семейные тайны. Часто указывает на пожилого человека в доме или необходимость за кем-то ухаживать (встречался показатель, когда семья временно переезжала жить в квартиру к бабушке). Год не благоприятен для операций с недвижимостью, крупных покупок для дома. Дела и сделки могут так и остаться незаконченными. при наличии других показателях может указывать на зачатие.',
                        'Гармоничный Нептун: дом – центр души и вдохновения.',
                        'Негативные аспекты Нептуна: опасность для дома, в ососбо сложных случаях – распад семьи.'
                    ]
                },
                5: {
                    title: 'Нептун в 5 доме Соляра',
                    description: [
                        'Такой Нептун указывает на запутанные любовные отношения и переживания из-за них.',
                        'Нептун придает влюбленностям человека идеалистический характер, оторванный от реальности. В итоге это может привести к обману или разочарованию. Запутанные отношения с детьми и любовниками, могут быть обманы с их стороны. Также указывает на возможные ошибки в финансовых операциях, зависимости от игр и праздного образа жизни. На практике Нептун а пятом встречался также в год незапланированного зачатия.',
                        'Гармоничный Нептун: близкие приятные отношения.',
                        'Напряженный Нептун: неблагоприятный год для риска, азартных игр, разочарование в детях.'
                    ]
                },
                6: {
                    title: 'Нептун в 6 доме Соляра',
                    description: [
                        'Такой Нептун указывает на иллюзии или разочарования в работе.',
                        'На работе происходит неразбериха: не совсем ясно, у кого какие обязанности, повысят ли, как общаться с коллегами. Это положение указывает на нежелание работать, лень и апатию. Нужно отнестись внимательнее к здоровью, возможна анемия, психосоматические заболевания. Человек сам заблуждается по поводу своего состояния. Благоприятный период для санаторного лечения и налаживания режима питания.',
                        'Пораженный Нептун: есть риск отравления, неправильного приема лекарств, неприятные ситуации на работе.'
                    ]
                },
                7: {
                    title: 'Нептун в 7 доме Соляра',
                    description: [
                        'Такой Нептун указывает на запутанные партнерские отношения.',
                        'Это положение может указывать на временное исчезновение партнера из жизни человека или иллюзии в отношениях. Отношения с партнером становятся неопределенными.',
                        'Гармоничный Нептун: отношения душа в душу, общие интересы, появление новых отношений.',
                        'Пораженный Нептун: обман и недоговоренность в отношениях.'
                    ]
                },
                8: {
                    title: 'Нептун в 8 доме Соляра',
                    description: [
                        'Такой Нептун указывает на беспорядок в финансовых отношениях с другими людьми.',
                        'В этом году возможны обманы или подлоги в финансовых операциях. Человек может зависеть от чьих-то денег, а партнер потерять деньги. Может указывать на большие траты в пользу бедных или больных (это также будет являться компенсаторикой).',
                        'В области интимных отношений возможны измены, охлаждение, потеря интереса или, напротив, невероятное яркое чувствование. Удачный год для медитации, глубокого изучения психологии.',
                        'Негативные аспекты Нептуна: разочарование в партнерах, ложные концепции, нравственные метания.'
                    ]
                },
                9: {
                    title: 'Нептун в 9 доме Соляра',
                    description: [
                        'Для человека будут актуальны интересы за границей, мечты о путешествиях.',
                        'Такое положение указывает на мечты о переезде или хотя бы путешествии, надежды на сотрудничество с иностранцами, которые не осуществятся (в случае дисгармоничного положения). Человек может захотеть расширить свой бизнес. Значительно возрастает интуиция, повышается интерес к духовной жизни. Может указывать на морское путешествие/круиз. Преподавательская деятельность или учеба в области эзотерических знаний – крайне благоприятна.',
                        'Пораженный Нептун: ложное мировоззрение (секты), неприятности с законом, ложные перспективы. Зарубежные связи разрываются.'
                    ]
                },
                10: {
                    title: 'Нептун в 10 доме Соляра',
                    description: [
                        'Такой Нептун указывает на иллюзии, связанные с карьерой.',
                        'Человек ожидал повышения, а его так и не случилось. Надежды на покровителя не оправдались. Свои способности преувеличиваются, а цели ставятся нереалистичными. Неудачный год для общественной деятельности, политики и завоевания популярности. Зато можно достигнуть успеха в оккультных науках, искусстве или мистике. Приобрести славу на почве благотворительной деятельности. Клиентам предпринимателям я в такие годы советую ярко освещать все свои благотворительные проекты. Благоприятное время для химиков, биологов и вообще представителей нептунианских профессий.',
                        'Негативные аспекты Нептуна: секреты выходят наружу, плохая репутация, дурная слава.'
                    ]
                },
                11: {
                    title: 'Нептун в 11 доме Соляра',
                    description: [
                        'Такой Нептун указывает на заблуждения относительно друзей.',
                        'В этот период отношения с некоторыми друзьями ослабевают, часть друзей может уехать (или переезжает сам натив), но отношения с оставшимися становятся ближе, всегда можно рассчитывать на поддержку и помощь. Интерес к необычным знаниям и личностям (моряки, химики, фармацевты). Благоприятный год для работы с подсознанием. Особенно хороши групповые медитации и психологические проработки в кругу единомышленников.',
                        'Пораженный Нептун: ненадежные друзья, иллюзии по их поводу, рассчитывать не на кого, есть риск попасть в неудачную компанию.'
                    ]
                },
                12: {
                    title: 'Нептун в 12 доме Соляра',
                    description: [
                        'Такой Нептун указывает на стремление к уединению и богатую внутреннюю жизнь.',
                        'Положение может указывать на отъезд, тайные дела, плохое душевное состояние, ощущение несвободы. Нептун способствует изучению психологии, религии, работе представителей нептунианских профессий.',
                        'Год может казаться полным разочарований и расставаний.',
                        'Пораженный Нептун: лень, нежелание заниматься делами, жалость к себе, депрессия, употребление алкоголя.'
                    ]
                },
            },
            pluto: {
                1: {
                    title: 'Плутон в 1 доме Соляра',
                    description: [
                        'Плутон дает возможность изменить жизнь и достичь целей.',
                        'Возникнут ситуации, где человеку нужно будет проявить выдержку, самостоятельность и напористость. Сферу жизни подскажет солярный дом, на куспиде которого находится Скорпион. Человек ищет смысл жизни, год становится для него важным и переломным. Такое положение дает привлекательность для людей, может указывать на зачатие или рождение ребенка. Возникает интерес к психологии и мистике.',
                        'Сильный Плутон: победа над обстоятельствами, новые силы.',
                        'Пораженный Плутон: беспощадность, от действий человека могут пострадать другие.'
                    ]
                },
                2: {
                    title: 'Плутон во 2 доме Соляра',
                    description: [
                        'Плутон дает стремление к заработку, желание обладать большими деньгами.',
                        'Такой Плутон заставляет хотеть заработать любой ценой, человек может забыть об осторожности или пользоваться деньгами других людей. Человек может буквально идти по головам ради денег. Положение способно принести трансформацию в денежной сфере.',
                        'Гармоничный Плутон: новые способы заработка, доходная работа, выплата кредитов и закрытие обязательств.',
                        'Пораженный Плутон: крупные расходы, рискованные сделки, переутомление, сверх концентрация в теме финансов, одержимость.'
                    ]
                },
                3: {
                    title: 'Плутон в 3 доме Соляра',
                    description: [
                        'Плутон дает напор в общении, убедительность.',
                        'У человека пробуждается способность настаивать, договариваться, убеждать. Есть риск переборщить с напором и оттолкнуть людей от себя Хороший год для журналистики и деловой сферы. Может меняться круг общения (трансформация информационного поля), возникать проблемы в учебе, неприятные ситуации в жизни братьев/сестер или других родных.',
                        'Пораженный Плутон: опасность в поездках, нежелание выполнять свои обязанности.'
                    ]
                },
                4: {
                    title: 'Плутон в 4 доме Соляра',
                    description: [
                        'Плутон дает перемены в сфере семьи и недвижимости.',
                        'Это положение указывает на домашние заботы, расхождения во взглядах в семье, масштабный ремонт или переезд. Человек начинает интересоваться историей семьи и места, где он живет. Может указывать на стремление приобрести недвижимость и смену жизненных циклов (заканчивается один значимый период и начинается новый).',
                        'Гармоничный Плутон: необходимость обустраиваться, много сил уходит на домашние хлопоты.',
                        'Злой Плутон: напряженная обстановка в семье, попытки переубедить друг друга, лишение дома'
                    ]
                },
                5: {
                    title: 'Плутон в 5 доме Соляра',
                    description: [
                        'Плутон дает стремление к любовным отношениям, чувственность.',
                        'Такой Плутон повышает чувственность и тягу к интимной стороне жизни. Человек будет флиртовать и искать свой идеал, возможны измены нынешнему партнеру, если на то есть предпосылки в натале. Кроме того, активизируется тема хобби, развлечений, поездок. Человек становится азартным, очень хочется самовыражения. Могут быть сложные отношения с детьми, ребенок совсем не слушается.',
                        'Негативные аспекты Плутона: опасность для ребенка, кризис в его жизни, много переживаний из-за любви, потери средств на бирже. Опасный период для ранних сроков беременности, однако, может указывать на сам факт рождения ребенка (не зачатия).'
                    ]
                },
                6: {
                    title: 'Плутон в 6 доме Соляра',
                    description: [
                        'Плутон дает существенные изменения в сфере работы.',
                        'Это положение может указывать на смену места работы или должности, повышение, признание заслуг человека на работе. При этом обязанностей становится больше, а коллеги недовольны новым положением и могут начать плести интриги. Обязательства перед людьми настолько огромные, что это может привести к переутомлению. Для предпринимателей - это период перестройки процессов и трансформации команды.',
                        'Негативные аспекты Плутона: саботаж на работе, проблемы со здоровьем.'
                    ]
                },
                7: {
                    title: 'Плутон в 7 доме Соляра',
                    description: [
                        'Плутон указывает на давление со стороны партнеров по бизнесу или браку.',
                        'Такое положение ставит человека в зависимое от партнеров положение, приводит к длительному противостоянию и борьбе за власть. Возможен разрыв отношений в бизнесе и появление новых партнеров. Может указывать на манипуляции в отношениях, усиление общественной активности, вообще переход к активным действиям человека. В плюсе – трансформация отношений, выход на новый уровень.',
                        'Гармоничный Плутон: широкое влияние на других людей, популярность в обществе (на практике встречался в год начала политической карьеры).',
                        'Негативные аспекты Плутона: начало развода, разногласия с партнером, тяжба, силовые противостояния в суде (часто с привлечением прокуратуры, власти, сильных покровителей).'
                    ]
                },
                8: {
                    title: 'Плутон в 8 доме Соляра',
                    description: [
                        'Плутон указывает на кризисы и экстремальные ситуации. При хорошем положении может расширить власть, дать приход денежных средств.',
                        'Такой период усиливает магнетизм человека для окружающих и активизирует работу его подсознания. Указывает на перемены в сфере жизни, описываемой натальным домом-проекцией Плутона. Человек считает этот год очень тяжелым.',
                        'Происходит автоматическое избавление от всего старого и ненужного, человек оказывается под давлением со стороны других, включая какиелибо организации. Может указывать на встающий финансовый вопрос, стремление получить деньги других людей, споры из-за наследства.',
                        'Гармоничный Плутон: период в жизни, из которого человек выходит с огромным новым опытом, прибыль, новый сексуальный и чувственный опыт.',
                        'Негативные аспекты Плутона: болезни, недомогания, серьезная угроза здоровью, рост долговых обязательств, разбирательства на фоне денежного вопроса.'
                    ]
                },
                9: {
                    title: 'Плутон в 9 доме Соляра',
                    description: [
                        'Плутон указывает на изменения в жизни, связанные с заграницей, мировоззрением или учебой.',
                        'Человек знакомится с новыми идеями и расширяет свои горизонты. Может указывать на поездки за рубеж, деловые связи по всему миру, расширение бизнеса. В этот период становятся ясными перспективы человека на ближайшие годы. Поездка в новую местность может быть очень продуктивной.',
                        'Активизируется интеллектуальная деятельность, сфера образования — но вся деятельность требует много усилий и концентрации.',
                        'Поражённый Плутон — неприятности в поездках.'
                    ]
                },
                10: {
                    title: 'Плутон в 10 доме Соляра',
                    description: [
                        'Плутон указывает на амбиции и лидерство.',
                        'На работе может начаться борьба за власть, человеку хочется стать лидером и проявить себя. Возможна смена начальства, проверка репутации человека. Увеличивается магнетизм человека, он нравится окружающим. Беспорядок в делах может помешать продвижению по службе. В негативных сценариях – полная неконтролируемая смена социального статуса (увольнение/ развод/ смерть родственника).',
                        'Негативные аспекты Плутона: неприятности на работе, недоверие начальства, лишение должности.'
                    ]
                },
                11: {
                    title: 'Плутон в 11 доме Соляра',
                    description: [
                        'Плутон указывает на изменения в кругу друзей.',
                        'В этот период дружба может проходить проверку на прочность, возникают конфликты, человек хочет быть главным в кругу друзей. Может указывать на изменения социальных ценностей человека. Этот год очень важен, потому что приближает к человека к исполнению его жизненных целей. Огромный напор в исполнении своих планов.',
                        'Может указывать на коллективный труд, возможность найти новый круг общения и стать там лидером.'
                    ]
                },
                12: {
                    title: 'Плутон в 12 доме Соляра',
                    description: [
                        'Плутон указывает на скрытность, тайные дела. Может проявить тайных врагов и недоброжелателей.',
                        'Год не очень благоприятен, человек находится под влиянием других людей, ему кажется, что весь мир против него. Может указывать на фобии, психоз.',
                        'Благоприятный год для занятий йогой, духовными практиками, развития интуиции.',
                    ]
                },
            },
        },
        'solar-axis': {},
        'cup-in-house': {
            1: {
                1: {
                    title: '1 дом Соляра в 1 доме',
                    description: [
                        'На первый план выходит собственное Я. Понимание целей и приоритетов в жизни. Саморазвитие, индивидуальность, свобода самовыражения. Год очень важный в жизни человека. Возможны судьбоносные события, встречи.',
                    ]
                },
                2: {
                    title: '1 дом Соляра во 2 доме',
                    description: [
                        'При отсутствии неблагоприятных показателей - год обретения финансовой стабильности, фундамента под ногами. Человек задумывается о материальных вещах, становится более прагматичным. Его больше всего волнуют вопросы зарабатывания денег, обретения финансовой независимости. Все материальные вопросы человек в этом году будет решать самостоятельно и в первую очередь',
                    ]
                },
                3: {
                    title: '1 дом Соляра в 3 доме',
                    description: [
                        'Может дать изменение в кругу общения, поездки, подписание значимых бумаг. На первый план выходит интеллектуальное развитие, обучение. Хороший год для подготовки и сдачи экзаменов, подтверждения сертификатов, написания статей и защиты дипломов, для начала и продолжения обучения.',
                    ]
                },
                4: {
                    title: '1 дом Соляра в 4 доме',
                    description: [
                        'Желательно этот год провести дома, путешествия могут принести неблагоприятные эмоции. Важный период, связанный с родителями человека, его семьей, а также с отношениями имущественного характера. Вероятна покупка недвижимости, обретения собственного домашнего очага, желание завести семью. Может давать перемену места жительства. При актуализации восьмого дома указывает на потерю в семье. В формулах событий также участвует как показатель к зачатию/рождению (особенно если в доме есть планета, которая проецируется в 5 дом натала).'
                    ]
                },
                5: {
                    title: '1 дом Соляра в 5 доме',
                    description: [
                        'Счастливый год, знаменующийся творческим развитием, внутренним подъемом, вдохновением. Часто - год рождения детей. Если дети уже есть - человек будет полностью занят решением их проблем. Для творческих личностей - успех в их деятельности, подъем творческой карьеры. Важную роль играют любовные отношения, появляется острое желание любить и быть любимым, флиртовать, повышается сексуальность и страстность. Склонность вести светский образ жизни, обретая популярность и статус в обществе.',
                        'При положительных конфигурациях соляра вероятно получение прибыли от игры на бирже, выигрыши в лотереях и конкурсах, махинациях.',
                        'При неблагоприятных показателях соляра - финансовые потери.'
                    ]
                },
                6: {
                    title: '1 дом Соляра в 6 доме',
                    description: [
                        'Человек чувствует себя заложником обстоятельств, от него ничего не зависит. Необходимость кому-то подчиняться. Проходит своеобразная проверка на прочность. Он завален разрешением проблем на работе, бытовыми неурядицами и проблемами со здоровьем.',
                        'Так же год перемен на работе, смены сферы и места деятельности.',
                        'При негативных аспектах - возможно, начало болезни.'
                    ]
                },
                7: {
                    title: '1 дом Соляра в 7 доме',
                    description: [
                        'Год партнерства. У свободных людей появляется желание найти вторую половинку. Пары, которые еще не оформили свои отношения, имеют все шансы дойти до ЗАГСа, при наличии других показателей/аспектах. Год поиска бизнес партнеров, сотрудничества и вовлеченности в дела других людей.',
                        'При негативных показателях человек сталкивается с критикой окружения, сложностями в бизнесе /семье. Появляются недоброжелатели или проблемы юридического характера.'
                    ]
                },
                8: {
                    title: '1 дом Соляра в 8 доме',
                    description: [
                        'Если есть указание в карте человека на специфические/магические способности, то в этом году такие знания начнут себя проявлять. Год погружения в тайные, мистические знания. Натив может быть задействован в вопросах, связанных со смертью (присутствие на похоронах, помощь в оформлении завещания и т.п.). Возможность получения неожиданных крупных выигрышей, чужих денег (наследства).',
                        'Желание переосмыслить жизнь, как бы переродиться. Повышенная вероятность несчастных случаев, стрессовых ситуаций, опасность болезней.'
                    ]
                },
                9: {
                    title: '1 дом Соляра в 9 доме',
                    description: [
                        'Год, связанный с заграницей или обучением. Поездка в другой город или другую страну может стать судьбоносной. Возможно длительное пребывание в другой стране, а также расширение знаний. Человек становится более заметным в обществе. Год может быть началом преподавательской деятельности или получения высшего образования, кандидатской или докторской степени. Человек занимается научной деятельностью или становится наставником для кого-то.'
                    ]
                },
                10: {
                    title: '1 дом Соляра в 10 доме',
                    description: [
                        'Год благоприятен для профессиональной деятельности, продвижения к цели, которую ставит перед собой человек. Возможна смена должности на более высокую. Либо получение значимой роли в обществе, получение негласного лидерства. Год становления индивидуума. Все зависит от человека, сам натив – либо причина своих возвышений, либо причина своих неудач. Для женщин, вероятно, изменение статуса.'
                    ]
                },
                11: {
                    title: '1 дом Соляра в 11 доме',
                    description: [
                        'Год, когда человек становится более социализированным, принимает активное участие в жизни общества. Друзья – главное слово этого года. Стремление проводить все свое время в обществе единомышленников. При благоприятных показателях, человек становится душой компании, он чувствует симпатию со стороны окружающих, поддержку от друзей.',
                        'Человека переполняет энтузиазм, он строит новые планы, у него полно идей.',
                        'При негативных показателях человек становится суетливым, много времени тратит впустую, способен пуститься в разгул ради новых приключений. Возможны разрывы отношений с друзьями.'
                    ]
                },
                12: {
                    title: '1 дом Соляра в 12 доме',
                    description: [
                        'В этот период важно обратить внимание на свое здоровье т.к. активизируются процессы хронических заболеваний, либо текущие переходят в хроническую форму. Так же активизируются враги и недоброжелатели. Не рекомендуется проявлять излишнюю активность. Лучше этот год провести в уединении. Год погружения в себя, собственное я и свои процессы. Человек сокращает количество контактов с окружающими. Год не способствует развитию карьеры, но заручиться тайной поддержкой влиятельного лица вполне вероятно (при благоприятных показателях).',
                        'При негативных аспектах возможно увлечение спиртными напитками, наркотиками, курением.'
                    ]
                },
            },
            2: {
                1: {
                    title: '2 дом Соляра в 1 доме',
                    description: [
                        'Материальные проблемы человека выходят в этом году на первый план. И решить их он может только благодаря собственным усилиям и инициативам.',
                        'При негативных показателях личная свобода человека и способность к осуществлению планов могут быть ограничены. Причиной становится неудовлетворительное материальное состояние и чрезмерное погружение в денежные проблемы.'
                    ]
                },
                2: {
                    title: '2 дом Соляра во 2 доме',
                    description: [
                        'Человек не испытывает недостатка в деньгах. Есть возможность даже накопить на что-либо. Положение очень подсвечивает все натальные тенденции второго дома (гармоничные и напряженные в равной степени).',
                        'При негативных показателях возможны мелкие кражи, потери мелких сумм денег.'
                    ]
                },
                3: {
                    title: '2 дом Соляра в 3 доме',
                    description: [
                        'Создается ощущение нестабильности финансового положения, когда в любую минуту можно понести потери или наоборот получить некоторую сумму.',
                        'Денежные проблемы человек может решить за счет стипендии, поездок и перемещений, заключения договоров, либо, при поддержке близких родственников. Интеллектуальные занятия также приносят прибыль. Деньги могут приходить по почте (переводы, подписка). При негативных показателях дает траты на платное обучение.',
                        'Год благоприятен для покупки транспортных средств и средств связи.'
                    ]
                },
                4: {
                    title: '2 дом Соляра в 4 доме',
                    description: [
                        'Год обретения финансовой самостоятельности.',
                        'Период благоприятен для покупки квартиры, участка, а также для вступления в права владения наследством (недвижимостью и капиталом). Квартирные и имущественные вопросы существенно перераспределяют бюджет.',
                        'В минусе – указание на растраты на семью, близких родственников, недвижимость, ремонт.'
                    ]
                },
                5: {
                    title: '2 дом Соляра в 5 доме',
                    description: [
                        'Человек может быть на содержании или некоторые виды расходов переложить на любимого человека или просто сексуального партнера, ухажера, поклонника, покровителя, а также ребенка.',
                        'При таком положении соляра возрастает возможность личного заработка, а так же доходов от творческих талантов, успехов в искусстве, спорта, если с ними связана жизнь человека.',
                        'При негативных показателях – траты на вышеперечисленные мероприятия.'
                    ]
                },
                6: {
                    title: '2 дом Соляра в 6 доме',
                    description: [
                        'Год указывает на траты на свое здоровье, покупку медикаментов или пребывание в лечебно-оздоровительных учреждениях. Не желательно начинать собственное дело, это может привести к убыткам, правильнее на этот год остаться в найме. Есть возможность прибавить в деньгах за счет хорошей службы.',
                        'При негативных показателях – зависимое положение в деньгах.'
                    ]
                },
                7: {
                    title: '2 дом Соляра в 7 доме',
                    description: [
                        'Прибыль и популярность приносит работа с партнерами, коллективная деятельность. Работа по договорным отношениям, контрактам, работа вместе с супругом.',
                        'При негативных показателях – супруг (супруга) может начать процесс о разделе денежных средств, назначении алиментов, денежного содержания. В минусе – разрыв отношений с финансовыми партнерами.'
                    ]
                },
                8: {
                    title: '2 дом Соляра в 8 доме',
                    description: [
                        'Год, в котором человек переосмысливает свое отношение к материальным ценностям, избирает новую стратегию заработка. Возможное получение наследства, возвращение долгов (с процентами). В плюсе – благоприятная трансформация доходов.',
                        'При негативных показателях - возможна потеря средств к существованию или существенная их трансформация. Угрожает банкротство или безработица.'
                    ]
                },
                9: {
                    title: '2 дом Соляра в 9 доме',
                    description: [
                        'Может дать расширение доходов или расходов. Указание на получение доходов от финансовых вливаний в иностранные фирмы, биржи, банки, от контактов с зарубежными партнерами или через привезенные идеи/товары. Прибыль так же может принести и пропагандистская деятельность, написание книг или преподавание.',
                        'При негативных показателях – траты на учебу/поездки/подписание соглашений.'
                    ]
                },
                10: {
                    title: '2 дом Соляра в 10 доме',
                    description: [
                        'Очень хорошие перспективы для получения дохода. Возможен финансовый прирост из-за нового статуса (повышение квалификации, новая должность, новая профессия). Или, напротив, положение человека требует расходов, инвестиций в бизнес и его развитие.',
                        'Хорошие возможности получить кредит, влияние на бизнес со стороны покровителей (спонсоров) или просто людей стоящих выше в социальной или профессиональной иерархии.'
                    ]
                },
                11: {
                    title: '2 дом Соляра в 11 доме',
                    description: [
                        'Формируются новые планы относительно того, как заработать и потратить деньги. Год переосмысления расходов и доходов. Возможна новая сфера деятельности для заработка (в том числе Уранические профессии). Финансовая поддержка со стороны друзей, поклонников или напротив, траты на них и удовлетворение сиюминутных желаний. Совместно с другими указателями в Соляре может давать год зачатия/рождения второго ребенка (работает ось 5-11).'
                    ]
                },
                12: {
                    title: '2 дом Соляра в 12 доме',
                    description: [
                        'Человек ведет не такой, как ему хочется образ жизни, из-за стеснения в средствах. Возможно получение средств от занятий психологией, эзотерикой, а также путем реализации удаленной работы. Так же, могут быть поступления денег из таинственных источников, от тайной деятельности, скрытых доброжелателей.',
                        'При негативных показателях - часть средств может уйти на лечение, пребывание в больнице, а также для освобождения из-под стражи (под залог или с помощью взятки). Ситуация может быть тяжелой, осложненной обязательствами и долгами.'
                    ]
                }
            },
            3: {
                1: {
                    title: '3 дом Соляра в 1 доме',
                    description: [
                        'Человека посещает множество идей и планов. Его интересуют дела его окружения, приятелей и близких родственников. Натив выражает себя через получение новых знаний, чтение, переписку, поездки. Положение может дать важные события в жизни брата/сестры.',
                        'В минусе даже при огромном количестве контактов человек ощущает себя одиноким и невостребованным.'
                    ]
                },
                2: {
                    title: '3 дом Соляра во 2 доме',
                    description: [
                        'Человек захвачен решением финансовых задач и торговлей. Все его перемещения и контакты связаны в основном с желанием заработать. Деньги могут приходить через темы третьего дома. Также вопросы финансов могут разрешаться с помощью родни.'
                    ]
                },
                3: {
                    title: '3 дом Соляра в 3 доме',
                    description: [
                        'Часто год становится годом начала определенного цикла обучения. Возможно подписание важных официальных бумаг, документов, контрактов, договоров. Активные контакты с родственниками, друзьями и знакомыми. Много возможностей краткосрочного отдыха в компаниях, поездок.'
                    ]
                },
                4: {
                    title: '3 дом Соляра в 4 доме',
                    description: [
                        'Все перемещения человека связаны с ближайшими родственниками (родителями), по вопросам имущества семьи или возвращением на родину, если такой сценарий актуален.',
                        'Может указывать на завершение цикла какого-либо обучения, на возвращение домой или отбытие из дома (переезд). Также может дать старт учебной программы, которую натив будет осваивать из дома.'
                    ]
                },
                5: {
                    title: '3 дом Соляра в 5 доме',
                    description: [
                        'Человек ведет очень активную социальную жизнь. Тусовки, мероприятия, праздники, походы в музеи. Много флирта, свиданий. Отсюда возможно появление поклонников, любовных связей. Развитие в творчестве через писательство. Возможно зачатие ребенка из-за мимолетной связи.'
                    ]
                },
                6: {
                    title: '3 дом Соляра в 6 доме',
                    description: [
                        'Возможны множественные командировки, перелеты, служебный переезд. Сдача экзаменов, связанных с работой, проверка профессиональных знаний. Состояние здоровья вызывает беспокойство и нервозность. Прогнозируем походы по врачам. Возможно небольшое путешествие для поправки здоровья, поездка на курорт. Интерес к медицине/оздоровительным мероприятиям.',
                        'Обратить внимание на здоровье родственников (!).'
                    ]
                },
                7: {
                    title: '3 дом Соляра в 7 доме',
                    description: [
                        'Все перемещения человека преимущественно связаны с партнерскими отношениями. Совместные поездки с супругом, командировки и деловые поездки с бизнес партнёрами. Может давать поездки самих партнеров, без участия натива. Год полезных деловых знакомств и контактов. Возможность получить предложение, которое изменит статус партнерства (социальный или деловой). Трактуем, как оформление важных документов по теме партнёрства. При негативных аспектах – начало бракоразводного или судебного процесса.'
                    ]
                },
                8: {
                    title: '3 дом Соляра в 8 доме',
                    description: [
                        'Поездки/полеты в этот год лучше отложить. А так же подписание контрактов, договоров, начало проектов. Указание на критические ситуации.',
                        'Возникновение интереса к магическим наукам, мистическим практикам, астрологии, психологии, а также вопросам права, налогообложения. Изучение этих дисциплин.'
                    ]
                },
                9: {
                    title: '3 дом Соляра в 9 доме',
                    description: [
                        'Много возможных коротких поездок за рубеж: командировки или просто отдых. Переписка с иностранцами или просто очень много учебы. Часто при поступлении в ВУЗ или при получение диплома. Общение с иностранцами. Начало литературного труда. Тяга к изучению культур других стран, религии.'
                    ]
                },
                10: {
                    title: '3 дом Соляра в 10 доме',
                    description: [
                        'Поездки/учеба/контакты или документы – как тема или цель года. Все темы третьего дома способны продвигать человека к его целям. Возможно подписание крайне важных бумаг (вплоть до брачных договоров или просто штампа в паспорте). Знакомства с влиятельными людьми, поездки, связанные с профессиональной деятельностью.'
                    ]
                },
                11: {
                    title: '3 дом Соляра в 11 доме',
                    description: [
                        'Год благоприятен для начала или активизации изучения астрологии, компьютерной электроники, программирования, ядерной физики, новых научных дисциплин, различных «модных» концепций. Человек очень много переписывается с друзьями/единомышленниками, обменивается планами, идеями. Появляется много новых друзей и поездок, связанных с ними.'
                    ]
                },
                12: {
                    title: '3 дом Соляра в 12 доме',
                    description: [
                        'Человек замыкается в себе. Становится закрытым и необщительным. Чаще делает это намеренно, углубляясь в познание внутреннего мира или эзотерических тем. Может совершать таинственные поездки, вести тайные переговоры, получать информацию из скрытых источников, может вести слежку или ее установят за ним самим.'
                    ]
                }
            },
            4: {
                1: {
                    title: '4 дом Соляра в 1 доме',
                    description: [
                        'Человек становится очень деятельным в плане создания семьи или урегулирования уже имеющихся семейных отношений. Все, что связано с родом, семьей, родиной, традициями или недвижимостью выходит на первый план. Человек может углубиться в изучение собственных корней. Может увеличиваться влияние родителей на натива.',
                        'Всегда знаменует начало нового важного этапа в жизни.'
                    ]
                },
                2: {
                    title: '4 дом Соляра во 2 доме',
                    description: [
                        'Основные денежные поступления идут от семьи или недвижимости, либо основные траты уходят на эти темы. Возможно накопление некоторых сумм, завершающееся крупной покупкой. Хорошо проводить ремонт в этом году, вкладываться в недвижимость, если нет иных противоречащих указаний.'
                    ]
                },
                3: {
                    title: '4 дом Соляра в 3 доме',
                    description: [
                        'Может дать перемену места жительства или постоянные переезды из одного дома в другой. Данный указатель чаще всего встречается в формулах переезда. Так же возможно указание на краткий отъезд кого-то из членов семьи, родителей. Окончание какой-либо учебы.',
                        'Интерес к традициям свое семьи, изучение родословной.'
                    ]
                },
                5: {
                    title: '4 дом Соляра в 5 доме',
                    description: [
                        'Любовные дела в доме человека. Может означать прибавление в семействе (рождение ребенка) или взятие в дом родственника для воспитания или присмотра.',
                        'В некоторых случаях показывает окончание цикла обучения ребенка, его успехи в творчестве или вознаграждение собственных усилий/творческих стараний, признание заслуг. Может дать покупку недвижимости для ребенка, его отъезд или возвращение домой.',
                        'Устанавливает связь того или иного рода между отношениями с сотрудниками и подчиненными. Здоровье одного из родителей или членов семьи может быть подорвано, в связи, с чем человек принимает на себя новые обязательства по дому, по уходу и т.п. Положение в семье приносит беспокойство и хлопоты. Множество домашних обязанностей, дел. Возможна работа из дома.',
                        'При негативных показателях — домашний режим, лечение.'
                    ]
                },
                7: {
                    title: '4 дом Соляра в 7 доме',
                    description: [
                        'В решении имущественных и бытовых проблем, решении вопросов места жительства важную роль играет вмешательство партнеров по бизнесу, других компаньонов или супруга. Также может быть вскрытие какой-либо тайны, связанной с этими лицами, обнародование, обсуждение связей человека. Часто означает начало семейной жизни без регистрации брака и приобретение (наем) совместного жилища. Также и переезд в дом супруга или, напротив, его отъезд. Возможны скандалы в семье',
                        'Решение вопроса о наследуемом имуществе, семейной тяжбе.'
                    ]
                },
                8: {
                    title: '4 дом Соляра в 8 доме',
                    description: [
                        'Возможно проживание в доме чужих людей, невозможность занять свою жилплощадь или непонятные проблемы с проживающими в квартире.',
                        'Родственников человека могут ожидать потрясения и критические ситуации. Возможно расторжение договоров, снятие с себя обязательств, особенно финансовых, а также выплата долга, погашение кредита и т.п.'
                    ]
                },
                9: {
                    title: '4 дом Соляра в 9 доме',
                    description: [
                        'Изменение места жительства, путешествие, отъезд за границу родителей или собственной семьи человека, отдельных ее членов. Часто встречается в формулах событий на эмиграцию.',
                        'Перспективы человека, расширение его горизонтов в этом году связаны с семейными отношениями и традициями, имущественным положением и вопросами собственности, с родными местами.'
                    ]
                },
                10: {
                    title: '4 дом Соляра в 10 доме',
                    description: [
                        'Родители и другие родственники, а также члены собственной семьи человека могут сыграть не последнюю роль в решении вопросов карьерного продвижения. Возможно изменение статуса, связанного с недвижимостью. Часто – достижение некой цели по этой теме (долго планировали квартиру и наконец-то выбрали/купили, как вариант). Завершение какого-то цикла в карьере, начало нового витка. Год очень важен для всех, чьей профессиональной деятельностью являются темы четвертого дома.',
                        'При негативных показателях – карьерному, профессиональному продвижению мешают домашние заботы или семейные отношения, родственные связи.'
                    ]
                },
                11: {
                    title: '4 дом Соляра в 11 доме',
                    description: [
                        'Возможно проживание в доме друга или влияние друзей на домашние и имущественные дела человека. Может означать конец какой-либо дружбы и углубление симпатий в отношении другого человека.',
                        'Возможно изменение места жительства по идейным соображениям, жизнь в коммуне, общине, а также использование своего дома для нужд таких организаций и групп.'
                    ]
                },
                12: {
                    title: '4 дом Соляра в 12 доме',
                    description: [
                        'События года могут сопровождаться трудностями с родственниками, членами семьи, — как событийного, так и эмоционального характера. Положение в доме, в семье вызывает состояние неудовлетворенности, одиночества и опустошенности. Углубление в тайны рода, происхождения. Возможно предоставление своей жилплощади, владений и имущества для нужд тайных обществ или подпольной деятельности. Дому или имуществу могут грозить повреждения, кражи, а человеку — насильственное лишение жилья.'
                    ]
                },
            },
            5: {
                1: {
                    title: '5 дом Соляра в 1 доме',
                    description: [
                        'Год благоприятен для людей искусства, — год признания, раскрытия таланта, творческого подъема. Усиливается чувственность, стремление к любовным переживаниям. Человек проявляет повышенную активность в светской жизни, принимает участие в увеселительных мероприятиях, приемах, а также азартных играх. Указание на любовные похождения.',
                        'Возможно зачатие, беременность и рождение ребенка.'
                    ]
                },
                2: {
                    title: '5 дом Соляра во 2 доме',
                    description: [
                        'Год удачного разрешения финансовых проблем, материального благосостояния, множества забот и действий в этом направлении. Может указывать также на выигрыши в лотереях, азартных играх, успех в рискованных финансовых операциях, если показатели дома не поражены. Денежный успех может приносить творчество, созданные (и проданные) произведения искусства, принадлежащие человеку объекты отдыха и развлечений.',
                        'Возрастают траты на удовольствия и детей.'
                    ]
                },
                3: {
                    title: '5 дом Соляра в 3 доме',
                    description: [
                        'Тема года - любовные переживания. Любовная переписка, написание текстов на любовные темы, в том числе лирических, эротических стихов. Множество разговоров о любви. Небольшие приятные путешествия, любовные похождения.',
                        'Возможно, рождение детей у кого-то из членов семьи. Для людей, занимающихся спортом, может означать спортивные свершения, крупные победы (при благоприятных показателях дома) или просто отъезд на значительные спортивные соревнования.'
                    ]
                },
                4: {
                    title: '5 дом Соляра в 4 доме',
                    description: [
                        'Год указывает на счастливое событие в доме, в семье. Может дать зачатие/рождение ребенка.',
                        'Человек принимает участие в семейных праздниках, торжествах. Дети создают собственные семьи, начинают жить своим домом, указатель на их помолвки, свадьбы. Может означать удачное приобретение недвижимости, земельных участков, домашнего имущества. Иногда указывает на ремонт в доме, приглашение дизайнера.',
                        'Улучшение отношений с родными, активное участие в их делах.'
                    ]
                },
                5: {
                    title: '5 дом Соляра в 5 доме',
                    description: [
                        'Чаще всего означает крупные события, связанные с любовными отношениями, — как начало связи, так и конец — помолвку или разрыв — в зависимости от показателей дома. Любовные переживания и события в личной жизни играют большую роль для человека. Стремление завести детей и высокая вероятность зачатия и рождения ребенка.',
                        'Увлеченность своим любимым делом, хобби или обретение хобби.'
                    ]
                },
                6: {
                    title: '5 дом Соляра в 6 доме',
                    description: [
                        'Возможен роман с сотрудником, на работе. Влияние служебных отношений на личные и наоборот. Детям грозят заболевания, общее состояние болезненности и апатии. Либо поступление их на службу. Также указание на долги и обязательства детей. Творческие порывы человека, его вдохновение связано с производственными процессами, обычной работой.'
                    ]
                },
                7: {
                    title: '5 дом Соляра в 7 доме',
                    description: [
                        'Связь, любовные отношения могут привести к изменениям в партнерстве. Браку (или, напротив, разводу), либо заключению соглашения.',
                        'Рост популярности человека, особенно среди партнеров или в консультационной деятельности (адвокатура, психология, астрология и пр.). Участие в торжественных, светских, общественных мероприятиях, приемах. Заключение сделок, которые приведут к доходу, удачным приобретениям.',
                        'При неблагоприятных показателях может указывать на разрыв, измену, ссоры.'
                    ]
                },
                8: {
                    title: '5 дом Соляра в 8 доме',
                    description: [
                        'Вступление в интимную связь по любви, с дорогим человеком. Чаще всего это кармические отношения. Возможно попадание в опасные ситуации из-за любовных похождений. Также и прекращение любовной связи, внезапная трансформация, разрыв.',
                        'Попадание в критические ситуации способствует трансформации личности, часто — увеличению творческой активности.'
                    ]
                },
                9: {
                    title: '5 дом Соляра в 9 доме',
                    description: [
                        'Возникновение любовной связи при проживании за границей или в другом городе или с человеком, прибывшим из-за границы. Также курортные романы или любовные связи в путешествиях.',
                        'Возможно зачатие или рождение ребенка за границей. Человек активно путешествует, творческие поездки. Отдых на выезде. Возможность обретения перспективных связей за границей или с иностранными гражданами и организациями.',
                        'При негативных аспектах— разрыв любовной связи вследствие разлуки, отъезда.'
                    ]
                },
                10: {
                    title: '5 дом Соляра в 10 доме',
                    description: [
                        'Год благоприятен для продвижения человека благодаря его творчеству, активной позиции, а также участию в светских и общественных мероприятиях. Особенно благоприятен год для артистов, людей, связанных с искусством, литературой (и вообще с тематикой пятого дома). Очевидно вознаграждение человека за его творческие усилия.',
                        'Иногда — получение наград, призов, выигрышей. Возникновение долгожданной любовной связи, часто воспринимаемой как награда или способствующей продвижению к жизненной цели или текущим задачам года.'
                    ]
                },
                11: {
                    title: '5 дом Соляра в 11 доме',
                    description: [
                        'Возникновение любовной связи на основе дружбы.',
                        'Человек обретает популярность, симпатии друзей. Общение с друзьями приносит удовольствие, заинтересованность в общих делах, совместные творческие планы. Год хорош для продвижения, запуска рекламы.',
                        'При негативных аспектах - пустые утехи, и необоснованные претензии к друзьям, склонность к изменам.'
                    ]
                },
                12: {
                    title: '5 дом Соляра в 12 доме',
                    description: [
                        'Неприятные события и переживания, связанные с любовной связью. Депрессии, тяжелые состояния, апатия, оторванность от реальной жизни.',
                        'Часто возникает необходимость скрывать свои чувства и отношения. Любовники могут толкать на неблаговидные поступки, ведение замкнутого образа жизни. Может означать также нежелательную или тяжело протекающую беременность у женщин, осложнения и беспокойства, ведение непривычного образа жизни из-за этого.'
                    ]
                }
            },
            6: {
                1: {
                    title: '6 дом Соляра в 1 доме',
                    description: [
                        'Проявление инициативы человека должно быть направлено на отношения в трудовом коллективе или собственное здоровье.',
                        'События, связанные с производственной сферой, занимают важное место. Благоприятная позиция для людей, занятых в медицине, диетологии, курортном бизнесе, а также торговле и сфере обслуживания.',
                        'Год чаще всего сопровождается ухудшением самочувствия, тревогами за состояние здоровья. Причем, человек сам негативно влияет на свой организм.'
                    ]
                },
                2: {
                    title: '6 дом Соляра во 2 доме',
                    description: [
                        'Указание на то, что доход человека лежит в поле служения и наемной работы. Зарабатывать важно только упорным трудом. Часто это скучная/неинтересная для натива работа. Можно рекомендовать переходить в любую другую служащую/помогающую профессию.',
                        'Возможны траты на поправку здоровья, оздоровительные мероприятия, дорогостоящее лечение. Такое положение может указывать на выход на пенсию, освобождение от обязанностей по состоянию здоровья.'
                    ]
                },
                3: {
                    title: '6 дом Соляра в 3 доме',
                    description: [
                        'Работа человека в этом году связана с поездками либо коммуникациями. Множество разговоров и документов на работе.',
                        'Перемена места жительства, смена климата неблагоприятно отразится на жизнедеятельности организма (для тех, кому этот вопрос актуален).'
                    ]
                },
                4: {
                    title: '6 дом Соляра в 4 доме',
                    description: [
                        'Чаще всего указывает на усиленный труд в домашних условиях, работу на дому. Возможно участие в сельскохозяйственных работах, занятость в горной, перерабатывающей промышленности.',
                        'При негативных аспектах – болезнь родителей, членов собственной семьи, а также урон для имущества, недвижимости человека.'
                    ]
                },
                5: {
                    title: '6 дом Соляра в 5 доме',
                    description: [
                        'Указывает на работу, труд во время отпуска. Либо, работа в местах увеселительного отдыха (вечеринки, клубы, мероприятия). Человек может оказаться в зависимости от партнера (любовника/поклонника), либо своего ребенка и его желаний. Не рекомендуется в этом году отдыхать, участвовать в светской жизни, посещать увеселительные заведения. Лучше сосредоточиться вокруг творческой работы.'
                    ]
                },
                6: {
                    title: '6 дом Соляра в 6 доме',
                    description: [
                        'Чаще всего означает переход на другое место работы. Человека мучит болезненность, мнительность, депрессия, более или менее выраженная. Заботы о здоровье занимают ум и время.'
                    ]
                },
                7: {
                    title: '6 дом Соляра в 7 доме',
                    description: [
                        'Благодаря своим усилиям и труду человеку будет легко выделиться, отличиться, стать заметнее. Год благоприятен для работы на публике — в сфере обслуживания, консультаций. Служебные обязанности предполагают углубление в человеческие взаимоотношения.',
                        'В этом году лучше не заключать договоров, соглашений, не начинать судебных процессов (все превращается в рутину).'
                    ]
                },
                8: {
                    title: '6 дом Соляра в 8 доме',
                    description: [
                        'Ощущение необходимости перехода на другую работу, смены вида деятельности. Иногда несет полную трансформацию обязанностей и места труда. Возможно попадание в зависимое положение из-за долгов.',
                        'От состояния здоровья человека в этом году зависит все. У женщин в этом году возможно наступление менопаузы.'
                    ]
                },
                9: {
                    title: '6 дом Соляра в 9 доме',
                    description: [
                        'Если дом активен, чаще всего означает работу за границей или в другом городе. Работа в связи и для иностранных граждан, предпринимателей и организаций. Может указывать на учебу за границей, повышение квалификации. Рутинная преподавательская деятельность.',
                        'Путешествия, отъезды не благоприятны для человека, навевают тревогу, могут подорвать здоровье человека.'
                    ]
                },
                10: {
                    title: '6 дом Соляра в 10 доме',
                    description: [
                        'Усиленная работа ради достижения цели. Повседневный, скучный труд. Положение дает терпение, настойчивость. Большое значение в этом году имеют отношения с начальством, которые — в зависимости от показателей дома — могут как возвысить человека, так и унизить.'
                    ]
                },
                11: {
                    title: '6 дом Соляра в 11 доме',
                    description: [
                        'Год способствует популярности, признанию труда человека. Часто дает влиятельных покровителей и преданных единомышленников, оказывающих всемерную помощь и поддержку. В этом году следует развивать и укреплять сотрудничество, искать партнеров, соратников.',
                        'При негативных аспектах – опасность разрушения планов, несбывшиеся надежды, в частности, в отношении работы. Кроме того, может означать несчастный случай с кем-нибудь из друзей, его болезнь, неприятности.'
                    ]
                },
                12: {
                    title: '6 дом Соляра в 12 доме',
                    description: [
                        'Может указывать на прекращение определенного вида деятельности, уход с работы, иногда при таинственных, странных обстоятельствах. Самостоятельный труд в уединении, приносящий плоды в будущем, работа на перспективу.',
                        'Необходимость жизни вдали от людских глаз из-за болезни, несчастного случая. Затяжное лечение.'
                    ]
                }
            },
            7: {
                1: {
                    title: '7 дом Соляра в 1 доме',
                    description: [
                        'При самом лучшем раскладе в этом году может случиться встреча с будущим супругом (если такого еще нет) или возможен брак.',
                        'Для семейного человека показатель указывает на год, важный для супруга, возрастание его роли в общественной жизни, обретение им популярности, признания. Человек становится очень активным, возрастают его амбиции. Начало взаимодействия с различными партнерами, налаживание связей, сотрудничества. События в социуме оказывают влияние на дела человека, его самосознание, жизнь в целом.',
                        'При самом худшем раскладе натив может инициировать развод или расторжение иных партнерских отношений.'
                    ]
                },
                2: {
                    title: '7 дом Соляра во 2 доме',
                    description: [
                        'Социальная роль, признание со стороны публики, деньги и статус, уровень обеспеченности партнера играет для человека важную роль. Возможна жизнь за счет денег партнера, на содержании. Партнер оказывает помощь в зарабатывании средств существования.',
                        'Деньги через дела седьмого дома.'
                    ]
                },
                3: {
                    title: '7 дом Соляра в 3 доме',
                    description: [
                        'Год благоприятствует популяризации идей или занятий человека, рекламы, обучения. На первом месте - общение, переписки, поездки для встреч с разными людьми, в том числе деловыми партнерами или супругом. Отношения с этой группой лиц приносят беспокойства, состояние нервозности. Подписание некоего соглашения, возможно, трудового. Вступление в деловые отношения или в переписку с супругом.',
                        'При негативных аспектах — противостояние с близким окружением, младшими родственниками, приятелями, проблемы взаимоотношений с ними.'
                    ]
                },
                4: {
                    title: '7 дом Соляра в 4 доме',
                    description: [
                        'Основной круг общения человека составляют родители, другие члены его семьи. Год благоприятен для вступления в брак, сожительства, огласки отношений. Сотрудничество с людьми, старшими по возрасту, родственниками. Работа на дому. Недоброжелатели человека, открытые противники, оппоненты набирают силу, укрепляют свои позиции.',
                        'При негативных аспектах – развод, раздел имущества или разрыв связей с родителями.'
                    ]
                },
                5: {
                    title: '7 дом Соляра в 5 доме',
                    description: [
                        'Год активности в любви и любовных связей. Возможно вступление в деловое сотрудничество с любовником или наоборот, вступление в любовную связь с партнером по бизнесу. Может означать соперничество, активизацию соперников. Год благоприятен для договоров и сотрудничества в искусстве, литературе, творческой деятельности, в спорте и азартных играх, а также в финансовых операциях.',
                        'При негативных аспектах — вражда с детьми, а также с любовниками. Осуждение со стороны общества за образ жизни человека, любовные связи.'
                    ]
                },
                6: {
                    title: '7 дом Соляра в 6 доме',
                    description: [
                        'Работа по совместительству. Профессиональные дела супруга могут предоставить человеку дополнительные возможности.',
                        'Указание на болезнь или опасность для супруга и/или других партнеров человека. Попадание их в подчиненное, зависимое положение. Тяжбы с подчиненными, сотрудниками, обслуживающим персоналом или с другими лицами на почве рабочих неудачных моментов.',
                        'При негативных аспектах – популярность человека уменьшается/увеличивается из-за болезни, несчастного случая, неприятностей.'
                    ]
                },
                7: {
                    title: '7 дом Соляра в 7 доме',
                    description: [
                        'В зависимости от показателей может указывать как на разрыв с партнером, так и на возникновение новой связи, ведущей к браку или сотрудничеству и совместной деятельности. Любовь и сотрудничество очень перекликаются, возможны отношения с сотрудниками, либо деловые моменты с супругом.',
                        'Обретение общественной популярности благодаря супругу или деловому партнеру. Повышение деловой и общественной активности, укрепление партнерских отношений.'
                    ]
                },
                8: {
                    title: '7 дом Соляра в 8 доме',
                    description: [
                        'Чаще всего указывает на вступление в первую или очень важную для человека сексуальную связь. Связь мистического характера, очень глубокую и/или тяжелую, с человеком, углубленным в оккультные практики и знания. Может указывать на ворожбу, привороты, колдовские чары.',
                        'А вот в карте семейного человека может означать негативные изменения в браке: развод, раздел имущества, кризисы, связанные с супругом, трансформация отношений.'
                    ]
                },
                9: {
                    title: '7 дом Соляра в 9 доме',
                    description: [
                        'Год даст возможность выхода на международный рынок благодаря супругу или связям деловых партнеров. Иногда указывает на обретение популярности за рубежом. Научное, литературное, религиозное сотрудничество за рубежом.',
                        'При подтверждении другими показателями может указать на заключение брака с иностранцем или хотя бы жителем другого города. Также и на судебные процессы с людьми издалека. Указывает на отъезд супруга или партнера, на идеологические споры с этими людьми.'
                    ]
                },
                10: {
                    title: '7 дом Соляра в 10 доме',
                    description: [
                        'Год благоприятен для открытой, консультативной деятельности, адвокатской практики. Продвижение человека очень зависит от его участия в общественных мероприятиях либо работы в государственных структурах.',
                        'При неблагоприятных аспектах — борьба за власть с оппонентами или партнерами, тяжбы, неустойчивое положение, запутанность дел. Открытое противостояние с начальником и властями, неприятие позиций друг друга. Возможно заключение брака в этом году, изменение статуса какого-либо партнерства.'
                    ]
                },
                11: {
                    title: '7 дом Соляра в 11 доме',
                    description: [
                        'Деловые партнеры и коллеги могут стать друзьями. Часто указывает на изменение стиля общения с друзьями: более тесное или расставание, неопределенность. Друзья помогают в делах, способствуют продвижению, популярности, приобретению полезных связей. Участие в свадебных торжествах друзей. При определенных показателях может указывать на появление друга у супруга. Если этот друг противоположного пола, возможен адюльтер. Вообще положение опасное для брака.',
                        'При негативных аспектах – неопределенность положения в обществе, повышенная конфликтность, непонимание в дружеской среде.'
                    ]
                },
                12: {
                    title: '7 дом Соляра в 12 доме',
                    description: [
                        'Огорчения, разочарования, неприятности приносят человеку его партнеры (в т.ч. супруг). Возможно попадание супруга в неприятную ситуацию.',
                        'Возможно возникновение тайной связи либо утаивание от супруга, партнеров некоторых сведений. Но более вероятна измена со стороны супруга, действия за спиной человека. Год полон проблем с окружением. Не желательно вступать в брак, заключать различные соглашения, выходить на широкую публику.'
                    ]
                }
            },
            8: {
                1: {
                    title: '8 дом Соляра в 1 доме',
                    description: [
                        'Это прежде всего кардинальные изменения в вопросах чужих денег, распределения прибыли, размещение капитала, дела с наследством, налогами, кредитами, ссудами, долгами. Значительно повышаются сексуальные потребности человека. Связи с потусторонними силами, могут проявиться необычные способности (у тех, у кого есть указания в натале).',
                        'Может указывать на год траура, роковые обстоятельства, тяжелые разочарования, закалку в испытаниях. В этом году человек может многое поставить на карту и — при благоприятных показателях дома — победить, выйти на новый уровень.'
                    ]
                },
                2: {
                    title: '8 дом Соляра во 2 доме',
                    description: [
                        'Год благоприятен для заработков, получения крупных сумм от партнеров, супруга. Часто неправедное получение денег, путем обмана, подлога.',
                        'При неблагоприятных аспектах — потеря одного, часто основного, источника дохода. Разрушение прочной материальной базы, перерасход бюджета. Опасность ограблений.'
                    ]
                },
                3: {
                    title: '8 дом Соляра в 3 доме',
                    description: [
                        'В большинстве случаев указывает на краткосрочные сексуальные связи. Может указывать на интимные отношения с приятелями. Часто означает смену сексуального партнера. Замкнутость, игнорирование коммуникаций с друзьями. Либо полное изменение круга общения.',
                        'Переписка мистического характера, известие о смерти, документальное оформление наследства.',
                        'Может означать опасность при перемещении/поездках.'
                    ]
                },
                4: {
                    title: '8 дом Соляра в 4 доме',
                    description: [
                        'Год может быть отмечен драматическими событиями в семье, при подтверждении другими показателями — трагического плана. Так же может означать коренные изменения в семье, обретение новой семьи.',
                        'При хороших показателях — продажа недвижимости, собственности. Хороший доход от наследуемого недвижимого имущества.'
                    ]
                },
                5: {
                    title: '8 дом Соляра в 5 доме',
                    description: [
                        'В этом году идет переосмысление своих любовных отношений и привязанностей, сопровождающееся внутренним конфликтом, угасание чувства. Потеря любимого человека, расставание, разрыв сексуальной связи. Выраженная сексуальность или ее подавление. Изменение отношения к сексу вообще. Часто отход от светской жизни, отказ от развлечений. Кризисные/переходные ситуации в жизни детей.',
                        'Для бизнесменов при позитивном характере элементов дома — увеличение капитала, повышение деловой активности, прибыли, удача в спекуляциях и в размещении капитала.'
                    ]
                },
                6: {
                    title: '8 дом Соляра в 6 доме',
                    description: [
                        'Выраженная связь доходов/расходов со здоровьем. Расходы на оздоровление, лечение. Изменения в состоянии здоровья. При позитивной аспектации — полное избавление от недугов. Так же, указание на изменение стиля работы, должностные перемещения. Либо полная смена вида деятельности, места работы.',
                        'При негативных аспектах – опасность для подчиненных, сотрудников или дальних родственников. В этом году лучше отказаться от составления завещания, других важных бумаг и имущественных документов.'
                    ]
                },
                7: {
                    title: '8 дом Соляра в 7 доме',
                    description: [
                        'Имущественные и финансовые споры. Недоразумения и сложности с размещением капитала.',
                        'Иногда может означать развод и новый брак в этом году. Или обновление супружеских отношений, активизируется интимная сторона брака.',
                        'При негативных аспектах — серьезный кризис в жизни одного из партнеров человека. Он может коснуться супруга или от него может исходить опасность в сторону натива.'
                    ]
                },
                8: {
                    title: '8 дом Соляра в 8 доме',
                    description: [
                        'Указание на изменение в личной (сексуальной) жизни человека, возобновление отношений после длительного одиночества. Переосмысление роли секса в жизни человека. Такое положение указывает на опасности сексуального характера, в частности попытки к изнасилованию в женском гороскопе (при иных указателях!).',
                        'Пробуждение сознания. Большое внимание уделяется человеком к вопросам духовного развития. В минусе – активация стрессовых ситуаций.'
                    ]
                },
                9: {
                    title: '8 дом Соляра в 9 доме',
                    description: [
                        'Этот год знаменуется сменой стадий обучения — поступлением или окончанием вуза, переходом из одного в другой. Переосмысление отношения к процессу получения знаний.',
                        'Денежные отношения с представителями иностранных фирм или независимыми иностранными предпринимателями. Перспективные вложения капитала.',
                        'Год не благоприятен для путешествий, они чреваты критическими ситуациями.'
                    ]
                },
                10: {
                    title: '8 дом Соляра в 10 доме',
                    description: [
                        'Через сексуальную связь – возможна реализация целей человека. Завязывание интимных отношений может рассматриваться человеком как цель. При негативных аспектах – крах карьеры. Опасность при достижении какой-либо цели. Смена начальника, смена власти.'
                    ]
                },
                11: {
                    title: '8 дом Соляра в 11 доме',
                    description: [
                        'Год смены дружеского окружения, тяжело переживаемый разрыв связей с друзьями.',
                        'Обретение покровителей или единомышленников, тесная связь с ними. При благоприятных аспектах — прибыли. При негативных аспектах — потери за счет друзей или долги.'
                    ]
                },
                12: {
                    title: '8 дом Соляра в 12 доме',
                    description: [
                        'Указание на тайную связь. Либо прерывание сексуальной связи, часто по абсолютно непонятным для человека причинам. Возможно попадание в заточение из-за сексуального партнера, в частности арест и взятие под стражу',
                        'Необходимость урегулирования финансового положения, запутанные дела. При благоприятных показателях может указывать на окончание черной полосы в жизни, конец неприятностей. Выход на новый уровень.'
                    ]
                },
            },
            9: {
                1: {
                    title: '9 дом Соляра в 1 доме',
                    description: [
                        'Год изучения себя, осмысления своей личности. В это время для человека важны философские, религиозные, литературные, научные веяния. Это имеет огромное значение для всей последующей жизни. Год открывает перспективы личного развития, духовного роста.',
                        'Человек решает проблемы, связанные с другими странами и культурами. Подчеркнуто также значение дальних поездок и путешествий, а кроме того, — влияния иностранных граждан и организаций.'
                    ]
                },
                2: {
                    title: '9 дом Соляра во 2 доме',
                    description: [
                        'Формируется определенный взгляд на финансовые проблемы. Возможность зарабатывать и тратить самостоятельно. Переосмысление духовных ценностей.',
                        'Возможность преподавания, передачи своих знаний и взглядов, литературная, издательская деятельность приносит доходы. Доходы могут приносить связи с иностранцами, совместный бизнес. Значительно повышаются денежные амбиции человека. Обретение финансовых перспектив.'
                    ]
                },
                3: {
                    title: '9 дом Соляра в 3 доме',
                    description: [
                        'Множественные краткосрочные поездки за рубеж или в другие города. Указание на изучение иностранных языков. Стажировку, обучение.',
                        'Много контактов и мелких забот, хлопот, из-за чего перспективы распыляются, а устои могут подвергаться изменениям. Показатель может участвовать в формуле события на переезд.'
                    ]
                },
                4: {
                    title: '9 дом Соляра в 4 доме',
                    description: [
                        'Показатель может участвовать в формуле события на переезд (эмиграцию).',
                        'Возможен отъезд родителей или других членов семьи за границу, а также обретение самим человеком места жительства за границей или в другом городе. Постоянные перемещения, путешествия.',
                        'Возможны туристические поездки в исторические места. Интерес к прошлому, кармическим вопросам, истории, изучение чужих культур и традиций.'
                    ]
                },
                5: {
                    title: '9 дом Соляра в 5 доме',
                    description: [
                        'Год крайне благоприятен для путешествий, приятного отдыха, развлечений за границей, а также для сдачи экзаменов и участия в соревнованиях. Может указывать на отъезд детей за границу, в другой город или посещение их за границей.',
                        'Выработка стиля воспитания детей.',
                        'Указатель на возможность начать преподавательскую/педагогическую деятельность.'
                    ]
                },
                6: {
                    title: '9 дом Соляра в 6 доме',
                    description: [
                        'Появление служебных, производственных перспектив. Связь работы с заграницей, торговля импортным товаром. Новый взгляд на служебные обязанности, переосмысление стиля и методов работы, а также вида деятельности.',
                        'Иногда указывает на посты и воздержание, оздоровительные практики, а также и поездки. Год может дать поездки и путешествия, особенно служебные или с целью поправки здоровья.'
                    ]
                },
                7: {
                    title: '9 дом Соляра в 7 доме',
                    description: [
                        'Переосмысление своего отношения к партнерским отношениям и человеческим взаимоотношениям вообще. Деятельность за границей по контракту, особенно преподавательская, литературная, издательская или миссионерская, а также консультативная.'
                    ]
                },
                8: {
                    title: '9 дом Соляра в 8 доме',
                    description: [
                        'Опасность, критические ситуации в поездках, путешествиях, что диктует необходимость отказаться от них. Может указывать на сексуальную связь с иностранцем, а также на расширение сексуальных возможностей. Получение прибыли за границей, удачное размещение капитала. В более высоком смысле означает духовную борьбу или переворот в жизни.'
                    ]
                },
                9: {
                    title: '9 дом Соляра в 9 доме',
                    description: [
                        'Может проснуться интерес к религии, философии, мировоззренческим проблемам, чужим странам и культурам, тяга к путешествиям и перемещениям.',
                        'Много разговоров на духовные темы, изучение этих вопросов. Год имеет очень большое концептуальное значение для человека. Актуализируются все темы девятого дома.'
                    ]
                },
                10: {
                    title: '9 дом Соляра в 10 доме',
                    description: [
                        'Обретение перспектив взрослой жизни, репутации. Год благоприятен для продвижения политической, адвокатской карьеры, для издателей и публицистов, философов, преподавателей. Год вообще благоприятен для продвижения, реализации перспектив и планов. Может дать получение наград, хороших отзывов. Профессиональный рост.',
                        'При негативных аспектах — разногласия с начальством, органами власти по идеологическим вопросам.'
                    ]
                },
                11: {
                    title: '9 дом Соляра в 11 доме',
                    description: [
                        'Год дружбы с иностранцами. Обретение единомышленников, коллег, поклонников, за рубежом. Надежды на связи с иностранцами, на путешествия и поездки.',
                        'Дружеское окружение человека может состоять из людей, увлеченных вопросами философии, религии, добродетели и меценатства. Иногда также означает отъезд близкого друга за рубеж.'
                    ]
                },
                12: {
                    title: '9 дом Соляра в 12 доме',
                    description: [
                        'Год не благоприятен для интеллектуальной, академической активности. Умственная апатия. Неудачи и разочарования в преподавательской деятельности или иных сферах по девятому дому.',
                        'Неудовлетворенность результатами поездок и путешествий, отсутствием перспектив.',
                        'Год благоприятен для тех, кто занимается скрытыми исследованиями, для тайных агентов любого рода, для принятия пострига.'
                    ]
                }
            },
            10: {
                1: {
                    title: '10 дом Соляра в 1 доме',
                    description: [
                        'Много действий, направленных на становление карьеры, профессиональный рост или обретение определенного социального статуса. Очень важный год, когда человек четко осознает, чего хочет, свои цели и место в жизни. Он прилагает все свои усилия, амбиции для выполнения планов, налаживание полезных связей, обретения хорошей репутации. Все действия служат будущему.',
                        'Часто, благодаря данному положению соляра, — обстоятельства сами подводят человека к выбору решения и нужным поступкам, деньгам и возможностям достичь большего.'
                    ]
                },
                2: {
                    title: '10 дом Соляра во 2 доме',
                    description: [
                        'Денежные вопросы, вопросы имущества в этом году очень важны. Это главные мотивы работы человека.',
                        'Год чаще всего предоставляет такие возможности, благодаря продвижению в карьере, профессиональным открытиям и достижениям. Удачное положение для денежной сферы, особенно при наличии хороших планет во втором и/или десятом доме.',
                        'При негативных аспектах – может развиться жадность, скупость, алчность.'
                    ]
                },
                3: {
                    title: '10 дом Соляра в 3 доме',
                    description: [
                        'Переписка, переговоры, различные контакты, полезные связи и командировки содействуют становлению и профессиональному росту, занятию высокого поста или изменению социального статуса.',
                        'Человек может быть признан полезным для общества. Работа и изучение множества документов, возможно подписание хорошего контракта или договора. Может быть указанием на успехи брата/сестры или другого родственника.'
                    ]
                },
                4: {
                    title: '10 дом Соляра в 4 доме',
                    description: [
                        'Возможна пауза в продвижении по карьерной лестнице, но, в тоже время, указывает на долгожданное приобретения жилья, решение имущественного вопроса. Год подготовки к новому витку в жизни.',
                        'При негативных аспектах - год отставки, выхода на пенсию, естественного окончания карьеры.'
                    ]
                },
                5: {
                    title: '10 дом Соляра в 5 доме',
                    description: [
                        'Год, удачный для детей. Их воспитанию, целям в дальнейшей жизни уделяется повышенное внимание. Указатель на победы детей. Также целью года может быть рождение ребенка.',
                        'Год успеха в спорте, творчестве, искусстве. Год удачных финансовых вложений. Радости и удовлетворения в любовных отношениях. Возможна помолвка с любимым, либо обретение поклонников, любовников, с хорошим положением в обществе.',
                        'При негативных аспектах указывает на события, ведущие к окончанию любовной связи, потере симпатий и покровителей, разрушению планов.'
                    ]
                },
                6: {
                    title: '10 дом Соляра в 6 доме',
                    description: [
                        'Успех на работе у лиц, занятых в сфере услуг, медицине, имеющих дело с кропотливым повседневным трудом. Человека замечают благодаря его стилю работы или работы его команды. Чья-то болезнь может освободить вакантное место.',
                        'Если у человека стоит вопрос здоровья, то указание на выздоровление, упорное проведение лечебных и оздоровительных мероприятий.',
                        'При негативных аспектах – болезнь может препятствовать любой деятельности, активности. Угнетенное состояние, обстоятельства, мешающие развернуться.'
                    ]
                },
                7: {
                    title: '10 дом Соляра в 7 доме',
                    description: [
                        'Год благоприятен и указывает на бракосочетание (чаще в женских солярах). Положение женщины зависит от состояния дел мужа.',
                        'Если человек не в браке, то получение интересного положения, выгодных договоров, хорошей поддержки публики. Вообще, прослеживается влияние других людей на вид деятельности и поступки. Все это способствует карьерному росту, продвижению, реализации задач. Однако, натив должен быть открыт для общения.',
                        'При негативных аспектах — развод, разрыв связей.'
                    ]
                },
                8: {
                    title: '10 дом Соляра в 8 доме',
                    description: [
                        'Возможны различные трактовки данного положения. В зависимости от текущей ситуации и уровня готовности человека идти в глубину и в духовность.',
                        'Получение денег от других людей, получение наследства, решение проблем с налоговыми, долгами, кредитами и другими финансовыми претензиями. Вступление в сексуальную связь, так же, человек может захотеть изучать мистику, необычные практики.',
                        'Это положение часто сопровождает людей, чья профессия или увлечения сопровождаются риском, попаданием в критические ситуации. Или чья профессия связана с восьмым домом.',
                        'При негативных показателях – крах карьеры, скандал на работе, рискованная борьба до последнего.'
                    ]
                },
                9: {
                    title: '10 дом Соляра в 9 доме',
                    description: [
                        'Такое положение может означать начало карьеры за рубежом. Иностранцы и все что с ними связано очень сильно могут влиять на положение дел и карьеру человека. Налаживание долгожданных связей с заграницей. Поездки/командировки.',
                        'Большое значение для решения текущих задач и продвижения к жизненной цели имеют интеллектуальные усилия, духовное развитие. Год удачен для преподавания, изучения наук, благотворительности, учебы.'
                    ]
                },
                10: {
                    title: '10 дом Соляра в 10 доме',
                    description: [
                        'Очень важный год для человека, в котором закладывается фундамент будущего. Продвижение к самой главной цели в жизни. Все действия человека приводят к этому. Человек либо сам действует, так как считает и понимает, либо все окружение (супруг/партнеры), а так же внешние независящие факторы действуют на него.',
                        'Возможна смена вида деятельности, активизация вопросов карьерного роста, положения в обществе.'
                    ]
                },
                11: {
                    title: '10 дом Соляра в 11 доме',
                    description: [
                        'Все задачи, связанные, с друзьями, единомышленниками, поклонниками, командой реализуются. Идет наработка на будущее. Вмешательство друзей и вышеперечисленных лиц значительно поможет человеку приблизиться к жизненной цели и решить ряд текущих проблем.',
                        'Появление новых дружеских связей, смена дружеского окружения. Год благоприятен для проведения рекламной кампании, обретения популярности среди единомышленников.',
                        'При негативных аспектах — крах планов, чему могут способствовать и друзья.'
                    ]
                },
                12: {
                    title: '10 дом Соляра в 12 доме',
                    description: [
                        'Год благоприятен для тайного и непроявленного. Работа в уединении может быть оценена по достоинству. Стремление к уединению, замкнутому образу жизни, а также сыскной деятельности. Успехи в этих вещах.',
                        'Развитие подсознания, душевной сопротивляемости, психических сил человека. При поражении элементов дома — год испытаний, проверки на прочность. Общественное положение и карьера подвергаются опасностям, в лучшем случае — приносят огорчения и разочарования. Возможность измены, клеветы, скандалов, вражды с начальством, сплетен и слухов. Трудности в работе, в поиске места. Общее недовольство судьбой, апатия, ипохондрия. Отлучение от высокой должности, импичмент. При позитивных показателях дома — выход из заточения любого рода (тюрьмы, больницы, плена), победа над тайными противниками.'
                    ]
                }
            },
            11: {
                1: {
                    title: '11 дом Соляра в 1 доме',
                    description: [
                        'Год подходит для долгожданного исполнения планов благодаря собственным усилиям. Дружеское окружение, соратники, единомышленники и поклонники имеют для человека большое значение. Налаживание новых дружеских связей. Возможна некая выгода от общения. Возможно образование компании единомышленников и близких по духу людей для открытия собственного дела.',
                        'Год благоволит для проведения рекламных компаний и запусков. Развития и изучения инструментов для реализации задуманного.'
                    ]
                },
                2: {
                    title: '11 дом Соляра во 2 доме',
                    description: [
                        'Все планы человека строятся вокруг темы денег, материальных благ. Доходы и расходы зависят от приятелей и друзей человека, иногда любовника. Может быть организация бизнес проекта с другом.',
                        'При негативных аспектах - неудачные финансовые отношения с друзьями.'
                    ]
                },
                3: {
                    title: '11 дом Соляра в 3 доме',
                    description: [
                        'Человек активно участвует в жизни друзей, в связи с этим у него много хлопот и суеты. Некоторые планы и проекты невозможны без составления документов, важных бумаг, заключения договоров.',
                        'При негативных аспектах — затруднения в контактах, поездках, обучении.'
                    ]
                },
                4: {
                    title: '11 дом Соляра в 4 доме',
                    description: [
                        'Большие планы человека, связны с родителями и его друзьями, которые при подтверждении другими показателями могут оказать помощь в решении вопросов жилищных проблем и обретения семьи. Их помощь существенна, если это подтверждается другими показателями дома.',
                        'При таком положении есть шанс получить внезапно наследство или сменить место жительства.',
                        'При негативных аспектах — путаница в семейных или дружественных отношениях, запутанные имущественные дела.'
                    ]
                },
                5: {
                    title: '11 дом Соляра в 5 доме',
                    description: [
                        'Любовные отношения человека дают почву для грандиозных планов, счастья в них. Часто дружба переходит в интимные отношения или, наоборот, любовник становится другом.',
                        'Год подходит хорошо для участия в светской жизни, в спортивных соревнованиях, в финансовых операциях.',
                        'Дела детей занимают также важное место. Внимание может быть приковано к окружению ребенка, ибо он стремится к независимости, изменению в воспитании. Дети могут повлиять на изменение планов человека, если другие показатели это подтверждают, а элементы дома поражены.'
                    ]
                },
                6: {
                    title: '11 дом Соляра в 6 доме',
                    description: [
                        'Большую часть времени работа может пройти по внештатному графику и без принуждения. Много амбициозных планов, связанных с работой и положением в ней: по смене места службы, трудоустройству, занятию определенной официальной должности, дружеским отношениям, основанным на общности мнений и интересов сотрудников. Совместные проекты, мероприятия. Служебные романы. Возможно продвижение по службе.',
                        'При негативных аспектах — странные ситуации на работе, неустойчивое положение на службе, неожиданные болезни.'
                    ]
                },
                7: {
                    title: '11 дом Соляра в 7 доме',
                    description: [
                        'Много планов строится, связанных с положением супруга, либо его прямой помощи в делах, либо если его нет - на заключение брака.',
                        'Такое положение дома хорошо для проведения рекламной кампании, обретения популярности, расширения связей с общественностью и своего влияния на публику. Благоприятно также для начала политической карьеры.',
                        'При негативных аспектах – переход дружеских отношений во вражду или супружескую измену (с той или иной стороны, судя по аспектам элементов).'
                    ]
                },
                8: {
                    title: '11 дом Соляра в 8 доме',
                    description: [
                        'Человека, находящегося в начале трансформационного пути, предрасполагает к изучению психологии, астрологии, к духовному преображению.',
                        'При благоприятной аспектации элементов дома указывает на позитивную реализацию планов, связанных с финансовыми вопросами, завещанием, проблемами с проверяющими организациями, налогами, пошлинами, долгами, чужим капиталом и распределением прибыли.',
                        'Человек много мыслей уделяет деньгам, которых он еще не получил, либо чужим деньгам.',
                        'При негативных аспектах – указание на неудачи с финансами, напрасные амбиции, притягивание критических ситуаций, а также на смерть друга.'
                    ]
                },
                9: {
                    title: '11 дом Соляра в 9 доме',
                    description: [
                        'Удачный год для поступления в ВУЗ, для продолжения образования, стажировку, повышение квалификации, изучение иностранных языков. Такое положение может указывать на встречу с духовным учителем.',
                        'Все, что связано с заграницей манит и наделяет большими надеждами: путешествия, дальние поездки, вести из-за границы, приезд иностранцев или земляков из-за границы, связи с иностранными фирмами и организациями, даже деньги из-за границы или размещение там капитала. Дружба с иностранцами.',
                        'Может стать годом поступления в ВУЗ для продолжение образования, стажировку, повышение квалификации, изучение иностранных языков.'
                    ]
                },
                10: {
                    title: '11 дом Соляра в 10 доме',
                    description: [
                        'Серьезные планы на построение карьеры, реализацию целей. Может давать дружбу с начальниками или вышестоящими людьми, а также участие в партийной деятельности. Часто несет полезные связи и контакты, возвышение за счет единомышленников, групп людей или просто друзей.'
                    ]
                },
                11: {
                    title: '11 дом Соляра в 11 доме',
                    description: [
                        'Основные планы года связаны у человека с его друзьями, единомышленниками, поклонниками, а также представителями свободных профессий, творческими, экстравагантными личностями, участием в их делах.',
                        'Яркая актуализация тем одиннадцатого дома. Может начаться изучение соответствующих дисциплин.'
                    ]
                },
                12: {
                    title: '11 дом Соляра в 12 доме',
                    description: [
                        'Год разочарования в дружбе, неосуществленных надежд.',
                        'Друзья могут уезжать, отдаляться или переходить в разряд тайных недоброжелателей. Стремление к уединению, тайные мечтания и планы. Хорошее время для духовных практик.'
                    ]
                }
            },
            12: {
                1: {
                    title: '12 дом Соляра в 1 доме',
                    description: [
                        'Год, в котором необходимо довериться своему подсознанию, побыть в уединении (для духовных практик, принятия обетов). Год, когда выявляются многие кармические связи. Человеку есть что скрывать и над чем поработать. Год, когда все тайное становится явным (поступки, совершенные ранее).',
                        'Период чреват депрессиями, психическими травмами, горестями в общечеловеческом и эмоциональном смысле.',
                        'При негативных аспектах возможна алкогольная или наркотическая зависимость.'
                    ]
                },
                2: {
                    title: '12 дом Соляра во 2 доме',
                    description: [
                        'Денег становится мало. Много страхов по ним, много плохих (воровство, спекуляции) предложений заработка. Вообще материальную сферу человека окружает ореол обманов и тайн. Нет прочного фундамента, почвы под ногами. Можно рекомендовать заработки на экологичных темах двенадцатого дома.',
                        'Период благоприятен для занятий йогой, оздоровительной гимнастикой, для накопления запасов энергии, использования скрытых резервов организма.'
                    ]
                },
                3: {
                    title: '12 дом Соляра в 3 доме',
                    description: [
                        'Год, когда у человека создается иллюзия общения: близкое окружение, приятели, братья или сестры приносят огорчения, взаимные обиды. Указание на тайные встречи, неясные контакты, встречи анонимного клуба, скрываемая переписка. Сплетни и тайны в информационном поле человека.',
                        'Так же такое положение указывает на проблемы с выражением собственных мыслей, отсюда возможны помехи в учебе, трудность в освоении материала. Положение благоприятно для сохранения тайны, подпольной деятельности.'
                    ]
                },
                4: {
                    title: '12 дом Соляра в 4 доме',
                    description: [
                        'Такое положение указывает на разочарования и неприятности, связанные с местом жительства, семейными отношениями, родителями, ухудшением их здоровья. Гнетущая обстановка в доме.',
                        'Так же, может быть указанием на поиск и приобретение тайной семьи, второго дома.',
                        'Год благоприятен для медитации, ухода в себя, обдумывания глубинных проблем, для самопознания.'
                    ]
                },
                5: {
                    title: '12 дом Соляра в 5 доме',
                    description: [
                        'В этом году человек склонен скрывать свои чувства. Возможно появление тайной любви. Сильная зависимость от любви и удовольствий. Возможно расторжение любовной связи, переживания из-за любви. Для женщины год неблагоприятен для зачатия и родов (может указать на выкидыш или тяжелую беременность).',
                        'Возрастает тяга и возможности участия в нечестных делах (аферах, шантажах). Проблемы у детей или в отношениях с ними.'
                    ]
                },
                6: {
                    title: '12 дом Соляра в 6 доме',
                    description: [
                        'Разочарование в работе, занимаемой должности. Уход с места работы. Коллеги недопонимают и может образоваться вражда. Незаконная деятельность на работе, махинации и клевета. При позитивных аспектах — подсиживание своих врагов на работе, обретение независимого положения на службе.',
                        'Год благоприятен для лечения нетрадиционными методами, снятия порчи, перестройки организма, а также для духовной практики, использования диет, гимнастики, выезда в санатории.'
                    ]
                },
                7: {
                    title: '12 дом Соляра в 7 доме',
                    description: [
                        'Крайне запутанный год для человека. Отсутствие популярности. Огорчения из-за всего, что связано с партнерством (ухудшается взаимопонимание с супругом и/или партнерами, одолевают заботы, проявляются недоговоренности).',
                        'Обычно указывает на более или менее тяжелое расставание, разочарование. Может указывать на заражение тяжелой болезнью при различных контактах. Не рекомендуется активное появление на публике.',
                        'При негативных аспектах — тайны человека всплывают и становятся предметом обсуждения.'
                    ]
                },
                8: {
                    title: '12 дом Соляра в 8 доме',
                    description: [
                        'Год разочарований, переживаний, неудач в финансовой сфере. Потери, риски смертельно опасных ситуаций, проявление суицидальных мыслей. Может указывать на сексуальную связь, которую человек хочет скрыть и которая раскроется все равно. Огорчения, неуверенность.',
                        'При негативных аспектах (тяжелых) – попытки самоубийства, привлечение к себе опасных ситуаций, влечение к опасности. Человек рискует стать объектом нападения, воздействия магических сил, психического воздействия, шантажа.',
                        'Чаще всего при нормальном расположении планет указывает на госпитализацию для проведения операции.'
                    ]
                },
                10: {
                    title: '12 дом Соляра в 10 доме',
                    description: [
                        'Год лучше не использовать для карьерного роста и общественного выдвижения. Для достижения собственных целей человек вынужден использовать обходные пути, тайное влияние.',
                        'Обязательства и долги значительно осложняют положение человека, мешают. Иногда может указывать на отдаление/уединение начальника, мужа, родителей. Также страхи, отсутствие свободы действий, вынужденное подчинение. Вскрытие неприятных для натива фактов.'
                    ]
                },
                11: {
                    title: '12 дом Соляра в 11 доме',
                    description: [
                        'Год ссор с друзьями, коллегами, командой. Часто друзья становятся причиной разочарований, потерь, неприятных ситуаций. При положительных аспектах удастся нейтрализовать друзей, которые могут стать врагами или завистниками. Год может дать появление тайного покровителя. Возможно завязывание тайных внебрачных отношений, беспорядочные связи.',
                        'Много мыслей и терзаний по поводу неисполнения задуманного, каких-то планов. Причины так же человек понять не может. Продумыванию дальнейшей жизни способствует уединение.',
                        'Переходный, очень важный год, который человек встречает разными эмоциями, не всегда положительными и радостными. Возможны и страхи и разочарования. Чаще всего знаменует год окончания определенного жизненного периода (черной полосы) и переход к новому циклу развития. Легкость перехода как такового определяется не столько показателями карты, сколько объективными наработками прошедших лет, уровнем духовного развития.'
                    ]
                },
                12: {
                    title: '12 дом Соляра в 12 доме',
                    description: [
                        'Переходный, очень важный год, который человек встречает разными эмоциями, не всегда положительными и радостными. Возможны и страхи и разочарования. Чаще всего знаменует год окончания определенного жизненного периода (черной полосы) и переход к новому циклу развития. Легкость перехода как такового определяется не столько показателями карты, сколько объективными наработками прошедших лет, уровнем духовного развития. '
                    ]
                },
            },
        },
        'solar-phormulas': {}

    },
    ingressions: {},
    directions: {}
};

;

    /**
     * изменением описания
     * @param { Object } args
     */

    function changeDescription(args) {
        let pathnames = args['pathnames'];
        let keys = args['keys'];
        let container = args['element'];
        let activeContent = Object.assign({}, descriptions); // клонируем объект с описаниеями
        // получаем нужный объект по ключам из url pathname
        for (let i = 0; i < pathnames.length; i++) {
            let pathname = pathnames[i];
            activeContent = activeContent[pathname];
        }

        // получаем нужный контент в нужном объекте
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            activeContent = activeContent[key];
        }

        let title = activeContent['title']; // получаем заголовок
        let description = activeContent['description']; // получаем описание
        let titleContainer = container.querySelector('.vision__tab-title'); // находим блок с заголовком
        let descriptionContainer = container.querySelector('.vision__tab-description');//  находим блок с описанием

        // если заголовок пустой, то скрываем блок
        if (title.length > 0) {
            titleContainer.style.display = 'block';
            titleContainer.textContent = title;
        } else {
            titleContainer.style.display = 'none';
        }

        // удаляем описание внутри блока с описанием
        descriptionContainer.innerHTML = '';

        // проходимся по массиву с описанием и заполнияем блок с описаниес
        if (description.length > 0) {
            for (let i = 0; i < description.length; i++) {
                let paragraph = document.createElement('p');
                paragraph.textContent = description[i];

                descriptionContainer.append(paragraph);
            }
        }
    }

    // подставляем нужное описание исходя и делаем активным нужный таб из гет параметров при загрузке
    (function () {
        let pathnames = getPathname();
        let visionId = pathnames[pathnames.length - 1];

        showPathnameBlock(visionId);
    })();

    const accordionOpenButtons = document.querySelectorAll('.vision__accordion-button');

    /**
     *
     * @param items
     */
    function updateButtonState(button, items) {

        // Проверяем, есть ли хотя бы один открытый элемент в аккордеоне
        let isOpen = Array.from(items).some(function (item) {
            return item.classList.contains('open');
        });

        let currentText = button.textContent.trim();
        let revertText = button.dataset.revertText;

        if (isOpen) {
            button.classList.add('vision__accordion-button_open');

            // Обновляем текст кнопки
            button.textContent = currentText === 'Свернуть все' ? currentText : revertText;
            button.dataset.revertText = currentText === 'Свернуть все' ? revertText : currentText;
        } else {
            button.classList.remove('vision__accordion-button_open');

            // Обновляем текст кнопки
            button.textContent = currentText === 'Развернуть все' ? currentText : revertText;
            button.dataset.revertText = currentText === 'Развернуть все' ? revertText : currentText;
        }
    }

    if (accordionOpenButtons) {
        accordionOpenButtons.forEach(function (button) {

            button.addEventListener('click', function () {
                let isOpen = this.classList.contains('vision__accordion-button_open');
                let currentVision = this.closest('.vision');
                let currentAccordionItems = currentVision.querySelectorAll('.accordion__item');

                if (currentAccordionItems) {

                    currentAccordionItems.forEach(function (item) {
                        let content = item.querySelector('.submenu');

                        if (isOpen) {
                            content.style.maxHeight = 0;
                            item.classList.remove('open', 'active');
                        } else {
                            item.classList.add('open');
                            content.style.maxHeight = content.scrollHeight + "px";
                        }

                    });

                    updateButtonState(this, currentAccordionItems);
                }
            });
        });
    }

    const visionAccordions = document.querySelectorAll('.vision__content-accordion');

    function updateNearbyItems(item) {
        // Используем флаг, чтобы предотвратить бесконечный цикл
        if (item.classList.contains('active')) {
            return;
        }

        let prevItem = item.previousElementSibling;
        let nextItem  = item.nextElementSibling;

        if (item.classList.contains('open')) {
            if (prevItem) prevItem.classList.add('active');
        } else {
            if (prevItem) prevItem.classList.remove('active');
            if (nextItem && nextItem.classList.contains('active')) nextItem.classList.remove('active');
        }
    }

    if (visionAccordions) {
        visionAccordions.forEach(function (accordion) {
            let currentBlock = accordion.closest('.vision');
            let accordionButton = currentBlock.querySelector('.vision__accordion-button');
            let accordionItems = accordion.querySelectorAll('.accordion__item');

            // Создаем экземпляр MutationObserver
            observer = new MutationObserver(function (mutations) {
                let currentItem = mutations[0]['target'];

                updateNearbyItems(currentItem);
                // При изменениях в аккордеоне вызываем функцию обновления текста кнопки
                updateButtonState(accordionButton, accordionItems);
            });

            // Настраиваем настройки для observer (следим за изменениями в дочерних элементах и их атрибутах)
            var config = {childList: true, subtree: true, attributes: true};

            // Начинаем отслеживание изменений в аккордеоне с использованием настроек и функции обратного вызова
            observer.observe(accordion, config);
        });
    }
});
